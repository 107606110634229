export interface EventsBiddingsCreateOrUpdateRequest {
  eventName: string;
  eventDescription: string;
  eventDate: number;
  duration: number;
  audienceType: string[];
  isTravelSponsorship: boolean;
  travelSponsorshipDescription: string;
  maxExpertBidLimit: number;
  vertical: string[];
  concern: string[];
  biddingEndDate: number;
  id?: string;
  isTestEventBid: boolean;
  totalHost: number;
  isClass: boolean;
  isOffline: boolean;
  offlineAddress?: string;
  offlineMapLink?: string;
  mapAddress?: string;
  eventStatus?: EventBidsStatus;
}
export enum EventBidsStatus {
  OPEN_FOR_BIDDING = 'OPEN_FOR_BIDDING',
  CLOSED = 'CLOSED',
}
