/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { TherapistExpertLevel } from 'projects/app-core/src/models/TherapistExpertLevel';
import { CircleTopics } from '../../../models/circles/AnyoCircles';
import { CirclesService } from '../../../services/circles.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { ExpertsService } from '../../../services/experts.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import {
  AnyoCurdComponent,
  CRUD,
} from 'projects/app-core/src/app/models/AnyoCrudComponent';
import { lastValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CreateExpertTherapistListenerRequest } from '../../../models/createExpertTherapistListener';
import { Status } from '../../../stores/types';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ExpertOnboardingStatus,
  ExpertOnboardRequests,
  ExpertsActivity,
  TherapistAvailabilityV2,
} from '../../../models/ExpertOnboardingRequests';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import * as moment from 'moment';

interface ExpertLevelArray {
  verticalId: string;
  verticalName: string;
  concernsList: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
  concerns: {
    concernsId: string;
    concernName: string;
    expertLevelId: string;
    expertLevel: string;
  }[];
}

interface DropdownOptions {
  value: string;
  data: string;
}

interface DisplayAvailabilityV2 extends TherapistAvailabilityV2 {
  color: string;
}

@Component({
  selector: 'app-crud-experts',
  templateUrl: './crud-experts.component.html',
  styleUrl: './crud-experts.component.scss',
})
export class CrudExpertsComponent extends AnyoCurdComponent {
  crudType: CRUD = CRUD.CREATE;
  archived = false;
  editPage = false;
  expertLevels: string[] = [];
  therapistExpertLevels: TherapistExpertLevel[] = [];
  selectedTherapistLevel: string = '';
  idealPrice = 0;
  rating = 0;
  userInterestTopics: CircleTopics[] = [];
  selectedUserInterests: string[] = [];
  tagLine = '';
  expertiseLevelArray: ExpertLevelArray[] = [];
  firstName = '';
  lastName = '';
  email = '';
  phoneNo = '';
  about = '';
  therapistImageFile!: File;
  videoBioFile!: File;
  videoSrc = '';
  profilePic = '';
  isExpert = false;
  isTherapist = false;
  isGoAnyoLive = false;
  isListener = false;
  title = '';
  yearOfExperiance = 0;
  selectedLanguage: string[] = [];
  userId = '';
  offlineCity: string = '';
  verticalIds: string[] = [];
  concernIds: string[] = [];
  concernExpertLevelMap: Record<
    string,
    {
      level: string;
      id: string;
    }
  > = {};

  maxPricing = 0;
  minPricing = 0;
  therapistSlug = '';
  expertOnboardingReqId!: string;
  expertOnboardRequest!: ExpertOnboardRequests;
  offlineAvailable: boolean = false;
  offlineAddress: string = '';
  offlineMapLink: string = '';
  mapAddress = '';
  isVisible = false;
  isTesting = false;
  isExternal = false;
  retailCost: number = 0;
  corporateCost: number = 0;

  constructor(
    protected override auth: AuthService,
    private circleService: CirclesService,
    private masterdataService: MasterdataService,
    private toastrService: ToastService,
    private experts: ExpertsService,
    private fileService: FileUtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public utils: UtilsService,
  ) {
    super(auth);
    this.addVertical();
  }

  override async getData(user?: IAnyoUser | undefined) {
    if (user) {
      if (this.crudType === CRUD.CREATE) {
        const id = this.activatedRoute.snapshot.params['onboardid'];
        if (id) {
          this.expertOnboardingReqId = id;
        }
        this.pageLoading = true;
        this.loadMasterData();
      } else if (this.crudType === CRUD.APPROVE) {
        const id = this.activatedRoute.snapshot.params['id'];
        if (id) {
          this.expertOnboardingReqId = id;
          this.pageLoading = true;
          this.loadMasterData();
        }
      } else {
        const value = this.activatedRoute.snapshot.params['id'];
        this.userId = value;
        this.pageLoading = true;
        this.loadMasterData();
      }
    }
  }

  availabilityV2: DisplayAvailabilityV2[] = [
    {
      day: 'monday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
    {
      day: 'tuesday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
    {
      day: 'wednesday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
    {
      day: 'thursday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
    {
      day: 'friday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
    {
      day: 'saturday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
    {
      day: 'sunday',
      color: '',
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        },
      ],
    },
  ];

  isVisibleTherapist = false;
  comment = '';
  isLoadingComment = false;

  showModal(): void {
    this.isVisible = true;
  }

  rejectOnboardStatus() {
    this.pageLoading = true;
    this.experts
      .updateOnboardStatus(
        this.expertOnboardRequest._id!,
        ExpertOnboardingStatus.REJECTED,
      )
      .subscribe({
        next: (value) => {
          this.toastrService.showSuccess('Successfully Rejected');
          this.pageLoading = false;
          this.router.navigate(['/experts/onboarding']);
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
          this.pageLoading = false;
        },
      });
  }

  navigateToCreateExpert() {
    this.router.navigate([
      '/experts/add/req/' + this.expertOnboardRequest.userId,
    ]);
  }

  requiredChangesOnboardStatus() {
    this.pageLoading = true;
    this.experts
      .updateOnboardStatus(
        this.expertOnboardRequest._id!,
        ExpertOnboardingStatus.REQUIRE_CHANGES,
      )
      .subscribe({
        next: () => {
          this.toastrService.showSuccess('Requested Changes');
          this.pageLoading = false;
          this.router.navigate(['/experts/onboarding']);
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
          this.pageLoading = false;
        },
      });
  }

  fetchExpertOnboardingRequest() {
    this.experts.getExpertRequestById(this.expertOnboardingReqId).subscribe({
      next: (value) => {
        // this.therapistSlug = "";
        // this.selectedAnyoTalks = "";
        this.title = value.basicInfo?.gender === 'Male' ? 'Mr' : 'Ms';
        this.isTherapist =
          value.activities?.includes(ExpertsActivity.THERAPIST) || false;
        this.isExpert =
          value.activities?.includes(ExpertsActivity.CIRCLE_EXPERT) || false;
        this.isListener =
          value.activities?.includes(ExpertsActivity.LISTENER) || false;
        this.firstName = value.basicInfo?.firstName || '';
        this.lastName = value.basicInfo?.lastName || '';
        this.email = value.basicInfo?.email || '';
        this.phoneNo = value.basicInfo?.mobileNumber || '';
        this.selectedTherapistLevel = ' ';
        this.rating = 0;
        this.tagLine = value.tagLine || '';

        // this.verticalIds = value.;
        // this.concernIds = value.concern;
        this.yearOfExperiance = value.yearsOfExperience || 0;
        this.profilePic = value.profilePic || '';
        this.about = value.description || '';
        this.avatarName = value.listener?.listenerName || '';
        this.avatar = value.listener?.listenerAvatar || '';
        this.isGoAnyoLive =
          value.activities?.includes(ExpertsActivity.GO_ANYO_LIVE) || false;

        this.expertOnboardRequest = value;
        this.minPricing =
          value.pricing && value.pricing.length ? value.pricing[0].min : 0;
        this.maxPricing =
          value.pricing && value.pricing.length ? value.pricing[0].max : 0;
        this.selectedLanguage = value.languages || [];
        this.selectedUserInterests =
          value.userInterest && value.userInterest.length > 0
            ? value.userInterest.map((data) => data.key)
            : [];

        if (value.listener) {
          this.selectedAvatarIndex = this.avatarImages.findIndex(
            (data) => data.value === value.listener?.listenerAvatar,
          );
          this.avatarName = value.listener?.listenerName;
          this.avatar =
            this.avatarImages.find(
              (d) => d.value === value.listener?.listenerAvatar,
            )?.data || 'avatar1';
        }
        if (value.specialization && value.specialization.length) {
          this.expertiseLevelArray = [];
          value.specialization.forEach((value3) => {
            const item: ExpertLevelArray = {
              verticalId: value3.verticalId,
              verticalName: '',
              concernsList: this.concernsMasterData
                .filter((value1) =>
                  value1.verticalId.includes(value3.verticalId),
                )
                .map((value2) => {
                  return {
                    ...value2,
                    disabled: value3.concerns
                      .map((data) => data.concernId)
                      .includes(value2.value),
                  };
                }),
              concerns: value3.concerns.map((data) => {
                return {
                  concernsId: data.concernId,
                  concernName: data.concernName,
                  expertLevelId: data.expertLevelId,
                  expertLevel: '',
                };
              }),
            };

            if (!item.concerns.length) {
              item.concerns.push({
                concernsId: '',
                concernName: '',
                expertLevelId: '',
                expertLevel: '',
              });
            }
            this.expertiseLevelArray.push(item);
            this.verticalsMasterDataFiltered = this.verticalsMasterData.map(
              (value1) => {
                return {
                  ...value1,
                  disabled: value
                    .specialization!.map((data) => data.verticalId)
                    .includes(value1.value),
                };
              },
            );
          });

          if (value.availability && value.availability.length) {
            this.availabilityV2 = value.availability.map((data) => {
              return {
                day: data.day,
                color: this.utils.getRandomColor(),
                slots: data.slots,
              };
            });
          }
        }
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }

  changeActiveListener($event: boolean) {
    this.isListener = true;
  }

  uploadTherapistImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.therapistImageFile = file;
      this.readMedia(this.therapistImageFile);
    }
  }

  uploadVideoImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.videoBioFile = file;
      this.previewVideo(this.videoBioFile);
    }
  }

  previewVideo(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.videoSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  readMedia(file: File) {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.profilePic = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  avatarCard = false;
  avatar = '';
  thumbnail = '';
  avatarName = '';

  show() {
    this.avatarCard = !this.avatarCard;
    const commonCard = document.getElementById('card');
    const choose = document.getElementById('chooseAvatar');
    if (choose && commonCard) {
      if (this.avatarCard) {
        const elementPosition = choose.getBoundingClientRect();
        commonCard.style.display = 'flex';
        commonCard.style.top = `${elementPosition.bottom}px`;
        commonCard.style.left = `${elementPosition.left}px`;
      } else {
        commonCard.style.display = 'none';
      }
    }
  }

  uploadAvatar(id: string, label: string) {
    const commonCard = document.getElementById('card');
    if (commonCard) commonCard.style.display = 'none';
    this.avatar = label;
    this.thumbnail = id;
  }
  selectedAvatarIndex: number | null = null;
  uploadAvatarOnBoard(id: string, label: string, index: number) {
    this.avatar = label;
    this.thumbnail = id;
    this.selectedAvatarIndex = index;
  }

  addVertical() {
    this.expertiseLevelArray.push({
      verticalId: '',
      verticalName: '',
      concernsList: [],
      concerns: [
        {
          concernsId: '',
          concernName: '',
          expertLevelId: '',
          expertLevel: '',
        },
      ],
    });
  }
  deleteVertical(i: number) {
    this.expertiseLevelArray.splice(i, 1);
    const selectedVerticalsIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.verticalsMasterDataFiltered.forEach((value) => {
      value.disabled = selectedVerticalsIds.includes(value.value);
    });
  }
  addConcern(i: number) {
    this.expertiseLevelArray[i].concerns.push({
      concernsId: '',
      concernName: '',
      expertLevelId: '',
      expertLevel: '',
    });
  }
  deleteConcern(i: number, j: number) {
    this.expertiseLevelArray[i].concerns.splice(j, 1);
    const selectedConcernsIds = this.expertiseLevelArray[i].concerns.map(
      (value) => value.concernsId,
    );
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) =>
        value.verticalId.includes(this.expertiseLevelArray[i].verticalId),
      )
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: selectedConcernsIds.includes(value.value),
        };
      });
  }
  verticalChange(i: number, $event: any) {
    this.expertiseLevelArray[i].verticalId = $event;
    const selectedVerticalsIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.verticalsMasterDataFiltered = this.verticalsMasterData.map((value) => {
      return {
        value: value.value,
        label: value.label,
        disabled: selectedVerticalsIds.includes(value.value),
      };
    });
    this.expertiseLevelArray[i].verticalName =
      this.verticalsMasterDataFiltered.find((value) => value.value == $event)
        ?.label || '';
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) => value.verticalId.includes($event))
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: false,
        };
      });
  }
  concernChange(i: number, j: number, $event: any) {
    this.expertiseLevelArray[i].concerns[j].concernsId = $event;
    this.expertiseLevelArray[i].concerns[j].concernName =
      this.expertiseLevelArray[i].concernsList.find(
        (value) => value.value == $event,
      )?.label || '';
    const selectedConcernsIds = this.expertiseLevelArray[i].concerns.map(
      (value) => value.concernsId,
    );
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) =>
        value.verticalId.includes(this.expertiseLevelArray[i].verticalId),
      )
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: selectedConcernsIds.includes(value.value),
        };
      });
  }
  expertLevelChange(i: number, j: number, $event: any) {
    this.expertiseLevelArray[i].concerns[j].expertLevelId = $event;
    this.expertiseLevelArray[i].concerns[j].expertLevel =
      this.expertLevelMasterData.find((value) => value.value == $event)
        ?.label || '';
  }
  verticalConcernMap: Record<
    string,
    {
      label: string;
      value: string;
    }[]
  > = {};
  expertLevelMasterData: {
    label: string;
    value: string;
  }[] = [];
  verticalsMasterData: {
    label: string;
    value: string;
  }[] = [];
  verticalsMasterDataFiltered: {
    label: string;
    value: string;
    disabled: boolean;
  }[] = [];
  concernsMasterData: {
    verticalId: string[];
    label: string;
    value: string;
  }[] = [];
  expertise: DropdownOptions[] = [];
  languages: DropdownOptions[] = [];
  validateExpertLevelArray() {
    let isValid = true;
    if (this.expertiseLevelArray.length == 0) {
      isValid = false;
    }
    this.expertiseLevelArray.forEach((value) => {
      if (!value.verticalId) {
        isValid = false;
      }
      value.concerns.forEach((concern) => {
        if (!concern.concernsId || !concern.expertLevelId) {
          isValid = false;
        }
      });
    });

    return isValid;
  }

  avatarImages: DropdownOptions[] = [];
  avatarNames: DropdownOptions[] = [];
  anyoTalks: DropdownOptions[] = [];
  selectedAnyoTalks: string[] = [];

  async loadMasterData() {
    this.userInterestTopics = await this.circleService.getUserInterestsMeta();
    this.masterdataService.masterDataList().subscribe({
      next: (masterdata) => {
        masterdata.anyoTalks.forEach((data) => {
          const dropdownOptionsanyoTalks: DropdownOptions = {
            data: data.name.toString(),
            value: data._id?.toString(),
          };
          this.anyoTalks.push(dropdownOptionsanyoTalks);
        });
        if (masterdata.languages) {
          masterdata.languages.forEach((data) => {
            const dropdownOptionsLanguages: DropdownOptions = {
              data: data.data.toString(),
              value: data.m_id?.toString() || '',
            };
            this.languages.push(dropdownOptionsLanguages);
          });
        }
        if (masterdata.areasOfExpertise) {
          masterdata.areasOfExpertise.forEach((data) => {
            const areasOfExpertise: DropdownOptions = {
              data: data.data,
              value: data.data,
            };
            this.expertise.push(areasOfExpertise);
          });
        }
        if (masterdata.therapyVerticals) {
          masterdata.therapyVerticals.forEach((data) => {
            const verticalsDropDownOption = {
              label: data.data,
              value: data.m_id,
              disabled: false,
            };
            this.verticalsMasterData.push(verticalsDropDownOption);
            this.verticalsMasterDataFiltered.push(verticalsDropDownOption);
          });
        }
        if (masterdata.therapyConcerns) {
          masterdata.therapyConcerns.forEach((data) => {
            const select2Data = {
              verticalId: data.verticalId,
              label: data.data,
              value: data.m_id,
            };
            this.concernsMasterData.push(select2Data);
          });
        }
        if (masterdata.therapistExpertLevels) {
          masterdata.therapistExpertLevels.forEach((data) => {
            const select2Data = {
              label: data.data,
              value: data.m_id,
            };
            this.expertLevelMasterData.push(select2Data);
          });
        }

        masterdata.avatarImages.forEach((data) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id!,
                data: data.data,
              };
              this.avatarImages.push(obj);
            }
          }
        });

        masterdata.avatarNames.forEach((data) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id!,
                data: data.data,
              };
              this.avatarNames.push(obj);
            }
          }
        });

        this.therapistExpertLevels = masterdata.therapistExpertLevels;
        this.expertLevels = this.therapistExpertLevels.map(
          (value) => value.data,
        );
        if (this.crudType === CRUD.CREATE) {
          if (this.expertOnboardingReqId) {
            this.fetchExpertOnboardingRequest();
          }
          this.pageLoading = false;
        }
        if (this.crudType === CRUD.APPROVE) {
          this.fetchExpertOnboardingRequest();
        }
        if (this.crudType === CRUD.UPDATE) {
          this.get(this.userId);
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
        this.pageLoading = false;
      },
    });
  }

  validation() {
    if (
      !this.title ||
      !this.firstName ||
      !this.lastName ||
      !this.email ||
      !this.phoneNo
    ) {
      this.toastrService.showError('Please enter all fields in general');
      this.pageLoading = false;
      return true;
    }
    if (this.offlineAvailable && (!this.offlineCity || !this.offlineAddress)) {
      this.toastrService.showError('Please enter offline city and address');
      this.pageLoading = false;
      return true;
    }
    if (!this.profilePic) {
      this.toastrService.showError('Please upload profile image');
      this.pageLoading = false;
      return true;
    }
    if (this.isTherapist) {
      if (
        !this.validateExpertLevelArray() ||
        this.tagLine === '' ||
        this.rating <= 0 ||
        this.maxPricing <= 0 ||
        this.selectedLanguage.length <= 0 ||
        this.about === '' ||
        this.yearOfExperiance <= 0 ||
        this.selectedTherapistLevel === ''
      ) {
        this.toastrService.showError(
          'Please enter all fields in expert consultation',
        );
        this.pageLoading = false;
        return true;
      }
    }
    return false;
  }

  override async create() {
    this.pageLoading = true;
    if (this.validation()) {
      return;
    }
    this.verticalIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.concernIds = this.expertiseLevelArray
      .map((value) => value.concerns.map((concern) => concern.concernsId))
      .flat();
    this.concernExpertLevelMap = this.expertiseLevelArray.reduce(
      (
        acc: Record<
          string,
          {
            level: string;
            id: string;
          }
        >,
        value,
      ) => {
        value.concerns.forEach((concern) => {
          acc[concern.concernsId] = {
            id: concern.expertLevelId,
            level: concern.expertLevel,
          };
        });
        return acc;
      },
      {},
    );

    this.userInterestTopics = this.userInterestTopics
      .filter((value) => this.selectedUserInterests.includes(value.key))
      .map((value) => {
        return {
          key: value.key,
          value: value.value,
        };
      });
    const newExperts: CreateExpertTherapistListenerRequest = {
      mapAddress: this.mapAddress,
      offlineAvailable: this.offlineAvailable,
      offlineMapLink: this.offlineMapLink,
      offlineAddress: this.offlineAddress,
      offlineCity: this.offlineCity,
      availabilityV2: this.availabilityV2.map((data) => {
        return {
          day: data.day,
          slots: data.slots,
        };
      }),
      startDate:
        this.expertOnboardRequest &&
          this.expertOnboardRequest.startDate &&
          moment(this.expertOnboardRequest.startDate.startDate, 'x')
          ? moment(this.expertOnboardRequest.startDate.startDate, 'x').format(
            'x',
          )
          : moment().format('x'),
      slug: this.therapistSlug,
      archived: this.archived,
      anyotalksIds: this.selectedAnyoTalks,
      activeTherapist: this.isTherapist,
      activeExpert: this.isExpert,
      activeListener: this.isListener,
      goLivePermission: this.isGoAnyoLive,
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      mobile: this.phoneNo,
      languagesKnown: this.selectedLanguage,
      desginationLevel: this.selectedTherapistLevel,
      rating: this.rating,
      pricing: {
        minPricing: this.minPricing,
        maxPricing: this.maxPricing,
      },
      userInterest: this.userInterestTopics,
      tagLine: this.tagLine,
      verticle: this.verticalIds,
      concern: this.concernIds,
      concernExpertLevelMap: this.concernExpertLevelMap,
      yearsOfExperience: this.yearOfExperiance,
      profilePicture: this.profilePic,
      aboutTherapist: this.about,
      videoBio: this.videoSrc,
      avatarName: this.avatarName,
      avatarPic: this.avatar,
      isVisible: this.isVisibleTherapist,
      isTesting: this.isTesting,
      isExternal: this.isExternal,
      retailCost: this.retailCost,
      corporateCost: this.corporateCost,
    };
    if (this.isGoAnyoLive && (!this.anyoTalks || !this.anyoTalks.length)) {
      this.toastrService.showError('Please select a live');
      return;
    }
    if (this.videoBioFile) {
      const uuid = uuidv4();
      const uuidWithoutHyphens = uuid.replace(/-/g, '');
      const extension = this.videoBioFile.name.split('.').pop();
      const videoBioFilePath = `therapist/${uuidWithoutHyphens}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          videoBioFilePath,
          this.videoBioFile.type,
        ),
      );
      this.toastrService.showSuccess(`Uploading Therapist Video Bio`);
      await lastValueFrom(
        this.fileService.uploadFile(this.videoBioFile, uploadUrl.url),
      );
      this.toastrService.showSuccess(`Uploading Therapist Video Bio completed`);
      newExperts.videoBio = videoBioFilePath;
    }
    if (this.videoBioFile) {
      newExperts.videoBio = await this.saveMediaFiles(
        this.videoBioFile,
        'therapist',
        'Video Bio',
      );
    }

    if (this.therapistImageFile) {
      newExperts.profilePicture = await this.saveMediaFiles(
        this.therapistImageFile,
        'therapist',
        'Profile Pic',
      );
    }
    if (this.expertOnboardingReqId) {
      newExperts.onBoardingRequestId = this.expertOnboardingReqId;
    }
    this.experts.createExpert(newExperts).subscribe({
      next: () => {
        this.toastrService.showSuccess('Successfully created');
        this.router.navigate(['/experts']);
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }

  override async get(userId: string) {
    this.pageLoading = true;
    this.experts.getExpertTherapistListener(userId).subscribe({
      next: (value) => {
        this.isTesting = value.isTesting;
        this.isExternal = value.isExternal;
        this.isVisibleTherapist = value.isVisible;
        this.mapAddress = value.mapAddress || '';
        this.offlineAddress = value.offlineAddress || '';
        this.offlineAvailable = value.offlineAvailable;
        this.offlineMapLink = value.offlineMapLink || '';
        this.offlineCity = value.offlineCity || '';
        this.therapistSlug = value.slug;
        this.selectedAnyoTalks = value.anyotalksIds;
        this.isTherapist = value.activeTherapist;
        this.isExpert = value.activeExpert;
        this.isListener = value.activeListener;
        this.title = value.title;
        this.firstName = value.firstName;
        this.lastName = value.lastName;
        this.email = value.email;
        this.phoneNo = value.mobile;
        this.selectedLanguage = value.languagesKnown;
        this.selectedTherapistLevel = value.desginationLevel;
        this.rating = value.rating;
        this.maxPricing = value.pricing?.maxPricing || 0;
        this.minPricing = value.pricing?.minPricing || 0;
        this.selectedUserInterests =
          value.userInterest && value.userInterest.length > 0
            ? value.userInterest.map((data) => data.key)
            : [];
        this.tagLine = value.tagLine;
        this.verticalIds = value.verticle;
        this.concernIds = value.concern;
        this.yearOfExperiance = value.yearsOfExperience;
        this.profilePic = value.profilePicture;
        this.about = value.aboutTherapist;
        this.videoSrc = value.videoBio;
        this.avatarName = value.avatarName;
        this.avatar = value.avatarPic;
        this.isGoAnyoLive = value.goLivePermission;
        this.retailCost = value.retailCost || 0;
        this.corporateCost = value.corporateCost || 0;
        if (value.verticle && value.verticle.length) {
          this.expertiseLevelArray = [];
          value.verticle.forEach((value3) => {
            const item: ExpertLevelArray = {
              verticalId: value3,
              verticalName: '',
              concernsList: this.concernsMasterData
                .filter((value1) => value1.verticalId.includes(value3))
                .map((value2) => {
                  return {
                    ...value2,
                    disabled: value.concern.includes(value2.value),
                  };
                }),
              concerns: [],
            };
            item.concernsList.forEach((v1) => {
              const expertLevel = value.concernExpertLevelMap[v1.value];
              if (expertLevel) {
                const it = {
                  concernsId: v1.value,
                  concernName: v1.label,
                  expertLevelId: expertLevel.id,
                  expertLevel: '',
                };
                item.concerns.push(it);
              }
            });
            if (!item.concerns.length) {
              item.concerns.push({
                concernsId: '',
                concernName: '',
                expertLevelId: '',
                expertLevel: '',
              });
            }
            this.expertiseLevelArray.push(item);
          });
          this.verticalsMasterDataFiltered = this.verticalsMasterData.map(
            (value1) => {
              return {
                ...value1,
                disabled: value.verticle.includes(value1.value),
              };
            },
          );
        }
        if (value.availabilityV2 && value.availabilityV2.length) {
          this.availabilityV2 = value.availabilityV2.map((data) => {
            return {
              day: data.day,
              color: this.utils.getRandomColor(),
              slots: data.slots,
            };
          });
        }
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }

  async saveMediaFiles(file: File, pathName: string, toastText: string) {
    const uuid = uuidv4();
    const uuidWithoutHyphens = uuid.replace(/-/g, '');
    const extension = file.name.split('.').pop();
    const filePath = `${pathName}/${uuidWithoutHyphens}.${extension}`;
    const uploadUrl = await lastValueFrom(
      this.fileService.generateUploadUrl(
        AnyoS3Buckets.CDN_BUCKET,
        filePath,
        file.type,
      ),
    );
    this.toastrService.showSuccess(`Uploading ${toastText}`);
    await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
    this.toastrService.showSuccess(`Uploading ${toastText} completed`);
    return `https://cdn.anyo.app/${filePath}`;
  }

  override async update(): Promise<void> {
    this.pageLoading = true;
    if (this.validation()) {
      return;
    }
    this.verticalIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.concernIds = this.expertiseLevelArray
      .map((value) => value.concerns.map((concern) => concern.concernsId))
      .flat();
    this.concernExpertLevelMap = this.expertiseLevelArray.reduce(
      (
        acc: Record<
          string,
          {
            level: string;
            id: string;
          }
        >,
        value,
      ) => {
        value.concerns.forEach((concern) => {
          acc[concern.concernsId] = {
            id: concern.expertLevelId,
            level: concern.expertLevel,
          };
        });
        return acc;
      },
      {},
    );

    this.userInterestTopics = this.userInterestTopics
      .filter((value) => this.selectedUserInterests.includes(value.key))
      .map((value) => {
        return {
          key: value.key,
          value: value.value,
        };
      });
    const updateExpert: CreateExpertTherapistListenerRequest = {
      mapAddress: this.mapAddress,
      offlineMapLink: this.offlineMapLink,
      offlineAddress: this.offlineAddress,
      offlineAvailable: this.offlineAvailable,
      offlineCity: this.offlineCity,
      availabilityV2: this.availabilityV2.map((data) => {
        return {
          day: data.day,
          slots: data.slots,
        };
      }),
      slug: this.therapistSlug,
      archived: this.archived,
      anyotalksIds: this.selectedAnyoTalks,
      activeTherapist: this.isTherapist,
      activeExpert: this.isExpert,
      activeListener: this.isListener,
      goLivePermission: this.isGoAnyoLive,
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      mobile: this.phoneNo,
      languagesKnown: this.selectedLanguage,
      desginationLevel: this.selectedTherapistLevel,
      rating: this.rating,
      pricing: {
        minPricing: this.minPricing,
        maxPricing: this.maxPricing,
      },
      userInterest: this.userInterestTopics.filter((data) =>
        this.selectedUserInterests.includes(data.key),
      ),
      tagLine: this.tagLine,
      verticle: this.verticalIds,
      concern: this.concernIds,
      concernExpertLevelMap: this.concernExpertLevelMap,
      yearsOfExperience: this.yearOfExperiance,
      profilePicture: this.profilePic,
      aboutTherapist: this.about,
      videoBio: this.videoSrc,
      avatarName: this.avatarName,
      avatarPic: this.avatar,
      isVisible: this.isVisibleTherapist,
      isTesting: this.isTesting,
      isExternal: this.isExternal,
      retailCost: this.retailCost,
      corporateCost: this.corporateCost,
    };

    if (this.videoBioFile) {
      updateExpert.videoBio = await this.saveMediaFiles(
        this.videoBioFile,
        'therapist',
        'Video Bio',
      );
    }

    if (this.therapistImageFile) {
      updateExpert.profilePicture = await this.saveMediaFiles(
        this.therapistImageFile,
        'therapist',
        'Profile Pic',
      );
    }
    this.experts.editExpert(this.userId, updateExpert).subscribe({
      next: () => {
        this.router.navigate(['/experts']);
        this.toastrService.showSuccess('Successfully Updated');
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }

  async downloadPdf(id: string) {
    this.experts.getPdfDownLoadUrl(id).subscribe({
      next: (url) => {
        window.open(url.resumeDownloadUrl, '_blank');
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
      },
    });
  }
}
