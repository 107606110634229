<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<nz-spin [nzSpinning]="pageLoading" nzTip="Fetching Experts request....">
  <div style="background: #ECECEC; padding:30px;" class="flex gap-3 w-full">
    <span nz-tooltip nzTooltipTitle="Go Back">
      <button nz-button class="save" nzShape="circle" [routerLink]="['/experts/onboarding/']">
        <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
      </button>
    </span>
    <button *ngIf="showAlreadyRejected()" class="flex align-center items-center justify-center" nz-button>
      <span nz-icon nzType="close-circle" nzTheme="outline"></span>
      Already Rejected</button>
    <button *ngIf="showAlreadyApproved()" class="flex align-center items-center justify-center" nz-button>
      <span nz-icon nzType="check-circle" nzTheme="twotone" nzTwotoneColor="#52c41a"></span>
      Already Approved</button>
    <button *ngIf="showRequiredChanges()" (click)="requiredChangesOnboardStatus()"
      class="flex items-center align-center justify-center" nz-button>
      <span nz-icon nzType="warning" nzTheme="outline"></span>
      Required Changes</button>

    <button *ngIf="showBtnChanges()" (click)="navigateToCreateExpert()" nz-button
      class="flex items-center align-center justify-center">
      <span nz-icon nzType="check-circle" nzTheme="twotone" nzTwotoneColor="#52c41a"></span>
      Approve
    </button>
    <button *ngIf="showBtnChanges()" (click)="rejectOnboardStatus()" nz-button
      class="flex items-center align-center justify-center">
      <span nz-icon nzType="close-circle" nzTheme="outline"></span>
      Reject
    </button>
    <button *ngIf="profilePic" (click)="downloadImage()" nz-button
      class="flex items-center align-center justify-center">
      <span nz-icon nzType="download" nzTheme="outline"></span>
      Download Image
    </button>
  </div>
  <div class="flex flex-col p-4 ">
    <nz-steps nzSize="small" [nzCurrent]="current">
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
      <nz-step nzTitle=""></nz-step>
    </nz-steps>

    <div class="steps-content flex align-center justify-center">
      @if(current === 0 && expertOnboardRequest && expertOnboardRequest.basicInfo){
      <div style="background: #ECECEC; padding:30px;">
        <nz-card style="width:300px;" [nzBordered]="false" nzTitle="Basic Expert Info" [nzCover]="coverTemplate">
          <p> UserName : {{expertOnboardRequest.basicInfo!.firstName + ' ' + expertOnboardRequest.basicInfo!.lastName}}
          </p>
          <p> Email : {{expertOnboardRequest.basicInfo!.email}}</p>
          <p> phoneNo : {{expertOnboardRequest.basicInfo!.mobileNumber || "N/A"}}</p>
          <p>Title : {{expertOnboardRequest.basicInfo!.title}}</p>
          <p>Gender :
            {{expertOnboardRequest.basicInfo!.gender}}
          </p>
        </nz-card>
        <ng-template #coverTemplate>
          <img alt="example" class=" h-96 w-96 object-cover " [src]="expertOnboardRequest.profilePic" />
        </ng-template>
      </div>
      }
      @if(current === 1){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Expert Activity Form">
          <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="log($event)">
            <div nz-col class="flex flex-col items-start">
              <div nz-col nzSpan="8" *ngFor="let activity of expertActivities"><label nz-checkbox [nzValue]="activity"
                  [nzDisabled]="true"
                  [nzChecked]="expertOnboardRequest.activities?.includes(activity) || false">{{utils.convertToTitleCase(activity)}}</label>
              </div>
            </div>
          </nz-checkbox-wrapper>
        </nz-card>
      </div>
      }
      @if(current === 2){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Expert Profile Creation Form">
            <div class="grid grid-cols-3 gap-2">
                <div>
                    <label class="form-label required">Years Of Experiance</label>
                    <input
                    nz-input
                    type="number"
                    placeholder="Enter Year Of Experiance"
                    [(ngModel)]="expertOnboardRequest.yearsOfExperience"
                    class="w-full"
                    [disabled]="true"
                  />
                </div>
                <div>
                  <label class="form-label">Max Pricing :</label>
                  <input
                    nz-input
                    type="number"
                    placeholder="Enter Max Pricing"
                    [(ngModel)]="maxPricing"
                    class="w-full"
                    [disabled]="true"
                  />
                </div>
                <div>
                  <label class="form-label">Min Pricing :</label>
                  <input
                    nz-input
                    type="number"
                    placeholder="Enter Min Pricing"
                    [(ngModel)]="minPricing"
                    class="w-full"
                    [disabled]="true"
                  />
                </div>
                <div>
                    <label class="form-label">TagLine</label>
                    <div
                      class="w-full border border-gray-300 p-2 rounded bg-gray-100 text-gray-700"
                      [ngClass]="{'cursor-not-allowed': true}"
                    >
                      {{ expertOnboardRequest.tagLine || 'Enter Tag Line' }}
                    </div>
                  </div>
                  <div>
                    <label class="form-label required">Languages</label>
                    <nz-select
                    class="w-full"
                    [(ngModel)]="expertOnboardRequest.languages"
                    nzPlaceHolder="Select Languages"
                    nzMode="multiple"
                    [nzDisabled]="true"
                  >
                    <nz-option
                      *ngFor="let expItem of languages"
                      [nzLabel]="expItem.data"
                      [nzValue]="expItem.value"
                    ></nz-option>
                  </nz-select>
                </div>
              </div>
        </nz-card>
      </div>
      }
      @if(current === 3){

      <div style="background: #ECECEC; padding:30px; width: 100%;">
        @if(expertiseLevelArray && expertiseLevelArray.length > 0 &&
        expertOnboardRequest.activities?.includes(ExpertsActivity.THERAPIST) || false){
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Choose Area of Specialization Form">
          <div class="grid grid-cols-2 gap-3">
            <div *ngFor="let item of expertiseLevelArray; index as i" class="border border-gray-500 rounded-lg p-5">
              <div class="flex flex-row justify-between gap-3">
                <nz-select class="w-[130%]" [ngModel]="item.verticalId" nzPlaceHolder="Select Vertical"
                  (ngModelChange)="verticalChange(i, $event)" [nzDisabled]="true">
                  <nz-option *ngFor="let vertical of verticalsMasterDataFiltered" [nzValue]="vertical.value"
                    [nzLabel]="vertical.label" [nzDisabled]="vertical.disabled"></nz-option>
                </nz-select>
              </div>
              <br />
              <div class="flex flex-col gap-3">
                <div *ngFor="let concern of item.concerns; index as j">
                  <div class="flex flex-row flex-1 gap-3">
                    <nz-select class="w-[100%]" [ngModel]="concern.concernsId" nzPlaceHolder="Select Concern"
                      (ngModelChange)="concernChange(i, j, $event)" [nzDisabled]="true">
                      <nz-option *ngFor="let cItem of item.concernsList" [nzLabel]="cItem.label" [nzValue]="cItem.value"
                        [nzDisabled]="cItem.disabled"></nz-option>
                    </nz-select>
                    <nz-select [ngModel]="concern.expertLevelId" class="w-[100%]" nzPlaceHolder="Select Expertise"
                      (ngModelChange)="expertLevelChange(i, j, $event)" [nzDisabled]="true">
                      <nz-option *ngFor="let expItem of expertLevelMasterData" [nzLabel]="expItem.label"
                        [nzValue]="expItem.value"></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-card>
        }
        @else{
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Choose Area of Specialization Form">
          <nz-descriptions nzTitle="No Expertise Level Array">
            <nz-descriptions-item nzTitle="Expertise Level Array">
              <nz-tag [nzColor]="'red'">User has not opted for therapist profile</nz-tag>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
        }
      </div>
      }
      @if(current === 4){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="When Can you Start Form">
          <nz-tag [nzColor]="'red'" class="p-3">
            {{ 
              expertOnboardRequest.startDate 
                ? (expertOnboardRequest.startDate.isImmediate 
                    ? "Immediate" 
                    : utils.convertEpochToFormattedDate(expertOnboardRequest.startDate.startDate!)) 
                : "N/A" 
            }}
          </nz-tag>
          
        </nz-card>
      </div>
      }
      @if(current === 5){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        @if(userInterestTopics && userInterestTopics.length > 0 &&
        expertOnboardRequest.activities?.includes(ExpertsActivity.CIRCLE_EXPERT) || false){
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Circle Profile Communities Form">
          <div class="grid grid-rows-2 gap-3">
            <div class="flex flex-col w-full">
              <label class="form-label">Select User Interest :</label>
              <nz-select [(ngModel)]="selectedUserInterests" nzPlaceHolder="Select User Interests" nzMode="multiple"
                [nzDisabled]="true">
                <nz-option *ngFor="let item of userInterestTopics" [nzLabel]="item.value"
                  [nzValue]="item.key"></nz-option>
              </nz-select>
            </div>
          </div>
        </nz-card>
        }
        @else{
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Circle Profile Communities Form">
          <nz-descriptions nzTitle="No User Interests">
            <nz-descriptions-item nzTitle="User Interests">
              <nz-tag [nzColor]="'red'">User has not selected any user interests</nz-tag>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
        }
      </div>
      }
      @if(current === 6){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        @if(expertOnboardRequest.activities?.includes(ExpertsActivity.LISTENER) || false){
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Listener Profile Form">
          <div class="grid grid-rows-2">
            <!-- Avatar Images Section -->
            <div class="flex flex-wrap gap-2">
              <div *ngFor="let avatar of avatarImages; index as i">
                <img *ngIf="selectedAvatarIndex === i" class="m-2 cursor-pointer"
                  src="../../../../assets/icons/{{ avatar.data }}.png"
                  (click)="uploadAvatarOnBoard(avatar.value, avatar.data, i)" alt="i" />
              </div>
            </div>

            <!-- Avatar Name Section -->
            <div class="mt-4">
              <label class="block mb-2 font-medium text-gray-700">Avatar Name</label>
              <nz-select [(ngModel)]="avatarName" class="w-full max-w-[300px]" nzPlaceHolder="Select Avatar Name"
                [nzDisabled]="true">
                <nz-option *ngFor="let expItem of avatarNames" [nzLabel]="expItem.data"
                  [nzValue]="expItem.value"></nz-option>
              </nz-select>
            </div>
          </div>
        </nz-card>
        }
        @else{
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Listener Profile Form">
          <nz-descriptions nzTitle="No Avatar Images">
            <nz-descriptions-item nzTitle="Avatar Images">
              <nz-tag [nzColor]="'red'">User has not opted for listener profile</nz-tag>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
        }
      </div>
      }
      @if(current === 7){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        @if(expertOnboardRequest.eventsCategories &&
        (expertOnboardRequest.eventsCategories.userSelected &&
        expertOnboardRequest.eventsCategories.userSelected.length > 0 ||
        expertOnboardRequest.eventsCategories.userSuggested)){
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Event Host Form">
          <div class="grid grid-rows-2 gap-3">
            <div class="flex flex-col w-full">
              <label class="form-label">Host Events :</label>
              <nz-select [(ngModel)]="expertOnboardRequest.eventsCategories!.userSelected"
                nzPlaceHolder="Suggested Event" nzMode="multiple" [nzDisabled]="true">
                <nz-option *ngFor="let item of verticalsMasterData" [nzLabel]="item.label"
                  [nzValue]="item.value"></nz-option>
              </nz-select>
            </div>
          </div>
          <nz-descriptions nzTitle="User Suggested Events">
            <nz-descriptions-item
              nzTitle="Events Name">{{expertOnboardRequest.eventsCategories.userSuggested}}</nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
        }
        @else{
        Hi
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Event Host Form">
          <nz-descriptions nzTitle="No User Suggested Events">
            <nz-descriptions-item nzTitle="User Suggested Events">
              <nz-tag [nzColor]="'red'">User has not selected any events</nz-tag>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
        }
      </div>
      }
      @if(current === 8){
      @if(expertOnboardRequest.activities?.includes(ExpertsActivity.THERAPIST) || false){
      <div *ngIf="expertOnboardRequest.location?.isOfflineConsultation"
        style="background: #ECECEC; padding:30px; width: 100%;">
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Location Form">
          <nz-descriptions nzTitle="Location Preferences" nzBordered>
            <nz-descriptions-item nzTitle="Address :"
              [nzSpan]="3">{{expertOnboardRequest.location?.location?.address}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="City :"
              [nzSpan]="3">{{expertOnboardRequest.location?.location?.city}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Offline Consultation :" [nzSpan]="3">
              <nz-tag
                [nzColor]="expertOnboardRequest.location?.isOfflineConsultation ? 'green' :'red'">{{expertOnboardRequest.location?.isOfflineConsultation
                ? "yes" : "No"}}</nz-tag>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
      </div>
      <div *ngIf="!expertOnboardRequest.location?.isOfflineConsultation"
        style="background: #ECECEC; padding:30px; width: 100%;">
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Location Form">
          <nz-descriptions nzTitle="Location Preferences" nzBordered>
            <nz-descriptions-item nzTitle="Offline Consultation :" [nzSpan]="3">
              <nz-tag
                [nzColor]="expertOnboardRequest.location?.isOfflineConsultation ? 'green' :'red'">{{expertOnboardRequest.location?.isOfflineConsultation
                ? "yes" : "No"}}</nz-tag>
            </nz-descriptions-item>
          </nz-descriptions>
        </nz-card>
      </div>
      }
      @else{
      <nz-card style="width:100%" [nzBordered]="false" nzTitle="Location Form">
        <nz-descriptions nzTitle="Location Preferences" nzBordered>
          <nz-descriptions-item nzTitle="Offline Consultation :" [nzSpan]="3">
            <nz-tag [nzColor]="'red'">User has not opted for therapist profile</nz-tag>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
      }
      }
      @if(current === 9){
      @if(expertOnboardRequest.activities?.includes(ExpertsActivity.THERAPIST) || false){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        <nz-card style="width:100%" [nzBordered]="false" nzTitle="Expert Availability Form">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2" *ngFor="let day of availabilityV2; let i = index">
              <div class="title">
                <nz-tag class="whitespace-nowrap text-base px-4 py-2"
                  [nzColor]="day.color">{{toUpperCaseFirstLetter(day.day) + " :"}}</nz-tag>
              </div>
              <div class="flex gap-2" *ngFor="let slot of day.slots; let index = index">

                <nz-ribbon nzSize="default" [nzText]="(index + 1).toString()" [nzColor]="day.color">
                  <div class="passenger-input">
                    <input nz-input [(ngModel)]="slot.slotStart" type="time" />
                    <input class="mt-2" nz-input [(ngModel)]="slot.slotEnd" type="time" />
                  </div>
                </nz-ribbon>

              </div>
            </div>
          </div>
        </nz-card>
      </div>
      }
      @else{
      <nz-card style="width:100%" [nzBordered]="false" nzTitle="Expert Availability Form">
        <nz-descriptions nzTitle="Expert Availability">
          <nz-descriptions-item nzTitle="Expert Availability">
            <nz-tag [nzColor]="'red'">User has not opted for therapist profile</nz-tag>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-card>
      }
      }
      @if (current === 10){
      <div style="background: #ECECEC; padding:30px; width: 100%;">
        <button nz-button nzType="primary" (click)="downloadPdf(expertOnboardRequest._id!)">Download Resume</button>
      </div>
      }
    </div>
    <div class="steps-action">
      @if (current > 0) {
      <button nz-button nzType="default" (click)="pre()">
        <span>Previous</span>
      </button>
      }
      @if (current < 10) { <button nz-button nzType="default" (click)="next()">
        <span>Next</span>
        </button>
        }
        <button *ngIf="expertOnboardRequest.onboardingStatus !== ExpertOnboardingStatus.PENDING" nz-button (click)="showModal()">
          <span nz-icon nzType="comment" nzTheme="outline"></span>
          Add Comments</button>
    </div>
  </div>
  <div style="background: #ECECEC; padding:30px; width: 100%;">
    <nz-list *ngIf="expertOnboardRequest && expertOnboardRequest.comments && expertOnboardRequest.comments.length"
      nzItemLayout="horizontal" [nzLoading]="pageLoading">
      <nz-list-item *ngFor="let item of expertOnboardRequest.comments;index as i">
        <nz-list-item-meta *ngIf="i === expertOnboardRequest.comments.length" #targetDiv
          nzAvatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          [nzDescription]="item.addedBy + ' ' + '---' + ' ' + formatDate(item.addedAt)">
          <nz-list-item-meta-title>
            <a href="https://ng.ant.design">{{ item.comment }}</a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <nz-list-item-meta *ngIf="i !== expertOnboardRequest.comments.length" #targetDiv
          nzAvatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          [nzDescription]="item.addedBy + ' ' + '---' + ' ' + formatDate(item.addedAt)">
          <nz-list-item-meta-title>
            <a href="https://ng.ant.design">{{ item.comment }}</a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
      </nz-list-item>

      @if (expertOnboardRequest && expertOnboardRequest.comments && expertOnboardRequest.comments.length === 0) {
      <nz-list-empty />
      }
    </nz-list>
  </div>
  <nz-modal [(nzVisible)]="isVisible" nzTitle="Add comment" (nzOnCancel)="handleCancel()"
    [nzOkLoading]="isLoadingComment" (nzOnOk)="addComment()">
    <ng-container *nzModalContent>
      <textarea nz-input placeholder="Enter Your thoughts" [(ngModel)]="comment" type="text"></textarea>
    </ng-container>
  </nz-modal>
</nz-spin>