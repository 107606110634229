<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/content']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 *ngIf="!viewMode" class="headeradminui-text pl-4">Update Content</h1>
    <h1 *ngIf="viewMode" class="headeradminui-text pl-4">View Content</h1>
  </div>
</div>
<nz-spin [nzSpinning]="contentSpinning" nzTip="Loading...">
  <div class="custom-card mt-3">
    <div class="card-body">
      <div nz-row nzJustify="end">
        <div nz-col>
          <button nz-button nzType="primary" (click)="editMode()">
            Edit Content
          </button>
        </div>
      </div>
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <nz-select
            (ngModelChange)="categoryChangeFn($event)"
            [(ngModel)]="selectedCategoryString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Category"
            nzShowSearch
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let category of categories"
              [nzLabel]="category.label"
              [nzValue]="category.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <nz-select
            [(ngModel)]="selectedGenreString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Genre"
            nzShowSearch
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let genre of genres"
              [nzLabel]="genre.label"
              [nzValue]="genre.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="emotionPurpose"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Emotion Purpose"
          />
        </div>
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="energyPurpose"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Energy Purpose"
          />
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="userProblems"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="User Problems"
          />
        </div>
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="feedback"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Feedback"
          />
        </div>
        <div nz-col nzSpan="6">
          <input
            [(ngModel)]="practiceName"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Practice Name"
            [disabled]="viewMode"
          />
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <nz-select
            [(ngModel)]="selectedUserInterests"
            nzPlaceHolder="Select User Interests"
            nzMode="multiple"
            nzSize="large"
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let item of userInterestTopics"
              [nzLabel]="item.value"
              [nzValue]="item.key"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div nz-col nzSpan="12">
          <textarea
            [(ngModel)]="contentDescription"
            nz-input
            rows="4"
            [disabled]="viewMode"
          >
Description</textarea
          >
        </div>
        <div nz-col nzSpan="12">
          <textarea [(ngModel)]="templates" [disabled]="true" nz-input rows="4">
Templates</textarea
          >
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div class="mt-5" nz-col nzSpan="6">
          <img class="pt-2 pb-2" [src]="contentThumbnailFileImage" />
          <span><b>Upload Thumbnail</b></span>
          <br />
          <input
            class="pt-2 pb-2"
            (change)="uploadContentThumbNail($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
            [disabled]="viewMode"
          />
        </div>
        <div class="mt-5 pl-4" nz-col nzSpan="6">
          <img class="pt-2 pb-2" [src]="contentBannerFileImage" />
          <span><b>Upload Content Banner</b></span>
          <br />
          <input
            class="pt-2 pb-2"
            (change)="uploadContentBanner($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
            [disabled]="viewMode"
          />
        </div>
        <div class="mt-5" nz-col nzSpan="6">
          <nz-select
            [nzDisabled]="viewMode"
            class="w-[100%] mr-1"
            [nzMaxTagCount]="4"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzMode="multiple"
            nzPlaceHolder="Please select instructors"
            [(ngModel)]="selectedInstructors"
          >
            <nz-option
              *ngFor="let item of instructors"
              [nzLabel]="item.label"
              [nzValue]="item.value"
            ></nz-option>
          </nz-select>
          <ng-template #tagPlaceHolder let-selectedList
            >and {{ selectedList.length }} more selected</ng-template
          >
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <label>Visible :</label>
          <nz-switch [(ngModel)]="isVisible"></nz-switch>
        </div>
      </div>

      <nz-divider nzText="Modules"></nz-divider>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            nz-button
            nzType="primary"
            [disabled]="viewMode"
            (click)="addModule()"
          >
            Add Modules
          </button>
        </div>
      </div>
      <div class="module-container">
        <div *ngFor="let module of modules; index as i" class="mt-3" nz-row>
          <nz-card
            [nzExtra]="deleteModuleTemplate"
            [nzTitle]="'Module ' + (i + 1)"
            nz-card
            style="width: 100%"
          >
            <div nz-row nzGutter="18">
              <div nz-col nzSpan="12">
                <div nz-row nzGutter="18">
                  <div nz-col nzSpan="12">
                    <img
                      class="pt-2 pb-2"
                      [src]="module.thumbnail"
                      *ngIf="module.thumbnail"
                    />
                    <span><b>Upload Module Thumbnail</b></span>
                    <br />
                    <input
                      (change)="uploadModuleThumbnail($event, i)"
                      accept=".jpg,.jpeg,.png"
                      type="file"
                      [disabled]="viewMode"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <img
                      class="pt-2 pb-2"
                      [src]="module.banner"
                      *ngIf="module.banner"
                    />
                    <span><b>Upload Module Banner</b></span>
                    <br />
                    <input
                      (change)="uploadModuleBanner($event, i)"
                      accept=".jpg,.jpeg,.png"
                      type="file"
                      [disabled]="viewMode"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <video class="pt-2 pb-2" controls *ngIf="module.url">
                      <source [src]="module.url" />
                    </video>
                    <span><b>Upload Module Media</b></span>
                    <br />
                    <input
                      (change)="uploadModuleMedia($event, i)"
                      accept=".mp3,.mp4"
                      type="file"
                      [disabled]="viewMode"
                    />
                  </div>
                </div>
                <div class="mt-5" nz-row nzGutter="18">
                  <div nz-col nzSpan="24">
                    <textarea
                      [(ngModel)]="module.moduleDescription"
                      class="w-[100%]"
                      nz-input
                      placeholder="Module Description"
                      rows="4"
                      [disabled]="viewMode"
                    >
Module Description</textarea
                    >
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="12">
                <div nz-row nzGutter="18">
                  <div nz-col nzSpan="12">
                    <input
                      [(ngModel)]="module.moduleId"
                      nz-input
                      nzSize="large"
                      placeholder="Module Id"
                      [disabled]="viewMode"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <input
                      [(ngModel)]="module.moduleName"
                      nz-input
                      nzSize="large"
                      placeholder="Module Name"
                      [disabled]="viewMode"
                    />
                  </div>
                </div>
                <div class="mt-10" nz-row nzGutter="18">
                  <div nz-col nzSpan="12">
                    <input
                      [(ngModel)]="module.estimatedTime"
                      nz-input
                      nzSize="large"
                      placeholder="Estimated Time (in Seconds)"
                      [disabled]="viewMode"
                      (input)="validateNumber($event)"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <label
                      [(ngModel)]="module.isMandatory"
                      nz-checkbox
                      [nzDisabled]="viewMode"
                      >Make as mandatory
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </nz-card>
          <ng-template #deleteModuleTemplate>
            <span
              (click)="deleteModule(i)"
              nz-icon
              nzTheme="outline"
              nzType="delete"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col class="pr-4">
          <button
            nz-button
            nzType="default"
            *ngIf="!viewMode"
            [routerLink]="['/content']"
          >
            Cancel
          </button>
        </div>
        <div nz-col>
          <button
            nz-button
            nzType="primary"
            *ngIf="!viewMode"
            (click)="updateContent()"
          >
            Update Content
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
