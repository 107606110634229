import { GridRequest } from "projects/app-core/src/models/gridRequest";

export enum ExpertsOnboardingFilterRequest {
  NAME = "NAME",
  ROLES = "ROLES",
  USER_INTEREST = "USER_INTEREST",
}

export enum ExpertsOnboardingSortRequest {
  CREATED_AT = "CREATED_AT",
}

export enum ExpertsOnboardingDateFilterRequest {
  CREATED_AT = "CREATED_AT",
}

export enum ExpertsOnboardingSearchRequest {
  NAME = "NAME",
  EMAIL = "EMAIL",
}

export enum ExpertOnboardingTab {
  UNDER_REVIEW = "UNDER_REVIEW",
  REQUIRE_CHANGES = "REQUIRE_CHANGES",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  ALL = "ALL"
}

export interface ExpertsOnboardingGridRequest
  extends GridRequest<
    ExpertsOnboardingSortRequest,
    ExpertsOnboardingFilterRequest,
    ExpertsOnboardingSearchRequest,
    ExpertsOnboardingDateFilterRequest
  > {
    tab: ExpertOnboardingTab
  }

