export const environment = {
  production: true,
  firebase: {
    databaseURL:
      "https://anyo-2022-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "anyo-2022",
    appId: "1:496659584103:web:cc8f9293d7de79ef19f0c2",
    storageBucket: "anyo-2022.appspot.com",
    apiKey: "AIzaSyApoVuNGIhdUfkm9RLhGil28_IDO49t25Q",
    authDomain: "anyo-2022.firebaseapp.com",
    messagingSenderId: "496659584103",
    measurementId: "G-HZB0ZSX880",
  },
  contentServiceUrl: "https://alpha-files-api.anyo.app",
  // apiBackendBaseUrl: 'http://localhost:8080',
  apiBackendBaseUrl: "https://apiv2.anyo.app",
  serviceUrl: "https://apiv1.portal.anyo.app/",
  serviceUrl2: "https://apiv1.auth.anyo.app/",
  domain: "anyo.app",
  app: "listener-portal",
  posthog: {
    apiKey: 'phc_5LpKnTykKxdURCGYLOk4YSM7deNk8LkF9UfdVGvmFS4',
    apiHost: 'https://ana.anyo.app',
  }
};
