import { Component } from '@angular/core';
import { PartnerService } from '../../../services/partner.service';
import { MemberService } from '../../../services/member.service';

import { PartnerTable } from '../../../models/partnerTable';
import { ToastService } from '../../../../../../app-core/src/app/services/toastr.service';
import { AuthService } from '../../../../../../app-core/src/auth/auth.service';
import {
  PartnerFilter,
  PartnerGridRequest,
  PartnerSearch,
} from '../../../models/partnerGridRequest';

import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { SessionGridStatsData } from 'projects/app-core/src/app/session/models/sessionStatsData';
import { AppointmentStatus } from 'projects/app-core/src/app/session/models/TherapistAppointment';
import { SessionsService } from 'projects/app-core/src/app/session/service/sessions.service';
import {
  SessionGridRequest,
  SessionGridTab,
} from 'projects/app-core/src/app/session/models/sessionGridRequest';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import { StatsCard } from '../../therapyPlans/stats-cards/stats-cards.component';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import { UniqueTherapyPlanSubscriptionForUsersReq } from '../../../models/uniqueTherapyPlanSubscription';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}
@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  totalCount: number = 0;
  activeCount: number = 0;
  passes: number = 0;
  fields: any[] = [
    'Partner ID',
    'Partner',
    'City',
    'State',
    'No of Passes',
    'No of Therapy Sessions',
    'Plan Status',
  ];
  actionField: Object = {
    label: 'Actions',
    colspan: '2',
  };
  tableData: PartnerTable[] = [];
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  partnerTableCurrentPageNo = 1;
  partnerTableTotalCount = 0;
  partnerTableQuery: PartnerGridRequest | undefined;
  partnerTableSpinner: boolean = false;
  citiesCheckBoxOptions: CheckBoxOptions[] = [];
  statesCheckBoxOptions: CheckBoxOptions[] = [];
  globalSearchInput = '';
  partnerNameColumnSearch = '';
  partnerIdColumnSearch = '';
  

  constructor(
    private auth: AuthService,
    private toasterService: ToastService,
    private partnerService: PartnerService,
    private sessionService: SessionsService,
    private therapyService: TherapyPlansService
  ) {}

  getCount() {
    this.partnerService.partnerCount().subscribe({
      next: (value) => {
        value.forEach((v: { _id: string; count: number }) => {
          this.totalCount += v.count;
          if (v._id === 'active') {
            this.activeCount += v.count;
          }
        });
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
  resetFilter() {
    this.partnerTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: {
        [PartnerFilter.STATE]: [],
        [PartnerFilter.CITY]: [],
      },
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.partnerTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.listPartners();
  }

  nzPageIndexChange(page: number) {
    if (this.partnerTableQuery) {
      this.partnerTableQuery!.page = page;
      this.listPartners();
    }
  }
  partIdColumnSearchFn() {
    this.partnerIdColumnSearch = this.partnerIdColumnSearch.trim();
    if (this.partnerIdColumnSearch.length) {
      if (!this.partnerTableQuery!.searchQuery) {
        this.partnerTableQuery!.searchQuery = {
          [PartnerSearch.PARTNER_ID]: '',
          [PartnerSearch.PARTNER]: '',
        };
      }
      this.partnerTableQuery!.searchQuery!.PARTNER_ID =
        this.partnerIdColumnSearch;
      this.listPartners();
    }
  }
  partNameSearchFn() {
    this.partnerNameColumnSearch = this.partnerNameColumnSearch.trim();
    if (this.partnerNameColumnSearch.length) {
      if (!this.partnerTableQuery!.searchQuery) {
        this.partnerTableQuery!.searchQuery = {
          [PartnerSearch.PARTNER_ID]: '',
          [PartnerSearch.PARTNER]: '',
        };
      }
      this.partnerTableQuery!.searchQuery!.PARTNER =
        this.partnerNameColumnSearch;
      this.listPartners();
    }
  }
  stateFilter(states: any[]) {
    if (states && states.length) {
      if (!this.partnerTableQuery!.filters) {
        this.partnerTableQuery!.filters = {
          [PartnerFilter.STATE]: [],
          [PartnerFilter.CITY]: [],
        };
      }
      this.partnerTableQuery!.filters!.STATE = states;
    }
  }
  cityFilter(cities: any[]) {
    if (cities && cities.length) {
      if (!this.partnerTableQuery!.filters) {
        this.partnerTableQuery!.filters = {
          [PartnerFilter.STATE]: [],
          [PartnerFilter.CITY]: [],
        };
      }
      this.partnerTableQuery!.filters!.CITY = cities;
    }
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.partnerTableQuery?.globalSearch?.push(this.globalSearchInput.trim());
      this.listPartners();
    }
  }

  listPartners() {
    this.partnerTableSpinner = true;
    this.partnerService.partnerList(this.partnerTableQuery!).subscribe({
      next: (value) => {
        this.citiesCheckBoxOptions = value.filterOptions.cities.map((city) => {
          return {
            label: city,
            value: city,
            checked: false,
          };
        });
        this.statesCheckBoxOptions = value.filterOptions.state.map((state) => {
          return {
            label: state,
            value: state,
            checked: false,
          };
        });
        value.data.forEach((document) => {
          document.planStatus.expired = !moment().isBetween(
            moment(document.planStatus.startDate, 'X'),
            moment(document.planStatus.endDate, 'X'),
          );
          document.planStatus.startDateString = moment(
            document.planStatus.startDate,
            'X',
          ).format('DD/MM/YYYY');
          document.planStatus.endDateString = moment(
            document.planStatus.endDate,
            'X',
          ).format('DD/MM/YYYY');
        });
        this.partnerTableTotalCount = value.totalRecords;
        this.tableData = value.data;
        this.partnerTableSpinner = false;
      },
      error: (error) => {
        this.partnerTableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit(): void {
    this.partnerTableSpinner = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.partnerTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        this.getCount();
        this.listPartners();
      }
    });
  }

  nzPageSizeChange(pageSize: number) {
    this.partnerTableQuery!.pageSize = pageSize;
    this.listPartners();
  }

  visible = false;
  partnerName: string = '';
  sessionStatsData!: SessionGridStatsData;
  sessionTableQuery: SessionGridRequest | undefined;
  therapyCards1: StatsCard[] = [
    {
      title: 'Total Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },
    {
      title: 'Total Confirmed Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },{
      title: 'Total Canceled Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },{
      title: 'Total Completed Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },
  ]

  therapyCards2: StatsCard[] = [
    {
      title: 'No Show User Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },
    {
      title: 'No Show Therapist Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },{
      title: 'Tech Issue Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },{
      title: 'Feedback Pending Sessions',
      count: 0,
      userLogo: false,
      upLogo: false
    },
  ]

  open(data: PartnerTable) {
    this.partnerName = data.partner;
    this.visible = true;
    this.getSessionsStats(data);
  }

  close(): void {
    this.visible = false;
  }

  planGridRequest!: UniqueTherapyPlanSubscriptionForUsersReq
  planLoading = false
  cards: StatsCard[] = [
    {
      title: 'Total Users',
      count: 0,
      userLogo: true,
      upLogo: false,
    },
    {
      title: 'Active Users',
      count: 0,
      userLogo: true,
      upLogo: false,
    },
    {
      title: 'Total Plans',
      count: 0,
      userLogo: false,
      upLogo: true,
    },
    {
      title: 'Active Plans',
      count: 0,
      userLogo: false,
      upLogo: true,
    },
    {
      title: 'Retail Value',
      count: 0,
      userLogo: false,
      upLogo: false,
    },
    {
      title: 'Corporate Value',
      count: 0,
      userLogo: false,
      upLogo: false,
    }
  ]
  

  getSessionsStats(data: PartnerTable) {
    this.sessionTableQuery = {
      sort: {
        SESSION_DATE: AnyoSortDirection.ASC,
        THERAPIST_RATING : AnyoSortDirection.DESC
      },
      dateFilters: undefined,
      export: false,
      filters: {
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        USER_ID: [],
        PARTNER_ID: [data.partnerId],
        HIDE_TEST_BOOKING: ['true'],
      },
      from: undefined,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      searchQuery: undefined,
      tab: SessionGridTab.ALL,
      to: undefined,
    };
    this.sessionService.sessionGridStats(this.sessionTableQuery!).subscribe({
      next: (value) => {
        const response = this.sessionService.mapSessionGridStatsResp(value);
        this.sessionStatsData = response;
        this.therapyCards1 = [
          {
            title: 'Total Sessions',
            count: this.sessionStatsData.totalSessions,
            userLogo: false,
            upLogo: false
          },
          {
            title: 'Total Confirmed Sessions',
            count: this.sessionStatsData.confirmedSessions,
            userLogo: false,
            upLogo: false
          },{
            title: 'Total Canceled Sessions',
            count: this.sessionStatsData.cancelledSessions,
            userLogo: false,
            upLogo: false
          },{
            title: 'Total Completed Sessions',
            count: this.sessionStatsData.completedSessions,
            userLogo: false,
            upLogo: false
          },
        ]
      
        this.therapyCards2 = [
          {
            title: 'No Show User Sessions',
            count: this.sessionStatsData.noShowTherapistSessions,
            userLogo: false,
            upLogo: false
          },
          {
            title: 'No Show Therapist Sessions',
            count: this.sessionStatsData.noShowTherapistSessions,
            userLogo: false,
            upLogo: false
          },{
            title: 'Tech Issue Sessions',
            count: this.sessionStatsData.techIssueSessions,
            userLogo: false,
            upLogo: false
          },{
            title: 'Feedback Pending Sessions',
            count: this.sessionStatsData.feedbackPendingSessions,
            userLogo: false,
            upLogo: false
          },
        ]
      },
      error: (value) => {
        const errorBody = value.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
    });
     this.planGridRequest = {
      page: 1,
      pageSize: 10,
      export: false,
    }
    if(!this.planGridRequest.filters){
      this.planGridRequest.filters = {
        TEST_USER: undefined,
        PARTNER_ID: undefined
      }
    }
    this.planGridRequest.filters.PARTNER_ID = [data.partnerId]
    this.planLoading = true
    this.therapyService.uniqueUserTherapySubslist(this.planGridRequest).subscribe({
      next: (value) => {
        let totalUsers = 0
        let totalActiveUsers = 0
        let totalActivePlans = 0
        let totalPlans = 0
        let retailValue = 0
        let corporateValue = 0
        value.statsData.forEach((data) => {
          if(data.retailUser){
            retailValue += data.totalPlanValue
          }
          if(data.corporateUser){
            corporateValue += data.totalPlanValue
          }
          if(data.activePlans > 0){
            totalActiveUsers += 1
          }
            totalPlans += data.totalPlans
            totalActivePlans += data.activePlans
        })
        totalUsers = value.statsData.length
        this.cards = [
          {
            title: 'Total Users',
            count: totalUsers,
            userLogo: true,
            upLogo: false,
          },
          {
            title: 'Active Users',
            count: totalActiveUsers,
            userLogo: true,
            upLogo: false,
          },
          {
            title: 'Total Plans',
            count: totalPlans,
            userLogo: false,
            upLogo: true,
          },
          {
            title: 'Active Plans',
            count: totalActivePlans,
            userLogo: false,
            upLogo: true,
          },
          {
            title: 'Retail Value',
            count: `₹${retailValue.toLocaleString()}`,
            userLogo: false,
            upLogo: false,
          },
          {
            title: 'Corporate Value',
            count: `₹${corporateValue.toLocaleString()}`,
            userLogo: false,
            upLogo: false,
          }
        ]
        this.planLoading = false
      }, error: (err) => {
        this.planLoading = false
        const errorBody = err.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      }
    })
  }
}
