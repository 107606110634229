<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex justify-end items-end w-full">
	<div nz-row
		nzjusty
		nzJustify="end"
		nzAlign="middle">
		<button (click)="openDrawer()"
			nz-button
			nzType="primary">
			Create Survey
		</button>
	</div>
</div>
<ng-template #tableHead>
	<div nz-row
		nzJustify="space-between">
		<div nz-col>
			<span class="font-bold">Anyo Surveys</span>
		</div>
		<div class="flex gap-2">
			<div nz-col>
				<button (click)="resetFilter()"
					class="btn-color"
					nz-button
					nzSize="small"
					nzType="primary">
					Clear filter
				</button>
			</div>
		</div>
	</div>
</ng-template>
<nz-spin [nzSpinning]="pageLoading"
	nzTip="Fetching surveys......">
	<nz-table #therapistTable
		[nzTitle]="tableHead"
		[nzScroll]="{ y: '500px' }"
		[nzData]="surveyGridRespData"
		[nzShowPagination]="true"
		[nzTotal]="totalSurveys"
		[nzPageSize]="pageSize"
		[nzBordered]="true"
		[nzShowSizeChanger]="true"
		[nzPageSizeOptions]="pageSizeOptions"
		[nzPageIndex]="page"
		(nzPageIndexChange)="nzPageIndexChange($event)"
		(nzPageSizeChange)="nzPageSizeChange($event)"
		class="mt-5"
		nzFrontPagination="false"
		nzTableLayout="fixed">
		<thead>
			<tr>
				<th>
					Internal Survey Name
				</th>
				<th>Login Required</th>
				<th>
					Collect User Details
				</th>
				<th>Valid From</th>
				<th>
					User Survey Response
				</th>
				<th>Active</th>
				<th nzWidth="25%">Actions</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let data of therapistTable.data">
				<td [ngClass]="{ 'bg-gray-200': !data.active }">
					{{ data.surveyName }}
				</td>
				<td [ngClass]="{ 'bg-gray-200': !data.active }">
					{{ data.loginRequired ? "Yes" : "No" }}
				</td>
				<td [ngClass]="{ 'bg-gray-200': !data.active }">
					{{ data.collectUserDetails ? "Yes" : "No" }}
				</td>
				<td [ngClass]="{ 'bg-gray-200': !data.active}">
					{{ utils.convertEpochToFormattedDate(data.validFrom) }}
				</td>
				<td [ngClass]="{ 'bg-gray-200': !data.active}">
					{{ data.saveUserSurveyResponse ? "Yes" : "No" }}
				</td>
				<td [ngClass]="{ 'bg-gray-200': !data.active }">
					{{ data.visible ? "Yes" : "N/a" }}
				</td>
				<td [ngClass]="{ 'bg-gray-200': !data.active }"
					nzRight>
					<div class="flex items-center justify-evenly">
						<span nz-tooltip
							nzTooltipTitle="View Survey">
							<button (click)="editDrawer(data.id!)"
								nz-button
								nzType="primary"
								nzShape="circle">
								<span nz-icon
									nzType="eye"
									nzTheme="outline"></span>
							</button>
						</span>
					</div>
				</td>
			</tr>
		</tbody>
	</nz-table>
</nz-spin>









<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }"
	[nzMaskClosable]="false"
	[nzWidth]="1100"
	[nzVisible]="isDrawerOpen"
	(nzOnClose)="closeDrawer()">
	<ng-container *nzDrawerContent>
		<div nz-row
			nzJustify="space-between">
			<div nz-col>
				<span *ngIf="!isEditSurvey"
					class="font-bold text-2xl">
					Add Survey
				</span>
				<span *ngIf="isEditSurvey"
					class="font-bold text-2xl">
					Edit Survey
				</span>
			</div>
		</div>
		<nz-divider></nz-divider>
		<div class="mt-5">
			<label class="form-label required text-sm">
				Internal Survey Name
			</label>
			<input [(ngModel)]="surveyName"
				type="text"
				placeholder="Enter Survey Name"
				class="w-[50%] p-2 border border-gray-300 rounded">
		</div>
		<div nz-row
			nzJustify="start"
			class="w-full mt-5">
			<div nz-col
				class="w-full">
				<div class="flex flex-row space-x-2 mb-2">
					<label class="form-label required text-sm">Survey JSON</label>
					(
					<a href="https://surveyjs.io/create-free-survey"
						target="_blank"
						class="text-blue-500 underline">
						https://surveyjs.io/create-free-survey
					</a>
					)
				</div>
				<textarea [(ngModel)]="surveyJSON"
					class="w-full  p-2 border border-gray-300 rounded"
					rows="10"
					placeholder="Enter your survey JSON here..."></textarea>
			</div>
		</div>
		<div nz-row
			nzJustify="start"
			class="w-full pt-5">
			<div class="grid grid-cols-3 gap-3 w-[70%]">
				<div>
					<label class="form-label text-sm pb-2">Login Required</label>
					<nz-switch [(ngModel)]="loginRequired"></nz-switch>
				</div>
				<div>
					<label class="form-label text-sm pb-2">Active</label>
					<nz-switch [(ngModel)]="active"></nz-switch>
				</div>
				<div>
					<label class="form-label text-sm pb-2">Visible</label>
					<nz-switch [(ngModel)]="visible"></nz-switch>
				</div>
			</div>
		</div>
		<div *ngIf="loginRequired"
			nz-row
			class="pt-5">
			<div nz-col>
				<nz-switch [(ngModel)]="corporateOnlySurvey"
					nzCheckedChildren="Corporate Only"
					nzUnCheckedChildren="All Customers"></nz-switch>
			</div>
		</div>
		<div *ngIf="corporateOnlySurvey && loginRequired"
			nz-row
			class="mt-5 w-full">
			<div nz-col
				nzSpan="12"
				class="w-full">
				<nz-select [(ngModel)]="selectedPartners"
					nzShowSearch="true"
					nzMode="multiple"
					nzPlaceHolder="Select Partners"
					class="w-full">
					<nz-option *ngFor="let partner of partners"
						[nzLabel]="partner.partner"
						[nzValue]="partner.id"></nz-option>
				</nz-select>
			</div>
		</div>
		<div nz-row
			nzJustify="start"
			class="w-full pt-5">
			<div class="grid grid-cols-3 gap-3 w-[70%]">
				<div>
					<label class="form-label text-sm pb-2">
						Collect User Details
					</label>
					<nz-switch [(ngModel)]="collectUserDetails"></nz-switch>
				</div>
				<div>
					<label class="form-label text-sm pb-2">
						Save User Survey Response
					</label>
					<nz-switch [(ngModel)]="saveUserSurveyResponse"></nz-switch>
				</div>
			</div>
		</div>
		<div *ngIf="collectUserDetails"
			class="mt-5 w-[50%]">
			<div class="flex items-center gap-2">
				<input [(ngModel)]="userDetailInput"
					type="text"
					placeholder="Enter detail"
					class="w-[70%] p-2 border border-gray-300 rounded">
				<button (click)="addUserDetail()"
					class="bg-blue-500 text-white px-4 py-2 rounded">
					Add
				</button>
			</div>











			<div *ngIf="userDetailsArray.length > 0"
				class="mt-3 w-[70%]">
				<div class="flex flex-wrap gap-2 border border-blue-500 rounded-lg p-3">
					<span *ngFor="let detail of userDetailsArray; let i = index"
						class="border-blue-500 border rounded-lg  px-3 py-1  flex items-center gap-2">
						{{ detail }}
						<button (click)="removeUserDetail(i)"
							nzType="link"
							class="text-red-600 flex justify-center items-center"
							aria-label="Remove item">
							<span nz-icon
								nzType="close-circle"
								nzTheme="outline"></span>
						</button>
					</span>
				</div>
			</div>
		</div>
		<div class="mt-5 flex gap-4">
			<div class="flex-1">
				<label for="validFrom"
					class="block text-sm">
					Valid From
				</label>
				<nz-date-picker [nzFormat]="dateFormat"
					[(ngModel)]="validFrom"
					id="validFrom"
					nzShowTime
					placeholder="Select valid from date"
					class="w-full"></nz-date-picker>
			</div>
			<div class="flex-1">
				<label for="validTill"
					class="block text-sm">
					Valid Till
				</label>
				<nz-date-picker [nzFormat]="dateFormat"
					[(ngModel)]="validTill"
					id="validTill"
					nzShowTime
					placeholder="Select valid till date"
					class="w-full"></nz-date-picker>
			</div>
		</div>








		<div class="mt-5">
			<label class="form-label text-sm">End Text</label>
			<input [(ngModel)]="endText"
				type="text"
				placeholder="Enter end text"
				class="w-[30%] p-2 border border-gray-300 rounded">
		</div>
		<div class="mt-5">
			<h2 class="font-bold text-lg mb-3">Points System</h2>
			<div class="space-y-5">
				<div *ngFor="let range of pointsRanges; let i = index"
					class="border p-3 rounded-lg shadow-sm">
					<div class="flex items-center gap-3">
						<label class="text-sm font-medium">Range:</label>
						<input [value]="range.min"
							type="number"
							class="p-2 border border-gray-300 rounded w-20"
							disabled>
						<span class="font-medium">-</span>
						<input [ngModel]="range.max"
							[attr.min]="range.min + 1"
							(ngModelChange)="updateMaxValue(i, $event)"
							type="number"
							class="p-2 border border-gray-300 rounded w-20"
							placeholder="Enter max">
					</div>
					<div class="mt-3">
						<div class="flex flex-row space-x-2 mb-2">
							<label class="form-label required text-sm">HTML Content</label>
						</div>
						<textarea [(ngModel)]="range.htmlText"
							class="w-full p-2 border border-gray-300 rounded"
							rows="3"
							placeholder="Enter HTML content for this range"></textarea>
						<div *ngIf="range.htmlText.trim() === ''"
							class="text-red-500 text-sm mt-1">
							HTML content is required.
						</div>
					</div>
				</div>











				<button [disabled]="!canAddNewRange()"
					(click)="addNewRange()"
					nz-button
					nzType="primary"
					class="mt-3">
					Add Range
				</button>
			</div>
		</div>
		<div class="mt-5 w-full flex justify-end items-end">
			<button (click)="saveOrUpdateSurvey()"
				nz-button
				nzType="primary"
				class="mt-3">
				{{ isEditSurvey ? "Update Survey" : "Save Survey"}}
			</button>
		</div>
	</ng-container>
</nz-drawer>
