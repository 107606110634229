import { Component } from '@angular/core';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { PartnerTable } from '../../../models/partnerTable';
import { PartnerService } from '../../../services/partner.service';
import { AnyoSurvey } from '../../../models/survey/AnyoSurvey';
import { SurveyService } from '../../../services/survey.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { SurveysGridRequest } from '../../../models/survey/surveysGridRequest';
import { SurveysGridResponseData } from '../../../models/survey/surveysGridResponse';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

@Component({
  selector: 'app-manage-survey',
  templateUrl: './manage-survey.component.html',
  styleUrl: './manage-survey.component.scss',
})
export class ManageSurveyComponent extends AnyoComponent {
  constructor(
    public utils: UtilsService,
    auth: AuthService,
    private partnerService: PartnerService,
    private surveyService: SurveyService,
    private toasterService: ToastService,
  ) {
    super(auth);
  }
  dateFormat = 'dd-MM-yyyy';
  isDrawerOpen: boolean = false;
  isEditSurvey: boolean = false;
  surveyJSON: string = '';
  surveyName: string = '';
  loginRequired: boolean = true;
  active: boolean = true;
  visible: boolean = true;
  partners: PartnerTable[] = [];
  corporateOnlySurvey: boolean = false;
  selectedPartners: string[] = [];
  collectUserDetails: boolean = false;
  saveUserSurveyResponse: boolean = true;
  userDetailInput: string = '';
  userDetailsArray: string[] = [];
  pointsRanges: { min: number; max: number | null; htmlText: string }[] = [
    { min: 0, max: null, htmlText: '' },
  ];
  endText: string = '';
  validFrom: Date | null = null;
  validTill: Date | null = null;

  isDisabled = false;
  openDrawer() {
    this.isDrawerOpen = true;
    this.surveyJSON = '';
    this.loginRequired = true;
    this.active = true;
    this.visible = true;
    this.partners = [];
    this.corporateOnlySurvey = false;
    this.selectedPartners = [];
    this.collectUserDetails = false;
    this.saveUserSurveyResponse = true;
    this.userDetailInput = '';
    this.userDetailsArray = [];
    this.pointsRanges = [{ min: 0, max: null, htmlText: '' }];
    this.endText = '';
    this.validFrom = null;
    this.validTill = null;
    this.surveyName = '';
  }

  surveyId!: string;
  editDrawer(id: string) {
    this.surveyId = id;
    this.isDisabled = true;
    this.isEditSurvey = true;
    this.pageLoading = false;
    this.surveyService.getSurveyById(id).subscribe({
      next: (value) => {
        this.surveyName = value.surveyName || '';
        this.surveyJSON = value.surveyJSON;
        this.loginRequired = value.loginRequired;
        this.active = value.active;
        this.visible = value.visible;
        this.corporateOnlySurvey = value.corporateOnlySurvey;
        this.collectUserDetails = value.collectUserDetails;
        this.saveUserSurveyResponse = value.saveUserSurveyResponse;
        this.userDetailsArray = value.userDetails || [];
        this.pointsRanges = value.pointsRanges;
        this.endText = value.endText || '';
        if (value.partnerIds && value.partnerIds.length) {
          this.selectedPartners = value.partnerIds.map((data) => data.id);
          this.corporateOnlySurvey = true;
        }
        if (value.validFrom) {
          this.validFrom = value.validFrom;
        }
        if (value.validFrom) {
          this.validFrom = value.validFrom;
        }
        this.pageLoading = false;
      },
      error: (err) => {
        this.pageLoading = false;
        const errorBody = err.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isDrawerOpen = true;
  }

  closeDrawer() {
    this.isDrawerOpen = false;
  }

  addUserDetail(): void {
    if (this.userDetailInput.trim()) {
      this.userDetailsArray.push(this.userDetailInput.trim());
      this.userDetailInput = '';
    }
  }
  removeUserDetail(index: number): void {
    this.userDetailsArray.splice(index, 1);
  }

  updateMaxValue(index: number, value: number): void {
    const max = parseInt(value.toString(), 10);
    if (max > this.pointsRanges[index].min) {
      this.pointsRanges[index].max = max;
      if (index + 1 < this.pointsRanges.length) {
        this.pointsRanges[index + 1].min = max;
      }
    }
  }

  addNewRange(): void {
    const lastRange = this.pointsRanges[this.pointsRanges.length - 1];
    if (lastRange.max !== null) {
      this.pointsRanges.push({
        min: lastRange.max,
        max: null,
        htmlText: '',
      });
    }
  }

  canAddNewRange(): boolean {
    const lastRange = this.pointsRanges[this.pointsRanges.length - 1];
    return lastRange.max !== null && lastRange.htmlText.trim() !== '';
  }

  saveOrUpdateSurvey(): void {
    if (!this.validateRanges()) {
      this.toasterService.showAnyoErrorToast('Please enter valid ranges.');
      return;
    }

    if (this.surveyJSON === '' || this.surveyName === '') {
      this.toasterService.showAnyoErrorToast(
        'Please fill out all mandatory fields.',
      );
      return;
    }

    const filterRange = this.pointsRanges.filter((data) => data.max !== null);
    const survey: AnyoSurvey = {
      surveyJSON: this.surveyJSON,
      loginRequired: this.loginRequired,
      active: this.active,
      visible: this.visible,
      corporateOnlySurvey: this.corporateOnlySurvey,
      collectUserDetails: this.collectUserDetails,
      saveUserSurveyResponse: this.saveUserSurveyResponse,
      userDetails: this.userDetailsArray,
      pointsRanges: filterRange,
      surveyName: this.surveyName,
    };

    if (this.isEditSurvey) {
      survey.surveyId = this.surveyId;
    }

    if (this.corporateOnlySurvey && this.selectedPartners.length > 0) {
      survey.partnerIds = this.selectedPartners.map((data) => {
        const partner = this.partners.find(
          (partner) => partner.id.toString() == data.toString(),
        );
        return {
          id: data,
          partnerCode: partner?.partnerId || '',
        };
      });
    }

    if (this.endText !== '') {
      survey.endText = this.endText;
    }
    if (this.validFrom !== null) {
      survey.validFrom = this.validFrom;
    }
    if (this.validTill !== null) {
      survey.validTill = this.validTill;
    }

    // Submit the survey
    this.surveyService.createSurvey(survey).subscribe({
      next: () => {
        this.fetchSurveys();
        this.isDrawerOpen = false;
        this.toasterService.showSuccess('Survey saved successfully!');
      },
      error: (error) => {
        this.pageLoading = false;
        const errorBody = error.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
      complete: () => {},
    });
  }

  validateRanges(): boolean {
    for (let i = 0; i < this.pointsRanges.length; i++) {
      const currentRange = this.pointsRanges[i];
      if (currentRange.min === null || currentRange.max === null) {
        this.toasterService.showAnyoErrorToast(
          `Range ${i + 1} is incomplete. Please provide both min and max values.`,
        );
        return false;
      }
      if (currentRange.max <= currentRange.min) {
        this.toasterService.showAnyoErrorToast(
          `Range ${i + 1} is invalid. Max must be greater than Min.`,
        );
        return false;
      }
      if (i > 0 && currentRange.min !== this.pointsRanges[i - 1].max) {
        this.toasterService.showAnyoErrorToast(
          `Range ${i + 1} is invalid. Min must match the previous range's Max.`,
        );
        return false;
      }
    }
    const lastRange = this.pointsRanges[this.pointsRanges.length - 1];
    if (lastRange.max !== 100) {
      this.toasterService.showAnyoErrorToast('The last range must end at 100.');
      return false;
    }

    return true;
  }

  surveyGridRequest!: SurveysGridRequest;
  surveyGridRespData: SurveysGridResponseData[] = [];
  totalSurveys = 0;
  page = 1;
  pageSize = 10;
  pageSizeOptions = [10, 20];

  nzPageIndexChange(page: number) {
    this.surveyGridRequest.page = page;
    this.fetchSurveys();
  }

  nzPageSizeChange(pageSize: number) {
    this.surveyGridRequest.pageSize = pageSize;
    this.fetchSurveys();
  }

  resetFilter() {
    this.surveyGridRequest = {
      page: 1,
      pageSize: this.pageSize,
      globalSearch: [],
      filters: undefined,
      export: false,
    };
    this.fetchSurveys();
  }

  fetchSurveys() {
    this.surveyService.fetchSurveysGrid(this.surveyGridRequest).subscribe({
      next: (value) => {
        this.surveyGridRespData = value.data;
        this.totalSurveys = value.totalRecords;
      },
      error: (err) => {
        this.pageLoading = false;
        const errorBody = err.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  async ready(): Promise<void> {
    if (this.user) {
      this.partners = await this.partnerService.getAllActivePartners();
      this.surveyGridRequest = {
        page: 1,
        pageSize: this.pageSize,
        globalSearch: [],
        filters: undefined,
        export: false,
      };
      this.pageLoading = true;
      this.fetchSurveys();
    }
  }
}
