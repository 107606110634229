<button nz-button (click)="open()" class="btn-color ml-2" nzType="primary">
    Set V2 Availability
  </button>
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="1284"
  [nzVisible]="visible"
  [nzTitle]="'Availability update for ' + therapistName"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>
    <nz-spin [nzSpinning]="setAvailableSpinner" nzTip="Loading......">


<div class="flex flex-col gap-2">
    <div
      class="flex gap-2"
      *ngFor="let day of availabilityV2; let i = index"
    >
    <div class="title">
        <nz-tag class="whitespace-nowrap text-base px-4 py-2" [nzColor]="day.color">{{toUpperCaseFirstLetter(day.day) + " :"}}</nz-tag>
    </div>
    <div class="flex gap-2" *ngFor="let slot of day.slots; let index = index" >
      
        <nz-ribbon
          nzSize="default"
          [nzText]="(index + 1).toString()"
          [nzColor]="day.color"
        >
        <div
        class="passenger-input"
        >
            <input
              nz-input
              [(ngModel)]="slot.slotStart"
              type="time"
            />
            <input
              class="mt-2"
              nz-input
              [(ngModel)]="slot.slotEnd"
              type="time"
            />
            <span
            *ngIf="index !== 0"
            nz-icon
            nzType="minus-circle-o"
            class="dynamic-delete-button"
            (click)="removeSlot(i,index)"
            (keydown)="removeSlot(i,index)"
            tabindex="0"
            role="button"
            aria-label="Remove time slot"
          ></span>
        </div>
        </nz-ribbon>
       
    </div>
    <div class="ml-8">
        <button
          nz-button
          nzType="dashed"
          class="add-button"
          (click)="addSlots(i)"
        >
          <span nz-icon nzType="plus"></span>
          Add slot {{day.day}}
        </button>
      </div>
    </div>
</div>
</nz-spin>
</ng-container>
</nz-drawer>
<ng-template #footerTpl>
    <div style="float: right">
     <button
        nz-button
        (click)="saveAvailabilityV2()"
        class="btn-color"
        nzType="primary"
      >
        Save Availability
      </button>
      <button
        nz-button
        style="margin-right: 8px; margin-left:8px;"
        class="btn-color"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
  </ng-template>