<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
	<div class="flex flex-wrap w-1/4 justify-start">
		<img [routerLink]="['/events/events-bidding']"
			class="left-arrow clickable"
			src="../../../../assets/icons/left-arrow.svg"
			alt="event">
		<h1 class="headeradminui-text pl-2">
			{{ isEdit ? "Edit " : "Add " }}Events Bidding
		</h1>
	</div>
</div>
<nz-spin [nzSpinning]="loading">
	<div class="flex flex-col space-y-4 p-5">
		<div nz-row
			nzGutter="16">
			<nz-select [(ngModel)]="selectedValue"
				(ngModelChange)="onClassEventChange($event)"
				nzPlaceHolder="Select an option"
				style="width: 200px;"
				z>
				<nz-option nzValue="Event"
					nzLabel="Event"></nz-option>
				<nz-option nzValue="Class"
					nzLabel="Class"></nz-option>
			</nz-select>
		</div>
		<div class="flex flex-row space-x-5">
			<div class="flex flex-col w-[30%] justify-start">
				<nz-form-label nzFor="eventDescription"
					nzRequired
					class="text-left self-start w-full">
					Select Vertical
				</nz-form-label>
				<nz-select [(ngModel)]="vertical"
					(ngModelChange)="onVerticalChange($event)"
					nzMode="multiple"
					nzPlaceHolder="Select Vertical"
					class="w-full">
					<nz-option *ngFor="let vertical of verticalsMasterData"
						[nzValue]="vertical.value"
						[nzLabel]="vertical.label">
					</nz-option>
				</nz-select>
			</div>
			<div class="flex flex-col w-[30%] justify-start">
				<nz-form-label nzFor="eventDescription"
					nzRequired
					class="text-left self-start w-full">
					Select Concern
				</nz-form-label>
				<nz-select [(ngModel)]="concern"
					(ngModelChange)="onConcernChange($event)"
					nzMode="multiple"
					nzPlaceHolder="Select Concern"
					class="w-full">
					<nz-option *ngFor="let concern of concernFilteredMasterData"
						[nzValue]="concern.value"
						[nzLabel]="concern.label">
					</nz-option>
				</nz-select>
			</div>
		</div>
		<div nz-row
			nzGutter="16">
			<div nz-col
				nzSpan="12">
				<nz-form-item>
					<nz-form-label nzFor="eventName"
						nzRequired
						nzSpan="24">
						Event Name
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="eventName"
							type="text"
							id="eventName"
							nz-input
							placeholder="Enter Event Name">
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="12"
				class="flex flex-col h-full">
				<nz-form-item class="h-full">
					<nz-form-label nzFor="eventDescription"
						nzRequired
						nzSpan="24">
						Description
					</nz-form-label>
					<nz-form-control nzSpan="24"
						class="h-full">
						<textarea [(ngModel)]="eventDescription"
							id="eventDescription"
							nz-input
							placeholder="Event Description"
							rows="6"
							class="h-full"></textarea>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<div nz-row
			nzGutter="16">
			<nz-switch [(ngModel)]="offlineEvent"
				nzCheckedChildren="Offline"
				nzUnCheckedChildren="Online"></nz-switch>
		</div>
		<div *ngIf="offlineEvent"
			nz-row
			nzGutter="16">
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="mapAddress"
						nzRequired
						nzSpan="24">
						Map Address(embedded link from google map)
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="mapAddress"
							type="text"
							id="mapAddress"
							nz-input
							placeholder="Map Address(embedded link from google map)">
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="eventName"
						nzRequired
						nzSpan="24">
						Offline map link
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="offlineMapLink"
							type="text"
							id="offlineMapLink"
							nz-input
							placeholder="Enter Offline map link">
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="offlineAddress"
						nzRequired
						nzSpan="24">
						Offline Address
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="offlineAddress"
							type="text"
							id="offlineAddress"
							nz-input
							placeholder="Enter offline address">
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<div nz-row
			nzGutter="16">
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="eventDateTime"
						nzRequired
						nzSpan="24">
						Event Date & Time
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<nz-date-picker [(ngModel)]="eventDate"
							[nzShowTime]="true"
							nzFormat="dd-MM-yyyy HH:mm"
							nzPlaceholder="Select Date & Time"
							class="w-full">
						</nz-date-picker>
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="eventDuration"
						nzRequired
						nzSpan="24">
						Duration
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="duration"
							type="number"
							id="eventDuration"
							nz-input
							placeholder="Enter Duration">
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="biddingEndDate"
						nzRequired
						nzSpan="24">
						Event Bidding End Date
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<nz-date-picker [(ngModel)]="biddingEndDate"
							nzFormat="dd-MM-yyyy"
							nzPlaceholder="Select Bidding End Date"
							class="w-full">
						</nz-date-picker>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<div nz-row
			nzGutter="16">
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="audienceType"
						nzRequired
						nzSpan="24">
						Audience Type
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<nz-select [(ngModel)]="audienceType"
							nzPlaceHolder="Select Audience Type"
							nzAllowClear
							nzMode="tags"
							class="w-full">
							<nz-option *ngFor="let option of audienceTypeOption"
								[nzValue]="option"
								[nzLabel]="option">
							</nz-option>
						</nz-select>
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="initialAmount"
						nzSpan="24">
						Max bid Amount
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="maxExpertBidLimit"
							type="number"
							id="initialAmount"
							nz-input
							placeholder="Enter Amount">
					</nz-form-control>
				</nz-form-item>
			</div>
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="extraField"
						nzSpan="24">
						Is Travel Sponsorship
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<div class="flex items-center space-x-2">
							<input [(ngModel)]="isTravelSponsorship"
								(change)="toggleIsTravelSponsorship()"
								type="checkbox">
							<input [(ngModel)]="travelSponsorshipDescription"
								[disabled]="!isTravelSponsorship"
								type="text"
								id="extraField"
								nz-input
								placeholder="Enter Extra Value">
						</div>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<div nz-row
			nzGutter="16">
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label nzFor="totalHost"
						nzSpan="24">
						Total Host
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="totalHost"
							type="number"
							id="totalHost"
							nz-input
							placeholder="Enter Max Host Number">
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<div nz-row>
			<div nz-col>
				<label [(ngModel)]="isTesting"
					nz-checkbox>
					Test Event Bid
				</label>
			</div>
		</div>
		<nz-divider></nz-divider>
		<div nz-row>
			<div nz-col
				nzSpan="24">
				<nz-form-item nz-row>
					<nz-form-control [nzSpan]="24">
						<button (click)="addOrUpdateEventBidding()"
							nz-button
							nzType="primary">
							{{ isEdit ? "Update " : "Add " }}Events Bidding
						</button>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
	</div>
</nz-spin>
