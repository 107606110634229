import { Component } from '@angular/core';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ExpertOnboardingGridData, ExpertOnboardingGridResponse } from '../../../models/expertOnboardingGridResponse';
import { ExpertOnboardingTab, ExpertsOnboardingGridRequest, ExpertsOnboardingSortRequest } from '../../../models/expertOnboardingGridRequest';
import { ExpertsService } from '../../../services/experts.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent {
  gridRequest!: ExpertsOnboardingGridRequest;
  expertsOnboardingSpinner = false;
  gridData: ExpertOnboardingGridData[] = [];
  pageSize = 10;
  pageSizeOptions = [10,20];
  globalSearchInput= '';
  totalRecords = 0;
  currentPageNo = 1; 

  tableData: ExpertOnboardingGridResponse = {
    data: [],
    filterOptions: {
      categories: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };
  tabs = [ExpertOnboardingTab.UNDER_REVIEW, ExpertOnboardingTab.REQUIRE_CHANGES,ExpertOnboardingTab.APPROVED,ExpertOnboardingTab.REJECTED,ExpertOnboardingTab.PENDING];
  selectedTab: number = 0;

  constructor(
    private auth: AuthService,
    private toast: ToastService,
    private expert: ExpertsService,
    public utils: UtilsService
  ){}

  async tabChange($event: NzTabChangeEvent) {
    this.selectedTab = $event.index!;
    this.tableData = {
      data: [],
      filterOptions: {
        categories: [],
      },
      pageNum: 1,
      pageSize: 10,
      totalPages: 0,
      totalRecords: 0,
    };

    this.globalSearchInput = '';
    this.gridRequest = {
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
      sort: {
        [ExpertsOnboardingSortRequest.LAST_UPDATED_AT] : AnyoSortDirection.DESC,
        [ExpertsOnboardingSortRequest.CREATED_AT] : AnyoSortDirection.ASC
      }
    };
    // if (this.bytesDeliveredTableComponent) {
    //   this.bytesDeliveredTableComponent!.resetValues();
    // }
    // if (this.bytesScheduledTableComponent) {
    //   this.bytesScheduledTableComponent!.resetValues();
    // }
    if ($event.index == 0) {
      this.gridRequest!.tab = ExpertOnboardingTab.UNDER_REVIEW;
    }
    if ($event.index == 1) {
      this.gridRequest!.tab = ExpertOnboardingTab.REQUIRE_CHANGES;
    }
    if ($event.index == 2) {
      this.gridRequest!.tab = ExpertOnboardingTab.APPROVED;
    }
    if ($event.index == 3) {
      this.gridRequest!.tab = ExpertOnboardingTab.REJECTED;
    }
    if($event.index == 4) {
      this.gridRequest!.tab = ExpertOnboardingTab.PENDING;
    }
    this.fetchExpertOnboardRequests();
  }

  lastUpdateSortFn($event: string | null){
    if($event){
      if($event === "ascend"){
        this.gridRequest.sort!.LAST_UPDATED_AT = AnyoSortDirection.ASC;
      }else {
        this.gridRequest.sort!.LAST_UPDATED_AT = AnyoSortDirection.DESC;
      }
      this.fetchExpertOnboardRequests();
    }
  }

  searchGlobal(){
    this.gridRequest.globalSearch?.push(this.globalSearchInput.trim())
    this.fetchExpertOnboardRequests()
  }

  nzPageIndexChange(index: number){
    this.gridRequest.page = index;
    this.fetchExpertOnboardRequests();
  }

  nzPageSizeChange(index: number){
    this.gridRequest.pageSize = index;
    this.fetchExpertOnboardRequests();
  }

  resetFilter(){
    this.gridRequest = {
      page: 1,
      export: false,
      globalSearch: [],
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
      sort: {
        [ExpertsOnboardingSortRequest.LAST_UPDATED_AT] : AnyoSortDirection.DESC,
        [ExpertsOnboardingSortRequest.CREATED_AT] : AnyoSortDirection.ASC
      }
    }
    this.fetchExpertOnboardRequests()
  }

  async fetchExpertOnboardRequests(){
    this.expertsOnboardingSpinner = true
    this.expert.getExpertOnboardingRequestGrid(this.gridRequest).subscribe({
      next: (value) => {
        this.gridData = value.data;
        value.data.forEach((data) => {
          data.onboardingStatusColor = this.utils.getRandomColor();
          data.activitiesWithColor = data.activities.map((data) => {
            return {
              color: this.utils.getRandomColor(),
              text: data
            }
          })
        })
        this.totalRecords = value.totalRecords;
        this.tableData = value
        this.expertsOnboardingSpinner = false
      }, error: (err) => {
        const e = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(e.description)
        this.expertsOnboardingSpinner = false
      }
    })
  }

  ngOnInit(){
    this.auth.currentAuthStatus.subscribe((user) => {
      if(user){
        this.gridRequest = {
          page: 1,
          pageSize: 10,
          export: false,
          globalSearch: [],
          tab: ExpertOnboardingTab.UNDER_REVIEW,
          sort: {
            [ExpertsOnboardingSortRequest.LAST_UPDATED_AT] : AnyoSortDirection.DESC,
            [ExpertsOnboardingSortRequest.CREATED_AT] : AnyoSortDirection.ASC
          }
        }
        this.fetchExpertOnboardRequests();
      }
    })
  }
  
}
