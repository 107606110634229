import { BaseDB } from "projects/app-core/src/app/posts/model/BaseDB";

export interface BasicDetailsForm {
  firstName: string;
  lastName: string;
  title: string;
  mobileNumber: string;
  email: string;
  gender: string;
}

export interface ExpertOnboardRequests extends BaseDB {
  basicInfo?: BasicDetailsForm;
  profilePic?: string;
  activities?: ExpertsActivity[];
  tagLine?: string;
  pricing?: ExpertPricing[];
  yearsOfExperience?: number;
  qualifications?: string[];
  description?: string;
  languages?: string[];
  specialization?: ExpertSpecialization[];
  availability?: TherapistAvailabilityV2[];
  startDate?: IStartDate;
  userInterest?: {
    key: string;
    value: string;
  }[];
  listener?: ExpertListener;
  eventsCategories?: {
    userSelected: string[];
    userSuggested: string;
  };
  resumeUrl?: string;
  contractUrl?: string;
  onboardingStatus?: ExpertOnboardingStatus;
  noOfChangesRequested?: number;
  currentPageIndex?: number;
  comments?: Comments[];
  location?: ConsultationMode;
  bankDetails?: ExpertBankDetails;
}

export interface ConsultationMode {
  isOfflineConsultation: boolean;
  location: ExpertConsultationLocation;
}

export interface ExpertConsultationLocation {
  city: string;
  address: string;
}

export interface IStartDate {
  isImmediate: boolean;
  startDate?: string;
}

export interface ExpertBankDetails {
  accountHolderName: string;
  accountNo: string;
  ifscCode: string;
  bankingName: string;
}

export interface TherapistAvailabilityV2{
  day: string;
  slots: SlotsV2[];
}
export interface SlotsV2 {
  slotStart: string;
  slotEnd: string;
  online: boolean;
  offline: boolean;
}

  export enum ExpertsActivity {
    THERAPIST = "THERAPIST",
    LISTENER = "LISTENER",
    GO_ANYO_LIVE = "GO_ANYO_LIVE",
    MANAGE_EVENT = "MANAGE_EVENT",
    CIRCLE_EXPERT = "CIRCLE_EXPERT",
  }

  interface ExpertPricing {
    currency: string;
    min: number;
    max: number;
  }

  interface ExpertSpecialization {
    verticalName: string;
    verticalId: string;
    concerns: {
      concernId: string;
      concernName: string;
      expertLevelId: string;
      expertLevelName: string;
    }[];
  }

  interface ExpertAvailability {
    day: string;
    slots: {
      slotStart: string;
      slotEnd: string;
    }[];
  }

  interface ExpertListener {
    listenerName: string;
    listenerAvatar: string;
  }

  export enum ExpertOnboardingStatus {
    SUBMITTED = "SUBMITTED",
    UNDER_REVIEW = "UNDER_REVIEW",
    REQUIRE_CHANGES = "REQUIRE_CHANGES",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    PENDING = "PENDING"
  }
  export enum OnboardingPageTypes {
    ACTIVITY_FORM = "ACTIVITY_FORM",
    EXPERT_PROFILE_CREATION_FORM = "EXPERT_PROFILE_CREATION_FORM",
    CHOOSE_SPECIALIZATION = "CHOOSE_SPECIALIZATION",
    EXPERTISE_RATING = "EXPERTISE_RATING",
    EXPERT_STARTING_DATE = "EXPERT_STARTING_DATE",
    CIRCLE_PROFILE_FORM = "CIRCLE_PROFILE_FORM",
    LISTENER_FORM = "LISTENER_FORM",
    EVENTS_PROFILE_FORM = "EVENTS_PROFILE_FORM",
    UPLOAD_RESUME_FORM = "UPLOAD_RESUME_FORM",
    CONTRACT_FORM = "CONTRACT_FORM",
    LOCATION_PREFERENCE_FORM = "LOCATION_PREFERENCE_FORM",
    EXPERT_AVAILABILITY_FORM = "EXPERT_AVAILABILITY_FORM",
    THANK_YOU = "THANK_YOU",
  }

  export interface Comments {
    comment: string;
    addedAt: Date;
    addedBy: string;
  }