<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Experts Onboarding Requests</span>
      </div>
      <div nz-col>
        <button
          class="btn-color"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Clear filter
        </button>
      </div>
    </div>
  </ng-template>
<nz-table #basicTable 
[nzData]="gridResp.data"
[nzTitle]="tableHead"
[nzScroll]="{ y: '500px' }"
class="mt-5"
[nzShowPagination]="true"
nzFrontPagination="false"
[nzTotal]="gridResp.totalRecords"
[nzPageSize]="gridResp.pageSize"
[nzBordered]="true"
[nzShowSizeChanger]="true"
[nzPageSizeOptions]="pageSizeOptions"
[nzPageIndex]="gridResp.pageNum"
nzTableLayout="fixed"
(nzPageIndexChange)="nzPageIndexChange($event)"
(nzPageSizeChange)="nzPageSizeChange($event)"
>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Applied On</th>
        <th>Start Date</th>
        <th>Last Updated</th>
        <th>Activities</th>
        <th nzWidth="20%">Onboarding Status</th>
        <th >Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{data.name}}</td>
        <td>{{data.email}}</td>
        <td>{{data.appliedOn !== "N/A" ? utils.convertEpochToFormattedDate(data.appliedOn) : "N/A"}}</td>
        <td>
          {{
            data.startDate && data.startDate !== "N/A" 
              ? (data.startDate !== "Invalid date" 
                  ? utils.convertEpochToFormattedDate(data.startDate) 
                  : "N/A") 
              : "Immediate"
          }}
        </td>        
        <td [align]="'left'">
          <div>By</div>
          <div>{{ data.lastModifiedBy }}</div>
          <div>On</div>
          <div>{{data.lastModifiedAt !== "N/A" ? utils.convertEpochToFormattedDate(data.lastModifiedAt) : "N/A"}}</div>
        </td>
        <td [align]="'left'">
          <div *ngIf="data.activitiesWithColor.length < 0" >N/A</div>
          <div *ngIf="data.activitiesWithColor.length > 0" > </div>
          <div class="flex flex-wrap gap-1" *ngFor="let tag of data.activitiesWithColor">
            <nz-tag class="mt-1" [nzColor]="tag.color">{{
              utils.convertToTitleCase(tag.text)
            }}</nz-tag>
           </div>
           <div *ngIf="!data.activitiesWithColor.length">N/A</div>
        </td>
        <td>
          <nz-tag class="mt-1" [nzColor]="data.onboardingStatusColor">{{
            utils.convertToTitleCase(data.onboardingStatus)
          }}</nz-tag>
        </td>
        <td>
            <span nz-tooltip nzTooltipTitle="View Expert Requests Details">
                <button
                  nz-button
                  class="save"
                  nzShape="circle"
                  [routerLink]="['/experts/form/' + data.userId]"
                >
                <span nz-icon nzType="folder-view" nzTheme="outline"></span>
                </button>
              </span>
        </td>
      </tr>
    </tbody>
</nz-table>