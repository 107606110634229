import { Injectable } from '@angular/core';
import { AnyoEvents } from '../models/events/AnyoEvents';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import { EventsListsGridRequest } from '../models/events/eventsGridRequest';
import { EventsListGridResponse } from '../models/events/eventsAdminGridResponse';
import { EventsRegistrationsGridResponse } from '../models/events/eventRegistrationGridResponse';
import { EventRegistrationGridRequest } from '../models/events/eventRegistrationGridRequest';
import { AnyoClubPass } from '../models/events/AnyoClubPasses';
import { EventsBiddingsCreateOrUpdateRequest } from '../models/events/EventsBiddingsCreateOrUpdateRequest';
import { AnyoEventsBidding } from '../models/events/AnyoEventsBidding';
import { EventsBidsListsGridRequest } from '../models/events/eventsBidsGridRequest';
import { EventsBiddingListGridResponse } from '../models/events/eventsBidsGridResponse';
import { EventsBiddingLogGridRequest } from '../models/events/eventBidsLogsGridRequest';
import { EventsBiddingLogGridResponse } from '../models/events/eventBidsLogsGridResponse';
import { AdminAcceptOrRejectBidRequest } from '../models/events/AdminAccepetOrRejectBidRequest';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private api: NetworkUtilsService) {}

  saveEvents(anyoEvent: AnyoEvents) {
    return this.api.post(URL_DICT.addEvents, anyoEvent);
  }

  listEvents(gridRequest: EventsListsGridRequest) {
    return this.api.post<EventsListGridResponse>(
      URL_DICT.eventsGridUrl,
      gridRequest,
    );
  }

  listEventRegistration(gridRequest: EventRegistrationGridRequest) {
    return this.api.post<EventsRegistrationsGridResponse>(
      URL_DICT.eventsRegistrationGridUrl,
      gridRequest,
    );
  }

  getEvents(id: string) {
    return this.api.get<AnyoEvents>(URL_DICT.eventsGetUrl + `${id}`);
  }

  updateEvents(updateEvents: AnyoEvents, eventId: string) {
    return this.api.post(URL_DICT.eventsUpdateUrl + `${eventId}`, updateEvents);
  }

  getAllClubPasses() {
    return this.api.get<AnyoClubPass[]>(URL_DICT.getClubPass);
  }

  addOrUpdateEventBidding(req: EventsBiddingsCreateOrUpdateRequest) {
    return this.api.post(URL_DICT.adminAddOrUpdateEventsBidding, req);
  }
  getEventBidding(id: string) {
    return this.api.get<AnyoEventsBidding>(URL_DICT.adminGetEventsBidding + id);
  }

  getEventBidsListGrid(gridRequest: EventsBidsListsGridRequest) {
    return this.api.post<EventsBiddingListGridResponse>(
      URL_DICT.adminEventsBidsGridUrl,
      gridRequest,
    );
  }
  getEventBidsLogGrid(gridRequest: EventsBiddingLogGridRequest) {
    return this.api.post<EventsBiddingLogGridResponse>(
      URL_DICT.adminEventsBidsLogGridUrl,
      gridRequest,
    );
  }
  acceptOrRejectBid(req: AdminAcceptOrRejectBidRequest) {
    return this.api.post(URL_DICT.adminAcceptOrRejectBid, req);
  }
}
