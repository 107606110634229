import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEventComponent } from './add-event/add-event.component';
import { ManageEventsComponent } from './manage-events/manage-events.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { ManageRegistrationsComponent } from './manage-registrations/manage-registrations.component';
import { ManageEventsBiddingsComponent } from './manage-events-biddings/manage-events-biddings.component';
import { CurdEventsBiddingsComponent } from './curd-events-biddings/curd-events-biddings.component';

const routes: Routes = [
  {
    path: '',
    component: ManageEventsComponent,
  },
  {
    path: 'add',
    component: AddEventComponent,
  },
  {
    path: 'add/:id',
    component: AddEventComponent,
  },
  {
    path: 'edit/:id',
    component: EditEventComponent,
  },
  {
    path: 'registrations',
    component: ManageRegistrationsComponent,
  },
  {
    path: 'events-bidding',
    component: ManageEventsBiddingsComponent,
  },
  {
    path: 'events-bidding/curd/:id',
    component: CurdEventsBiddingsComponent,
  },
  {
    path: 'events-bidding/curd',
    component: CurdEventsBiddingsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {}
