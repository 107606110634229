import { Component } from '@angular/core';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { EventsBiddingListGridResponse } from '../../../models/events/eventsBidsGridResponse';
import {
  EventsBidListTab,
  EventsBidsListsGridRequest,
} from '../../../models/events/eventsBidsGridRequest';
import { EventsService } from '../../../services/events.service';
import {
  EventBidsStatus,
  EventsBiddingsCreateOrUpdateRequest,
} from '../../../models/events/EventsBiddingsCreateOrUpdateRequest';
import * as moment from 'moment';
import {
  EventBiddingLogListSort,
  EventsBiddingLogGridRequest,
} from '../../../models/events/eventBidsLogsGridRequest';
import {
  BiddingStatus,
  EventsBiddingLogResponse,
} from '../../../models/events/eventBidsLogsGridResponse';
import { AdminAcceptOrRejectBidRequest } from '../../../models/events/AdminAccepetOrRejectBidRequest';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import { AnyoEventsBidding } from '../../../models/events/AnyoEventsBidding';
import { Permissions } from 'projects/app-core/src/auth/models/roles';

@Component({
  selector: 'app-manage-events-biddings',
  templateUrl: './manage-events-biddings.component.html',
  styleUrl: './manage-events-biddings.component.scss',
})
export class ManageEventsBiddingsComponent extends AnyoComponent {
  constructor(
    auth: AuthService,
    private toastService: ToastService,
    private eventService: EventsService,
  ) {
    super(auth);
  }
  readonly statusFilter = [
    EventBidsStatus.OPEN_FOR_BIDDING,
    EventBidsStatus.CLOSED,
  ];
  readonly eventBidLogStatus = [
    BiddingStatus.ACCEPTED,
    BiddingStatus.IN_REVIEW,
    BiddingStatus.REJECTED,
  ];
  readonly sortFilter = ['Low to High', 'High to Low'];
  eventStatusEnum = EventBidsStatus;
  biddingStatusEnum = BiddingStatus;
  permission = Permissions;
  readonly dateFormat = 'dd/MM/yyyy';
  loading: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  dateRange: Date[] = [];
  globalSearchInput: string = '';
  eventBidsListDate: EventsBiddingListGridResponse =
    {} as EventsBiddingListGridResponse;
  testEvent: boolean = false;
  tab: EventsBidListTab | undefined = undefined;
  selectStatus: EventBidsStatus | undefined = undefined;
  eventsBidEndFilterDate: Date[] = [];
  isDrawerVisible: boolean = false;
  expertBidId: string = '';
  async ready(): Promise<void> {
    if (this.user) {
      await this.loadInitialData();
    }
  }

  async loadInitialData() {
    const req: EventsBidsListsGridRequest = {
      page: this.page,
      pageSize: this.pageSize,
      filters: {
        TEST_EVENTS: [this.testEvent ? 'true' : 'false'],
        EVENT_BID_STATUS: this.selectStatus ? [this.selectStatus] : [],
      },
      globalSearch: [],
      dateFilters: {
        EVENT_BID_END_DATE: undefined,
        EVENT_DATE: undefined,
      },
      tab: this.tab,
      export: false,
    };
    this.globalSearchInput
      ? req.globalSearch!.push(this.globalSearchInput)
      : undefined;
    if (this.dateRange) {
      req.dateFilters!.EVENT_DATE = {
        from: moment(this.dateRange[0], 'x').startOf('day').toDate(),
        to: moment(this.dateRange[1], 'x').endOf('day').toDate(),
      };
    }
    if (this.eventsBidEndFilterDate) {
      req.dateFilters!.EVENT_BID_END_DATE = {
        from: moment(this.eventsBidEndFilterDate[0], 'x')
          .startOf('day')
          .toDate(),
        to: moment(this.eventsBidEndFilterDate[1], 'x').endOf('day').toDate(),
      };
    }
    this.loading = true;
    this.eventService.getEventBidsListGrid(req).subscribe({
      next: (data) => {
        console.log(data);
        this.eventBidsListDate = data;
      },
      error: (err) => {
        this.toastService.showError(err.error.description);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  async resetAllFilter() {
    this.globalSearchInput = '';
    this.tab = undefined;
    this.selectStatus = undefined;
    this.dateRange = [];
    this.eventsBidEndFilterDate = [];
    await this.loadInitialData();
  }
  async searchGlobal() {
    this.page = 1;
    await this.loadInitialData();
  }
  async testEventChangeFn($event: boolean) {
    this.page = 1;
    this.testEvent = $event;
    await this.loadInitialData();
  }

  async statusFilterFun() {
    this.page = 1;
    await this.loadInitialData();
  }
  async resetStatusFilterFun() {
    this.page = 1;
    this.selectStatus = undefined;
    await this.loadInitialData();
  }

  onEventsFilterDateChange($event: Date[]) {
    this.dateRange = $event;
  }

  async dateFilterFn() {
    this.page = 1;
    await this.loadInitialData();
  }
  async resetFilter() {
    this.dateRange = [];
    await this.loadInitialData();
  }

  onBidEndDateChange($event: Date[]) {
    this.eventsBidEndFilterDate = $event;
  }

  async bidEndDateFilterFn() {
    this.page = 1;
    await this.loadInitialData();
  }
  async resetBidEndDateFilterFn() {
    this.eventsBidEndFilterDate = [];
    await this.loadInitialData();
  }

  async nzPageIndexChange($event: number) {
    this.page = $event;
    await this.loadInitialData();
  }
  nzPageSizeChange($event: number) {
    this.toastService.showSuccess($event as unknown as string);
  }

  formatUnixToDate(unixTimestamp: number): string {
    return moment(unixTimestamp).format('DD-MM-YYYY');
  }

  isCloseBidModalVisible: boolean = false;
  event: AnyoEventsBidding = {} as AnyoEventsBidding;

  async openCloseBidModal(id: string) {
    await this.getAndSetTheEventsBidding(id);
    this.isCloseBidModalVisible = true;
  }
  closeCloseBidModal() {
    this.isCloseBidModalVisible = false;
    this.event = {} as AnyoEventsBidding;
  }

  async getAndSetTheEventsBidding(id: string): Promise<void> {
    this.eventService.getEventBidding(id).subscribe({
      next: async (event) => {
        this.event = event;
        console.log(event);
      },
      error: (error) => {
        this.toastService.showError(error);
      },
    });
  }

  async closeBid() {
    const reqBody: EventsBiddingsCreateOrUpdateRequest = {
      ...this.event,
      totalHost: this.event.totalEventHost,
      eventStatus: EventBidsStatus.CLOSED,
      eventDate: moment(this.event.eventDate).valueOf(),
      maxExpertBidLimit: this.event.maxExpertBidLimit[0].amount,
      biddingEndDate: moment(this.event.biddingEndDate).valueOf(),
      id: this.event._id,
    };
    // console.log(reqBody);
    // return;
    this.eventService.addOrUpdateEventBidding(reqBody).subscribe({
      next: () => {
        this.toastService.showSuccess(`Event Bid closed Successfully`);
        this.closeCloseBidModal();
        this.loadInitialData();
      },
      error: (err) => {
        this.toastService.showError(err.error.description);
      },
    });
  }

  bidsLogData: EventsBiddingLogResponse[] = [];
  logLoading: boolean = false;
  bidLogStatusFilter: BiddingStatus | undefined = undefined;
  amountSort: string = '';
  isModalVisible = false;
  isAcceptModalVisible = false;
  bidId: string = '';
  totalHostReached: boolean = false;

  onCloseModal() {
    this.isModalVisible = false;
  }
  onOpenModal(id: string, flag = true) {
    this.bidId = id;
    this.isAcceptModalVisible = flag;
    this.isModalVisible = true;
  }

  onConfirm() {
    this.acceptOrRejectBid(this.expertBidId, this.bidId);
    this.isModalVisible = false;
  }

  async openDrawer(id: string, hostReached: boolean) {
    this.totalHostReached = hostReached;
    this.isDrawerVisible = true;
    this.fetchLogs(id);
  }

  fetchLogs(eventBidId: string) {
    this.expertBidId = eventBidId;
    const req: EventsBiddingLogGridRequest = {
      page: 1,
      pageSize: 50,
      filters: {
        EVENT_BID_ID: [eventBidId],
        EVENT_BID_LOG_STATUS: [],
      },
      sort: {} as Record<
        EventBiddingLogListSort,
        AnyoSortDirection | undefined
      >,
      export: false,
    };
    if (this.bidLogStatusFilter) {
      req.filters!.EVENT_BID_LOG_STATUS = [this.bidLogStatusFilter];
    }
    if (this.amountSort) {
      req.sort!.PRICE =
        this.amountSort === 'Low to High'
          ? AnyoSortDirection.ASC
          : AnyoSortDirection.DESC;
    }
    this.logLoading = true;
    this.eventService.getEventBidsLogGrid(req).subscribe({
      next: (data) => {
        this.bidsLogData = data.data;
      },
      error: (err) => {
        this.toastService.showError(err.error.description);
      },
      complete: () => {
        this.logLoading = false;
      },
    });
  }
  closeDrawer() {
    this.isDrawerVisible = false;
  }

  resetAmountSort() {
    this.amountSort = '';
    this.fetchLogs(this.expertBidId);
  }

  resetStatusFilter() {
    this.bidLogStatusFilter = undefined;
    this.fetchLogs(this.expertBidId);
  }

  acceptOrRejectBid(eventId: string, bidId: string) {
    const req: AdminAcceptOrRejectBidRequest = {
      eventBidId: eventId,
      bidId: bidId,
      status: this.isAcceptModalVisible
        ? BiddingStatus.ACCEPTED
        : BiddingStatus.REJECTED,
    };
    this.logLoading = true;
    this.eventService.acceptOrRejectBid(req).subscribe({
      next: () => {
        this.fetchLogs(this.expertBidId);
        this.loadInitialData();
        this.toastService.showSuccess('Bid Updated Successfully');
      },
      error: (err) => {
        this.toastService.showError(err.error.description);
      },
      complete: () => {
        this.logLoading = false;
      },
    });
  }
}
