<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex gap-2 pb-4">
  <div [routerLink]="['/experts']" class="w-fit">
    <span nz-icon nzType="left" nzTheme="outline"></span>
  </div>
  <h1>Add Experts</h1>
</div>
<div class="flex flex-col gap-6 rounded-lg bg-white p-4 w-full">
  <nz-spin [nzSpinning]="pageLoading">
    <div class="grid grid-cols-4 gap-4">
      <div class="w-full">
        <label class="form-label required">Mark As Test</label>
        <nz-switch [(ngModel)]="isTesting"></nz-switch>
      </div>
      <div class="w-full">
        <label class="form-label required">External</label>
        <nz-switch [(ngModel)]="isExternal"></nz-switch>
      </div>
    </div>
    <nz-divider nzText="Expert Info"></nz-divider>
    <div class="grid grid-cols-4 gap-4">
      <div class="flex flex-col w-full">
        <label class="form-label">Select Title :</label>
        <nz-select [(ngModel)]="title" nzPlaceHolder="Select Title">
          <nz-option nzValue="Mr" nzLabel="Mr"></nz-option>
          <nz-option nzValue="Ms" nzLabel="Ms"></nz-option>
          <nz-option nzValue="Mrs" nzLabel="Mrs"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div class="flex flex-col w-full">
        <label class="form-label">First Name :</label>
        <input
          [(ngModel)]="firstName"
          nz-input
          type="text"
          placeholder="Enter First Name"
          class="w-full"
        />
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label">Last Name :</label>
        <input
          [(ngModel)]="lastName"
          nz-input
          type="text"
          placeholder="Enter Last Name"
          class="w-full"
        />
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label">Email :</label>
        <input
          [(ngModel)]="email"
          nz-input
          type="text"
          placeholder="Enter Email"
          class="w-full"
        />
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label">Phone No :</label>
        <input
          [(ngModel)]="phoneNo"
          nz-input
          type="text"
          placeholder="Enter Phone Number"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2">
      <div class="flex flex-col gap-1">
        <label class="form-label">Profile Pic</label>
        <img *ngIf="profilePic" [src]="profilePic" alt="ProfilePic" />
        <input
          (change)="uploadTherapistImage($event)"
          nz-input
          accept=".jpg,.jpeg,.png"
          type="file"
          required
        />
      </div>
      <div class="flex flex-col gap-1">
        <label class="form-label">Video Bio</label>
        <video *ngIf="videoSrc" [src]="videoSrc" controls>
          Your browser does not support the video tag.
        </video>
        <input
          (change)="uploadVideoImage($event)"
          nz-input
          accept=".mp4"
          type="file"
          required
        />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label">Archive</label>
        <nz-switch [(ngModel)]="archived"></nz-switch>
      </div>
    </div>
    <nz-divider nzText="Therapy info"></nz-divider>
    <div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label required">Expert consultation</label>
        <nz-switch [(ngModel)]="isTherapist"></nz-switch>
      </div>
      <div class="w-full">
        <label class="form-label required">Is Visible</label>
        <nz-switch [(ngModel)]="isVisibleTherapist"></nz-switch>
      </div>
    </div>

    <div class="grid grid-cols-4 gap-2">
      <div class="flex flex-col w-full">
        <label class="form-label"> Select Designation Level : </label>
        <nz-select
          [(ngModel)]="selectedTherapistLevel"
          nzPlaceHolder="Select Designation Level"
        >
          <nz-option
            *ngFor="let vertical of therapistExpertLevels"
            [nzValue]="vertical.data"
            [nzLabel]="vertical.data"
          ></nz-option>
        </nz-select>
      </div>
      <div class="w-full">
        <label class="form-label">Rate Therapist :</label>
        <nz-rate [(ngModel)]="rating"></nz-rate>
      </div>
      <div>
        <label class="form-label">Max Pricing :</label>
        <input
          [(ngModel)]="maxPricing"
          nz-input
          type="number"
          placeholder="Enter Max Pricing"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label">Min Pricing :</label>
        <input
          [(ngModel)]="minPricing"
          nz-input
          type="number"
          placeholder="Enter Min Pricing"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-3">
      <div>
        <label class="form-label">TagLine</label>
        <input
          [(ngModel)]="tagLine"
          nz-input
          type="text"
          placeholder="Enter Tag Line"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label required">Languages</label>
        <nz-select
          [(ngModel)]="selectedLanguage"
          class="w-full"
          nzPlaceHolder="Select Languages"
          nzMode="multiple"
        >
          <nz-option
            *ngFor="let expItem of languages"
            [nzLabel]="expItem.data"
            [nzValue]="expItem.value"
          ></nz-option>
        </nz-select>
      </div>
      <div>
        <label class="form-label required">
          Slug (We want to enter slug like this example: "test-user") :
        </label>
        <input
          [(ngModel)]="therapistSlug"
          nz-input
          type="text"
          placeholder="Therapist Slug"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-3">
      <div>
        <label class="form-label">Retail Cost</label>
        <input
          [(ngModel)]="retailCost"
          nz-input
          type="number"
          placeholder="Enter Retail Cost"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label">Corporate Cost</label>
        <input
          [(ngModel)]="corporateCost"
          nz-input
          type="number"
          placeholder="Enter Corporate Cost"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2">
      <div>
        <label class="form-label required">About</label>
        <textarea [(ngModel)]="about" rows="4" nz-input></textarea>
      </div>
      <div>
        <label class="form-label required">Years Of Experiance</label>
        <input
          [(ngModel)]="yearOfExperiance"
          nz-input
          type="number"
          placeholder="Enter Year Of Experiance"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div>
        <label class="form-label"
          >Map Address(embedded link from google map):</label
        >
        <input
          nz-input
          type="text"
          placeholder="Enter map address (embedded link from google map)"
          [(ngModel)]="mapAddress"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label">offline Map Link</label>
        <input
          nz-input
          type="text"
          placeholder="Enter Offline Map Link"
          [(ngModel)]="offlineMapLink"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label">Offline Address</label>
        <input
          nz-input
          type="text"
          placeholder="Enter Offline Address"
          [(ngModel)]="offlineAddress"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label">Offline City</label>
        <input
          nz-input
          type="text"
          placeholder="Enter Offline City"
          [(ngModel)]="offlineCity"
          class="w-full"
        />
      </div>
      <div>
        <label class="form-label required">offline Available</label>
        <nz-switch [(ngModel)]="offlineAvailable"></nz-switch>
      </div>
    </div>

    <nz-divider nzText="Vertical and Concern"></nz-divider>
    <div class="grid grid-cols-2 gap-3">
      <div
        *ngFor="let item of expertiseLevelArray; index as i"
        class="border border-gray-500 rounded-lg p-5"
      >
        <div class="flex flex-row justify-between gap-3">
          <nz-select
            [ngModel]="item.verticalId"
            (ngModelChange)="verticalChange(i, $event)"
            class="w-[130%]"
            nzPlaceHolder="Select Vertical"
          >
            <nz-option
              *ngFor="let vertical of verticalsMasterDataFiltered"
              [nzValue]="vertical.value"
              [nzLabel]="vertical.label"
              [nzDisabled]="vertical.disabled"
            ></nz-option>
          </nz-select>
          <!-- Delete button if not last element -->
          <button
            *ngIf="i !== expertiseLevelArray.length - 1"
            (click)="deleteVertical(i)"
            type="button"
            nz-button
            nzType="primary"
            nzShape="circle"
          >
            <span nz-icon nzType="minus" nzTheme="outline"></span>
          </button>

          <button
            *ngIf="
              i === expertiseLevelArray.length - 1 &&
              verticalsMasterDataFiltered.length
            "
            (click)="addVertical()"
            type="button"
            nz-button
            nzType="primary"
            nzShape="circle"
          >
            <span nz-icon nzType="plus" nzTheme="outline"></span>
          </button>
        </div>
        <br />
        <div class="flex flex-col gap-3">
          <div *ngFor="let concern of item.concerns; index as j">
            <div class="flex flex-row flex-1 gap-3">
              <nz-select
                [ngModel]="concern.concernsId"
                (ngModelChange)="concernChange(i, j, $event)"
                class="w-[100%]"
                nzPlaceHolder="Select Concern"
              >
                <nz-option
                  *ngFor="let cItem of item.concernsList"
                  [nzLabel]="cItem.label"
                  [nzValue]="cItem.value"
                  [nzDisabled]="cItem.disabled"
                ></nz-option>
              </nz-select>
              <nz-select
                [ngModel]="concern.expertLevelId"
                (ngModelChange)="expertLevelChange(i, j, $event)"
                class="w-[100%]"
                nzPlaceHolder="Select Expertise"
              >
                <nz-option
                  *ngFor="let expItem of expertLevelMasterData"
                  [nzLabel]="expItem.label"
                  [nzValue]="expItem.value"
                ></nz-option>
              </nz-select>
              <button
                *ngIf="j !== item.concerns.length - 1"
                (click)="deleteConcern(i, j)"
                type="button"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <span nz-icon nzType="minus" nzTheme="outline"></span>
              </button>

              <button
                *ngIf="
                  j === item.concerns.length - 1 && item.concernsList.length
                "
                (click)="addConcern(i)"
                type="button"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <span nz-icon nzType="plus" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nz-divider nzText="Circle Expert info"></nz-divider>
    <div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label required">Circle Expert</label>
        <nz-switch [(ngModel)]="isExpert"></nz-switch>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <div class="flex flex-col w-full">
        <label class="form-label"> Select User Interest : </label>
        <nz-select
          [(ngModel)]="selectedUserInterests"
          nzPlaceHolder="Select User Interests"
          nzMode="multiple"
        >
          <nz-option
            *ngFor="let item of userInterestTopics"
            [nzLabel]="item.value"
            [nzValue]="item.key"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <nz-divider nzText="Listener info"></nz-divider>
    <div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label required">Listener</label>
        <nz-switch [(ngModel)]="isListener"></nz-switch>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <div class="flex">
          <div
            [ngClass]="
              'flex p-2 items-center border-solid rounded-md upload-pic cursor-pointer mt-1'
            "
            (click)="show()"
            id="chooseAvatar"
          >
            <img
              *ngIf="avatar"
              class="m-2 cursor-pointer"
              src="../../../../assets/icons/{{ avatar }}.png"
              alt="i"
            />
            <div class="flex flex-col items-center">
              <p class="text-sm">Choose your avatar</p>
              <div class="flex gap-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-avatar mt-[40rem]" id="card" style="display: none">
        <img
          *ngFor="let avatar of avatarImages"
          (click)="uploadAvatar(avatar.value, avatar.data)"
          class="m-2 cursor-pointer"
          src="../../../../assets/icons/{{ avatar.data }}.png"
          alt="i"
        />
      </div>
      <div>
        <label class="form-label required">Avatar Name</label>
        <nz-select
          [(ngModel)]="avatarName"
          class="w-[100%]"
          nzPlaceHolder="Select AvatarName"
        >
          <nz-option
            *ngFor="let expItem of avatarNames"
            [nzLabel]="expItem.data"
            [nzValue]="expItem.value"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <nz-divider nzText="Live info"></nz-divider>
    <div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label required">Live Expert</label>
        <nz-switch [(ngModel)]="isGoAnyoLive"></nz-switch>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div class="flex flex-col w-full">
        <label class="form-label"> Add Expert In Anyo Talks : </label>
        <nz-select
          [(ngModel)]="selectedAnyoTalks"
          nzPlaceHolder="add expert to anyo talks"
          nzMode="multiple"
        >
          <nz-option
            *ngFor="let talks of anyoTalks"
            [nzValue]="talks.value"
            [nzLabel]="talks.data"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-row class="pt-4" nzJustify="end">
      <div nz-col>
        <button (click)="create()" nz-button nzType="primary">
          Save Experts
        </button>
      </div>
    </div>
  </nz-spin>
</div>
