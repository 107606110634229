import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../../../../app-core/src/auth/auth.service';
import { Permissions, Roles } from 'projects/app-core/src/auth/models/roles';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { Menu } from 'projects/app-core/src/app/models/menu';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  menu: Menu[] = [
    {
      name: 'Dashboard',
      icon: 'assets/icons/dashboard.svg',
    },
  ];
  user: string = '';
  domainNameMessage: string = environment.domain;
  constructor(private auth: AuthService) {}
  async ngOnInit(): Promise<void> {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.user = user.name!;
        this.handleAdditionalPermissions(user.customClaims);
        this.handleRoles(user.customClaims);
      }
    });
  }

  handleAdditionalPermissions(customClaims: IAnyoUser['customClaims']) {
    if (!customClaims) return;
    if (
      [
        Permissions.AdminManageTherapist,
        Permissions.AdminManageListener,
        Permissions.AddTherapist,
      ].some((per) => customClaims.additionalPermissions.includes(per))
    ) {
      this.menu.push({
        name: 'Experts',
        icon: 'assets/icons/listener.svg',
        subMenu: [
          {
            name: 'Manage Experts',
            link: '/experts',
          },
          {
            name: 'Mange Onboarding Requests',
            link: '/experts/onboarding'
          }
        ],
      });
    }
    if (
      [
        Permissions.AdminManageTherapist,
        Permissions.AdminManageAppointment,
        Permissions.AdminManageTherapyPlans,
      ].some((permission) =>
        customClaims.additionalPermissions.includes(permission),
      )
    ) {
      this.menu.push({
        name: 'Therapists',
        icon: 'assets/icons/therapist.svg',
        subMenu: [],
      });
    }

    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManageTherapist,
      )
    ) {
      const index = this.menu.findIndex((data) => data.name === 'Therapists');
      this.menu[index].subMenu?.push({
        name: 'Manage Therapist',
        link: '/therapist/manage',
      });
    }

    if (customClaims.roles.includes(Roles.ManageUserAttributes)) {
      const index = this.menu.findIndex((data) => data.name === 'Therapists');
      this.menu[index].subMenu?.push({
        name: 'Manage User Attributes',
        link: '/therapist/manage-user-attributes',
      });
    }

    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManageAppointment,
      )
    ) {
      const index = this.menu.findIndex((data) => data.name === 'Therapists');
      this.menu[index].subMenu?.push({
        name: 'Manage Appointments',
        link: '/therapist/session/admin',
      });
    }
    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManageTherapyPlans,
      )
    ) {
      const index = this.menu.findIndex((data) => data.name === 'Therapists');
      this.menu[index].subMenu?.push({
        name: 'Manage Therapy Plans',
        link: '/therapy-plans/list',
      });
    }
    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManageTherapyPlans,
      )
    ) {
      const index = this.menu.findIndex((data) => data.name === 'Therapists');
      this.menu[index].subMenu?.push({
        name: 'All Therapy Plans',
        link: '/therapy-plans/all',
      });
    }

    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManageTherapyPlans,
      )
    ) {
      const index = this.menu.findIndex((data) => data.name === 'Therapists');
      this.menu[index].subMenu?.push({
        name: 'Therapy Plan Subscriptions',
        link: '/therapy-plans/all-users-subscriptions',
      });
    }

    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManageListener,
      )
    ) {
      this.menu.push({
        name: 'Listener',
        icon: 'assets/icons/listener.svg',
        subMenu: [
          {
            name: 'Manage Listener',
            link: '/listener',
          },
        ],
      });
    }

    if (
      customClaims.additionalPermissions.includes(Permissions.ContentManager)
    ) {
      this.menu.push({
        name: 'Content Library',
        icon: 'assets/icons/content.svg',
        subMenu: [
          {
            name: 'Manage Content',
            link: '/content',
          },
        ],
      });
    }

    if (
      customClaims.additionalPermissions.includes(
        Permissions.AdminManagePartner,
      )
    ) {
      this.menu.push({
        name: 'Partners',
        icon: 'assets/icons/corporate.svg',
        subMenu: [
          {
            name: 'Manage Partners',
            link: '/partner',
          },
        ],
      });
    }

    if (
      customClaims.additionalPermissions.includes(Permissions.RoutineManager)
    ) {
      this.menu.push({
        name: 'Routines',
        icon: 'assets/icons/challenge.svg',
        subMenu: [
          {
            name: 'Manage Routine',
            link: '/routine',
          },
        ],
      });
    }

    if (customClaims.additionalPermissions.includes(Permissions.BytesManager)) {
      this.menu.push({
        name: 'Bytes',
        icon: 'assets/icons/challenge.svg',
        subMenu: [
          {
            name: 'Manage Bytes',
            link: '/bytes',
          },
        ],
      });
    }

    if (
      customClaims.additionalPermissions.includes(
        Permissions.AddRecipeInContent,
      )
    ) {
      const index = this.menu.findIndex((data) => data.name === 'Content Library');
      this.menu[index].subMenu?.push({
        name: 'Add Recipe',
        link: '/content/recipe/add',
      });
    }
  }

  handleRoles(customClaims: IAnyoUser['customClaims']) {
    if (!customClaims || !customClaims.roles) return;

    if (customClaims.roles.includes(Roles.ViewUserProfile)) {
      this.menu.push({
        name: 'User Profile',
        icon: 'assets/icons/corporate.svg',
        subMenu: [
          {
            name: 'Search User',
            link: '/user-profile',
          },
        ],
      });
    }

    if (customClaims.roles.includes(Roles.TalksManager)) {
      this.menu.push({
        name: 'Anyo Talks',
        icon: 'assets/icons/dashboard.svg',
        subMenu: [
          {
            name: 'Talks',
            link: 'talks/',
          },
          {
            name: 'Talk Sessions',
            link: 'talks/talk-logs/grid',
          },
        ],
      });
    }

    if (customClaims.roles.includes(Roles.CircleManager)) {
      this.menu.push({
        name: 'Circles',
        icon: 'assets/icons/content.svg',
        subMenu: [
          {
            name: 'Manage Circles',
            link: '/circles',
          },
          {
            name: 'Manage Moderators',
            link: '/circles/moderators',
          },
        ],
      });
    }

    if (
      customClaims.roles.includes(Roles.CirclesModerator) ||
      customClaims.roles.includes(Roles.CircleManager)
    ) {
      this.menu.push({
        name: 'Posts',
        icon: 'assets/icons/challenge.svg',
        subMenu: [
          {
            name: 'Manage Posts',
            link: '/posts',
          },
        ],
      });
    }
    if (customClaims.roles.includes(Roles.SuperAdmin)) {
      this.menu.push({
        name: 'Roles And Permission',
        icon: 'assets/icons/challenge.svg',
        subMenu: [
          {
            name: 'Manage Roles And Permission',
            link: '/roles-permission',
          },
        ],
      });
    }

    if (customClaims.roles.includes(Roles.EventsManager)) {
      this.menu.push({
        name: 'Events',
        icon: 'assets/icons/challenge.svg',
        subMenu: [
          {
            name: 'Manage Events',
            link: '/events',
          },
          {
            name: 'Event Registrations',
            link: '/events/registrations',
          },
        ],
      });
    }
  }
}
