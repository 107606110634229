import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { ExpertRewardsGridRequest } from '../models/expertRewardGridRequest';
import {
  AnyoRewardTypes,
  ExpertRewardsGridResponse,
  TransactionDirection,
} from '../models/expertRewardGridResponse';

export interface AddOrUpdateRewardsValues {
  name: string;
  value: number;
  description: string;
  active: boolean;
}

export interface AdminGetRewardValuesResponse {
  id: string;
  name: string;
  description: string;
  amount: number;
}
export interface AdminMakePayoutToExpertsRequest {
  amount: number;
  transactionId: string;
  modeOfPayment: string;
  userId: string;
}

export interface AdminAddRewardToLedger {
  paymentTransactionType: TransactionDirection;
  postId?: string;
  amount: number;
  userId: string;
  description: AnyoRewardTypes | string;
}

@Injectable({
  providedIn: 'root',
})
export class RewardService {
  constructor(private api: NetworkUtilsService) {}

  public getRewardValues() {
    return this.api.get<AdminGetRewardValuesResponse[]>(
      URL_DICT.adminGetRewardsValues,
    );
  }

  public updateRewardValues(requestBody: AddOrUpdateRewardsValues) {
    return this.api.post(URL_DICT.adminUpdateRewardsValues, requestBody);
  }

  public rewardGrid(requestBody: ExpertRewardsGridRequest) {
    return this.api.post<ExpertRewardsGridResponse>(
      URL_DICT.adminRewardGrid,
      requestBody,
    );
  }

  public makePayout(requestBody: AdminMakePayoutToExpertsRequest) {
    return this.api.post(URL_DICT.adminMakePayment, requestBody);
  }

  public addRewardToLedger(requestBody: AdminAddRewardToLedger) {
    return this.api.post(URL_DICT.addRewardToLedger, requestBody);
  }
}
