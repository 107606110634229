<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Therapy Plans Subcriptions For Users</span>
      </div>
      <div class="flex gap-2">
        <nz-switch [(ngModel)]="showTestCount" (ngModelChange)="showTestUsers()" nzCheckedChildren="hide test user" nzUnCheckedChildren="show test user"></nz-switch>
        <div nz-col>
          <button
            class="btn-color"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Clear filter
          </button>
        </div>
        </div>
    </div>
  </ng-template>
  <ng-template #innerTableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Session Details</span>
      </div>
    </div>
  </ng-template>
  <div class="max-w-1xl mx-auto bg-white p-4 rounded-lg shadow-lg">
    <div class="flex flex-wrap pb-4 justify-start">
      <h1 class="headeradminui-text pl-2">{{ 'Manage Therapy Plans Subscriptions For Users'}}</h1>
    </div>
    <div style="background: #ECECEC; padding: 30px;">
      <app-stats-cards [cards]="cards"></app-stats-cards>
    </div>

    <div class="flex justify-end mt-3 mb-3">
      <div class="w-[40%]">
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
          <input
            type="text"
            nz-input
            [(ngModel)]="globalSearchInput"
            placeholder="Search for user email,phone number"
          />
        </nz-input-group>
      </div>
    </div>
    <ng-template #suffixButton>
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        nzSearch
        (click)="searchGlobal()"
      >
        Search
      </button>
    </ng-template>
   
    <nz-spin [nzSpinning]="pageLoading">
      <nz-table
        #nestedTable
        [nzTitle]="tableHead"
        [nzScroll]="{ y: '500px' }"
        [nzData]="therapyPlansSubscriptionsForEachUsers"
        [nzBordered]="true"
        [nzShowPagination]="true"
        nzFrontPagination="false"
        [nzTotal]="totalUsers"
        [nzPageSize]="pageSize"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="pageSizeOptions"
        [nzPageIndex]="page"
        nzTableLayout="fixed"
        (nzPageIndexChange)="nzPageNoChange($event)"
        (nzPageSizeChange)="nzPageSizeChange($event)"
      >
        <thead>
          <tr>
            <th nzWidth="15%">User Name</th>
            <th nzWidth="10%">User Email</th>
            <th nzWidth="10%">Partner Id
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="partnerId"
            >
              <span nz-icon nzTheme="outline" nzType="filter-o"></span>
            </nz-filter-trigger>
            </th>
            <th>Phone No</th>
            <th nzWidth="10%">Latest Subscribed</th>
            <th>Total Plans</th>
            <th>Active Plans</th>
            <th>Completed Plans</th>
            <th>Expired Plans</th>
            <th>Total Plan Value</th>
            <th>Free Plans</th>
            <th>Paid Plans</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of nestedTable.data">
            <td><span class="cursor-pointer underline" (click)="goToFilterUsers(data.userId)">
              <nz-tag class="flex-wrap" [nzColor]="data.color">{{
                data.userName ? data.userName : "N/A"
              }}</nz-tag>
            </span></td>
            <td>{{ data.userEmail }}</td>
            <td>{{ data.partnerId }}</td>
            <td>{{ data.phoneNumber ? data.phoneNumber : "N/A" }}</td>
            <td>{{ utils.convertEpochToFormattedDate(data.latestSubscribedDate) }}</td>
            <td><nz-badge
              nzStandalone
              [nzCount]="data.totalPlans"
              [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }"
            ></nz-badge></td>
            <td>{{ data.activePlans }}</td>
            <td>{{ data.completedPlans }}</td>
            <td>{{ data.expiredPlans }}</td>
            <td>{{ formatToRupees(data.totalPlanValue) }}</td>
            <td>{{ data.freePlans }}</td>
            <td>{{ data.paidPlans }}</td>
          </tr>
        </tbody>
      </nz-table>
    </nz-spin>
  </div>
  <nz-dropdown-menu #partnerId="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div nz-row>
        <div nz-col>
          <span class="font-bold">Payment Type</span>
          <nz-divider></nz-divider>
          <nz-checkbox-wrapper (nzOnChange)="partnerIdFilterChangeFn($event)">
            <div *ngFor="let topic of partnerIdCheckBoxOptions" nz-row>
              <div nz-col>
                <label
                  nz-checkbox
                  [nzValue]="topic.value"
                  [ngModel]="topic.checked"
                  >{{ topic.label }}</label
                >
              </div>
            </div>
          </nz-checkbox-wrapper>
        </div>
      </div>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="fetchList()"
          >
            Ok
          </button>
        </div>
  
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>