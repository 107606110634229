<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span *ngIf="therapyPlans.length > 0" class="font-bold">{{'Therapy Plans Subcription for User - ' + therapyPlans[0].userDetails.name }}</span>
        <span *ngIf="therapyPlans.length <= 0" class="font-bold">Therapy Plans Subcription for User</span>

      </div>
      <div class="flex gap-2">
      <!-- <nz-switch [(ngModel)]="showTestUsers" (ngModelChange)="showTestUserFn()" nzCheckedChildren="hide test user" nzUnCheckedChildren="show test user"></nz-switch> -->
      <div nz-col>
        <button
          class="btn-color"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Clear filter
        </button>
      </div>
      </div>
     
    </div>
  </ng-template>
  <ng-template #innerTableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Session Details - See on top for therapist Details and For Add Extra Sessions button(Add Permissions).</span>
      </div>
      <div nz-col>
        <button nz-button (click)="scrollToTop()" nzType="primary" >See Therapist Details</button>
      </div>
    </div>
  </ng-template>
  <div class="max-w-1xl mx-auto bg-white p-4 rounded-lg shadow-lg">
    <div class="flex flex-wrap pb-4 justify-start">
      <h1 class="headeradminui-text pl-2">Manage Therapy Plans Subscription</h1>
    </div>
    <div class="flex items-start gap-4 " style="background: #ECECEC;padding:30px;">
      <nz-card [nzLoading]="pageLoading" *ngIf="therapyPlans.length > 0" style="width:400px;" [nzBordered]="false" nzTitle="User info">
        <p>User Name : {{ therapyPlans[0].userDetails.name }}</p>
        <p>User Email : {{ therapyPlans[0].userDetails.email }}</p>
        <p>Gender:  {{ therapyPlans[0].userDetails.gender }}</p>
        <p>phone:  {{ therapyPlans[0].userDetails.phone }}</p>
        <p>partnerId:  {{ therapyPlans[0].userDetails.partnerId }}</p>
        <div class="flex gap-1 flex-wrap">tags:  <nz-tag *ngFor="let tag of therapyPlans[0].userDetails.userTags" [nzColor]="color">{{
          tag
        }}</nz-tag></div>
      </nz-card>
        <nz-card id="top-container" class="animate-bounce-custom w-full" *ngIf="selectedSubscription" [nzBordered]="false" nzTitle="Therapist for Selected sessions" [nzExtra]="extraTemplate">
          <p>Therapist Name : {{ therapistName }}</p>
          <p>Therapist Email : {{ therapistEmail }}</p>
        </nz-card>
        <ng-template #extraTemplate>
          <button *ngIf="showAddExtraSessionBtn" nz-button (click)="showModal()" >Add Extra Sessions</button>
        </ng-template>
       
    </div>
   
   
    <nz-spin [nzSpinning]="pageLoading">
      <nz-table
        #nestedTable
        [nzTitle]="tableHead"
        [nzScroll]="{ y: '500px' }"
        [nzData]="therapyPlans"
        [nzShowPagination]="true"
        nzFrontPagination="false"
        [nzTotal]="totalRecords"
        [nzPageSize]="pageSize"
        [nzBordered]="true"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="pageSizeOptions"
        [nzPageIndex]="currentPageNo"
        nzTableLayout="fixed"
        (nzPageIndexChange)="nzPageIndexChange($event)"
        (nzPageSizeChange)="nzPageSizeChange($event)"
      >
        <thead>
          <tr>
            <th nzWidth="40px"></th>
            <th>
              Plan Name
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="planNameSearch"
            >
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
            </th>
            <th >Purchased date
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="purchasedAtDateFilterMenu"
            >
              <span nz-icon nzTheme="outline" nzType="schedule"></span>
            </nz-filter-trigger>
            </th>
            <th>Validity
                <nz-filter-trigger
                [nzActive]="true"
                [nzDropdownMenu]="validTillDateFilterMenu"
              >
                <span nz-icon nzTheme="outline" nzType="schedule"></span>
              </nz-filter-trigger>
            </th>
            <th>
              Payment Type
              <nz-filter-trigger
                [nzActive]="true"
                [nzDropdownMenu]="paymentTypeMenu"
              >
                <span nz-icon nzTheme="outline" nzType="filter-o"></span>
              </nz-filter-trigger>
            </th>
            <th>Verticle</th>
            <th>Concern</th>
            <th>No of completed/total Sessions</th>
            <th>Plan value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of nestedTable.data">
          <tr>
            <td (nzExpandChange)="onExpandChange(data.id!,$event,data)"
            [nzExpand]="expandSet.has(data.id!)"></td>
            <td>{{ data.planName }}</td>
            <td>{{ utils.convertEpochToFormattedDate(data.purchasedAt)}}</td>
            <td>{{ utils.convertEpochToFormattedDate(data.validTill) }}</td>
            <td [align]="'left'">
              <nz-tag [nzColor]="data.paymentTypeColor">{{
                utils.convertToTitleCase(data.paymentType)
              }}</nz-tag>
            </td>
            <td>{{ data.vertical }}</td>
            <td>{{ data.concern }}</td>
            <td>{{ fractionsOfSessions(data.noOfSessions) }}</td>
            <td>{{ data.planValue ? data.planValue : 'N/A' }}</td>
            <td>
          <span
          nz-tooltip
          nzTooltipTitle="Transfer To New Therapist"
        >
          <button
            nz-button
            class="save"
            nzShape="circle"
            (click)="showModalTransfer(data)"
          >
            <span nz-icon nzType="setting" nzTheme="fill"></span>
          </button>
        </span>
            </td>
          </tr>
          <tr [nzExpand]="expandSet.has(data.id!)" >
            <nz-table #innerTable [nzData]="data.sessionDetailslist" nzSize="middle" [nzShowPagination]="false" [nzTitle]="innerTableHead">
              <thead>
                <tr>
                  <th>session Date</th>
                  <th>Session Name</th>
                  <th>Session Description</th>
                  <th>Session Booked</th>
                  <th>Session Completed</th>
                  <th>Session Status</th>
                  <th>Is Extra Session</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of innerTable.data">
                 <td>{{ data.sessionDate ? convertDataAndTime(data.sessionDate) : 'N/A' }}</td>
                  <td>
                   {{ data.sessionName }}
                  </td>
                  <td>{{ data.description }}</td>
                  <td>{{ data.sessionBooked ? 'Yes' : 'No' }}</td>
                  <td>{{ data.sessionCompleted ? 'Yes' : 'No' }}</td>
                  <td>{{ data.sessionStatus }}</td>
                  <td>{{ data.extraSession ? 'Yes' : 'No' }}</td>
                </tr>
              </tbody>
            </nz-table>
          </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </nz-spin>
  </div>
  
  <nz-dropdown-menu #paymentTypeMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div nz-row>
        <div nz-col>
          <span class="font-bold">Payment Type</span>
          <nz-divider></nz-divider>
          <nz-checkbox-wrapper (nzOnChange)="paymentTypeFilterChangeFn($event)">
            <div *ngFor="let topic of paymentTypeCheckBoxOptions" nz-row>
              <div nz-col>
                <label
                  nz-checkbox
                  [nzValue]="topic.value"
                  [ngModel]="topic.checked"
                  >{{ topic.label }}</label
                >
              </div>
            </div>
          </nz-checkbox-wrapper>
        </div>
      </div>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="fetchTherapyPlans()"
          >
            Ok
          </button>
        </div>
  
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #validTillDateFilterMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="ng-row">
        <nz-range-picker
          [(ngModel)]="validTillColumnFilterDate"
          (ngModelChange)="validTillFilterDateChange($event)"
        ></nz-range-picker>
      </div>
      <div nz-row class="mt-5" nzJustify="end">
        <div nz-col class="mr-1">
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="fetchTherapyPlans()"
          >
            Ok
          </button>
        </div>
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #purchasedAtDateFilterMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="ng-row">
        <nz-range-picker
          [(ngModel)]="purchasedAtColumnFilterDate"
          (ngModelChange)="purchasedAtFilterDateChange($event)"
        ></nz-range-picker>
      </div>
      <div nz-row class="mt-5" nzJustify="end">
        <div nz-col class="mr-1">
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="fetchTherapyPlans()"
          >
            Ok
          </button>
        </div>
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #planNameSearch="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="m-1" nz-row>
        <div nz-col>
          <input
            [(ngModel)]="planNameColumnSearch"
            nz-input
            placeholder="Search"
            nzSize="small"
          />
        </div>
      </div>
      <div class="m-1" nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchByPlanName()"
          >
            Ok
          </button>
          <button
            class="m-1"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-modal [nzOkLoading]="pageLoading" [(nzVisible)]="isVisible" nzTitle="Add Extra Sessions" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
      <label>Session Name</label>
      <input nz-input placeholder="session name" [(ngModel)]="extraSessionName" type="text" />
      <label>Session Description</label>
      <textarea class="mt-1" rows="3" placeholder="Description" nz-input [(ngModel)]="extraSessionDescription"></textarea>
    </ng-container>
  </nz-modal>
  <nz-modal [(nzVisible)]="isVisibleTransfer" [nzOkLoading]="transferTherapistLoading" nzTitle="Select New Therapist To Transfer" (nzOnCancel)="handleCancelTransfer()" (nzOnOk)="transferTherapist()">
    <ng-container *nzModalContent>
      <nz-select class="w-full" nzShowSearch nzAllowClear nzPlaceHolder="Select a therapists" [(ngModel)]="selectedTherapistId">
        <nz-option
          *ngFor="let therapist of therapistDropdownData"
          [nzValue]="therapist.value"
          [nzLabel]="therapist.label"
        ></nz-option>
      </nz-select>
    </ng-container>
  </nz-modal>