import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { lastValueFrom } from 'rxjs';
import { TherapistService } from '../../../services/therapist.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';

import { Status } from '../../../stores/types';
import {
  IAnyoTherapist,
  MasterData,
  TherapistAvailability,
} from '../../../models/IAnyoTherapist';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { v4 as uuidv4 } from 'uuid';
import { TherapistExpertLevel } from 'projects/app-core/src/models/TherapistExpertLevel';
import { CircleTopics } from '../../../models/circles/AnyoCircles';
import { CirclesService } from '../../../services/circles.service';

interface ExpertLevelArray {
  verticalId: string;
  verticalName: string;
  concernsList: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
  concerns: {
    concernsId: string;
    concernName: string;
    expertLevelId: string;
    expertLevel: string;
  }[];
}

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent {
  tagLine: any;
  deleteVideoBio() {
    if (this.therapist) {
      this.therapist.videoBio = '';
    }
    this.videoBioFile = undefined;
    this.isAlert = false;
  }
  uploadVideoImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.videoBioFile = file;
    }
  }
  availability: TherapistAvailability[] = [
    {
      day: 'monday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'tuesday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'wednesday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'thursday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'friday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'saturday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'sunday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
  ];

  extra: any[] = [
    {
      day: 'monfri',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'alldays',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
  ];

  qualificationString: string[] = [];
  areaOfExpertiseString: string[] = [];

  therapist: IAnyoTherapist = {
    tagLine: '',
    u_id: '',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    contactNo: '',
    languages: [],
    qualification: [],
    yearsOfExperience: '',
    areaOfExpertise: [],
    availability: this.availability,
    bio: '',
    isFlexibleWithTiming: false,
    isAvailable: false,
    status: 'active',
    preferredModesOfTherapy: [],
    picture: '',
    availableFrom: moment(),
    lastSeen: moment().toISOString(),
    rating: 0,
    videoBio: '',
    verticalIds: [],
    concernIds: [],
    concernExpertLevelMap: {},
    pricing: {
      minPricing: 0,
      maxPricing: 0,
    },
    userInterestTopics: [],
    designationLevel: '',
    offlineAvailable: false
  };
  compulsoryFields = [
    'title',
    'firstName',
    'lastName',
    'email',
    'contactNo',
    'qualification',
    'areaOfExpertise',
    'yearsOfExperience',
    'bio',
  ];
  newId = '';
  gotonext: boolean = false;
  addTherapistForm!: FormGroup;
  viewForm: boolean = false;
  editMode: boolean = false;
  isAlert: boolean = false;
  isDisabled: boolean = false;
  reUpload: boolean = false;
  isThumbnail: boolean = false;
  isVideoBio: boolean = false;
  isSubmit: boolean = false;
  alertHeaderDisable: string = 'Therapist Disable';
  alertBodyDisable: string =
    'Please make sure that you want to disable the therapist';
  alertHeaderEnable: string = 'Therapist Enable';
  alertBodyEnable: string =
    'Please make sure that you want to enable the therapist';
  steppertitle1: string = 'Profile Information';
  steppertitle2: string = 'Availability';
  alertHeaderDeletePicture: string = 'Thumbnail Delete';
  alertBodyDeletePicture: string = 'Thumbnail Delete';
  alertHeaderDeleteVideBio: string = 'Video Bio Delete';
  alertBodyDeleteVideoBio: string = 'Video Bio Delete';

  @ViewChild('fileInput') fileInput: any;
  @ViewChild('videoBioFileInput') videoBioFileInput: any;
  @ViewChild('checkbox') checkbox!: ElementRef<HTMLInputElement>;
  @ViewChild('mode1') mode1!: ElementRef<HTMLInputElement>;
  @ViewChild('mode2') mode2!: ElementRef<HTMLInputElement>;
  @ViewChild('mode3') mode3!: ElementRef<HTMLInputElement>;

  show: boolean = false;
  addSlot: boolean = false;
  audio: any;
  video: any;
  chat: any;
  profilePicture: any;
  qualifications: Select2Data = [];
  expertise: Select2Data = [];
  languages: Select2Data = [];
  therapistImageFile: File | undefined;
  videoBioFile: File | undefined;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private therapistService: TherapistService,
    private masterdataService: MasterdataService,
    private router: Router,
    private toastrService: ToastService,
    private activatedRoute: ActivatedRoute,
    private circleService: CirclesService,
    private fileService: FileUtilsService,
  ) {
    this.addTherapistForm = this.formBuilder.group({
      title: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      experience: ['', Validators.required],
      about: ['', Validators.required],
    });
  }

  inputValidation(event: KeyboardEvent) {
    if (!/^[0-9]$/.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }
  }

  setQualifications(qualifications: string[]) {
    console.log(qualifications);
  }

  setAreasOfExpertise(areasOfExpertise: string[]) {
    console.log(areasOfExpertise);
  }

  updateQualification(event: Select2UpdateEvent<any>) {
    this.therapist.qualification = event.value;
  }

  updateExpertise(event: Select2UpdateEvent<any>) {
    this.therapist.areaOfExpertise = event.value;
  }

  updateLanguages(event: Select2UpdateEvent<any>) {
    this.therapist.languages = event.value;
  }

  uploadTherapistImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.therapistImageFile = file;
      this.readMedia(this.therapistImageFile!);
    }
  }

  readMedia(file: File) {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.therapist.picture = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  getPlaceholder(category: string) {
    switch (category) {
      case 'Qualifications':
        return 'Enter Qualifications';
      case 'Areas of Expertise':
        return 'Select Areas of Expertise';
      default:
        return 'Enter..';
    }
  }

  next(form: any) {
    let flag = true;
    this.isSubmit = false;
    if (!this.validateExpertLevelArray()) {
      this.toastrService.showError('Please select valid experience level');
      return;
    }
    this.therapist.verticalIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );

    this.therapist.concernIds = this.expertiseLevelArray
      .map((value) => value.concerns.map((concern) => concern.concernsId))
      .flat();
    this.therapist.concernExpertLevelMap = this.expertiseLevelArray.reduce(
      (
        acc: Record<
          string,
          {
            level: string;
            id: string;
          }
        >,
        value,
      ) => {
        value.concerns.forEach((concern) => {
          acc[concern.concernsId] = {
            id: concern.expertLevelId,
            level: concern.expertLevel,
          };
        });
        return acc;
      },
      {},
    );
    this.compulsoryFields.forEach((field: any) => {
      if (
        !this.therapist[field as keyof IAnyoTherapist] ||
        (this.therapist[field as keyof IAnyoTherapist] as string[]).length == 0
      ) {
        flag = false;
      }
    });
    if (flag) {
      this.submit(form);
      // this.gotonext = true;
    } else {
      this.isSubmit = true;
      this.toastrService.showError('Please fill all the required fields');
    }
  }

  previous() {
    this.gotonext = false;
  }

  editForm() {
    this.viewForm = false;
    this.editMode = true;
  }

  //Todo refactor it Properly
  async submit(form: any) {
    if (!this.validateExpertLevelArray()) {
      this.toastrService.showError('Please select valid experience level');
      return;
    }
    this.therapist.verticalIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.therapist.concernIds = this.expertiseLevelArray
      .map((value) => value.concerns.map((concern) => concern.concernsId))
      .flat();
    this.therapist.concernExpertLevelMap = this.expertiseLevelArray.reduce(
      (
        acc: Record<
          string,
          {
            level: string;
            id: string;
          }
        >,
        value,
      ) => {
        value.concerns.forEach((concern) => {
          acc[concern.concernsId] = {
            id: concern.expertLevelId,
            level: concern.expertLevel,
          };
        });
        return acc;
      },
      {},
    );

    (this.therapist.userInterestTopics = this.userInterestTopics
      .filter((value) => this.selectedUserInterests.includes(value.key))
      .map((value) => {
        return {
          key: value.key,
          value: value.value,
        };
      })),
      (this.therapist.designationLevel = this.selectedTherapistLevel);

    this.therapist.contactNo = this.trimString(this.therapist.contactNo);
    this.therapist.preferredModesOfTherapy = [];
    if (form.value.mode1 == true || this.audio == true) {
      this.therapist.preferredModesOfTherapy.push('audio');
    }
    if (form.value.mode2 == true || this.video == true) {
      this.therapist.preferredModesOfTherapy.push('video');
    }
    if (form.value.mode3 == true || this.chat == true) {
      this.therapist.preferredModesOfTherapy.push('chat');
    }
    this.therapist.pricing = {
      maxPricing: this.idealPrice,
      minPricing: 0,
    };
    // this.therapist.availability = this.availability;
    const req = this.therapist;
    // this.therapist.availability.forEach((day: TherapistAvailability) => {
    //   if (day.slot1Start) {
    //     if (day.slot1End) {
    //       availabilityFlag = true;
    //     } else {
    //       availabilityFlag = false;
    //       errorMessage = 'Please provide the end timing for the slots given';
    //     }
    //   }
    //   if (day.slot2Start) {
    //     if (day.slot2End) {
    //       availabilityFlag = true;
    //     } else {
    //       availabilityFlag = false;
    //       errorMessage = 'Please provide the end timing for the slots given';
    //     }
    //   }
    // });

    // if (this.therapist.preferredModesOfTherapy.length == 0) {
    //   modeFlag = false;
    // }

    // if (availabilityFlag && modeFlag) {

    // } else {
    //   if (!availabilityFlag && !modeFlag) {
    //     if (errorMessage) {
    //       this.toastrService.showError(
    //         errorMessage + ' and the preferred mode of therapy',
    //       );
    //     } else {
    //       this.toastrService.showError(
    //         'Please provide the atleast one slot of availability and the preferred mode of therapy',
    //       );
    //     }
    //   } else if (!availabilityFlag && modeFlag) {
    //     if (errorMessage) {
    //       this.toastrService.showError(errorMessage);
    //     } else {
    //       this.toastrService.showError(
    //         'Please provide atleast one slot of availability',
    //       );
    //     }
    //   } else {
    //     this.toastrService.showError(
    //       'Please provide the preferred mode of therapy',
    //     );
    //   }
    // }

    if (this.editMode) {
      const _id = String(this.activatedRoute.snapshot.params['id']);
      if (this.therapistImageFile) {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const extension = this.therapistImageFile.name.split('.').pop();
        const filePath = `therapist/${uuidWithoutHyphens}.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.therapistImageFile.type,
          ),
        );
        this.toastrService.showSuccess(`Uploading Therapist Profile Picture`);
        await lastValueFrom(
          this.fileService.uploadFile(this.therapistImageFile, uploadUrl.url),
        );
        this.toastrService.showSuccess(
          `Uploading Therapist Profile Picture completed`,
        );
        req.picture = `https://cdn.anyo.app/${filePath}`;
      }

      if (this.videoBioFile) {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const extension = this.videoBioFile.name.split('.').pop();
        const videoBioFilePath = `therapist/${uuidWithoutHyphens}.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            videoBioFilePath,
            this.videoBioFile.type,
          ),
        );
        this.toastrService.showSuccess(`Uploading Therapist Video Bio`);
        await lastValueFrom(
          this.fileService.uploadFile(this.videoBioFile, uploadUrl.url),
        );
        this.toastrService.showSuccess(
          `Uploading Therapist Video Bio Completed`,
        );
        req.videoBio = videoBioFilePath;
      }
      this.therapistService.updateTherapist(_id, req).subscribe({
        next: () => {
          this.toastrService.showSuccess('Therapist edited successfully');
          this.router.navigate(['/therapist/manage']);
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
        },
      });
    } else {
      this.therapistService.createTherapist(req).subscribe({
        next: async (value: any) => {
          if (this.therapistImageFile) {
            const uuid = uuidv4();
            const uuidWithoutHyphens = uuid.replace(/-/g, '');
            const extension = this.therapistImageFile.name.split('.').pop();
            const filePath = `therapist/${uuidWithoutHyphens}.${extension}`;
            const uploadUrl = await lastValueFrom(
              this.fileService.generateUploadUrl(
                AnyoS3Buckets.CDN_BUCKET,
                filePath,
                this.therapistImageFile.type,
              ),
            );
            this.toastrService.showSuccess(
              `Uploading Therapist Profile Picture`,
            );
            await lastValueFrom(
              this.fileService.uploadFile(
                this.therapistImageFile,
                uploadUrl.url,
              ),
            );
            this.toastrService.showSuccess(
              `Uploading Therapist Profile Picture completed`,
            );

            req.picture = `https://cdn.anyo.app/${filePath}`;
            this.therapistService
              .updateTherapist(value.therapistId, req)
              .subscribe({
                next: () => {
                  this.toastrService.showSuccess(
                    'Therapist edited successfully',
                  );
                  this.router.navigate(['/therapist/manage']);
                },
                error: (error) => {
                  const errorBody = error.error as IAnyoError;
                  this.toastrService.showAnyoErrorToast(errorBody.description);
                },
              });
          }
          if (this.videoBioFile) {
            const uuid = uuidv4();
            const uuidWithoutHyphens = uuid.replace(/-/g, '');
            const extension = this.videoBioFile.name.split('.').pop();
            const videoBioFilePath = `therapist/${uuidWithoutHyphens}.${extension}`;
            const uploadUrl = await lastValueFrom(
              this.fileService.generateUploadUrl(
                AnyoS3Buckets.CDN_BUCKET,
                videoBioFilePath,
                this.videoBioFile.type,
              ),
            );
            this.toastrService.showSuccess(`Uploading Therapist Video Bio`);
            await lastValueFrom(
              this.fileService.uploadFile(this.videoBioFile, uploadUrl.url),
            );
            this.toastrService.showSuccess(
              `Uploading Therapist Video Bio completed`,
            );
            req.videoBio = videoBioFilePath;
            this.therapistService
              .updateTherapist(value.therapistId, req)
              .subscribe({
                next: () => {
                  this.toastrService.showSuccess(
                    'Therapist edited successfully',
                  );
                  this.router.navigate(['/therapist/manage']);
                },
                error: (error) => {
                  const errorBody = error.error as IAnyoError;
                  this.toastrService.showAnyoErrorToast(errorBody.description);
                },
              });
          }

          this.toastrService.showSuccess('Therapist added successfully');
          this.router.navigate(['/therapist/manage']);
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
        },
      });
    }
  }

  openFileExplorerPicture() {
    this.fileInput.nativeElement.click();
  }
  openFileExplorerVideoBio() {
    this.videoBioFileInput.nativeElement.click();
  }

  add(day: string) {
    this.addSlot = true;
    if (!this.viewForm) {
      let flag = true;
      if (day == 'monfri' || day == 'alldays') {
        this.extra.every((item, index) => {
          if (item['day'] == day) {
            this.extra[index].ifSlot2 = true;
            flag = false;
          }
          return flag;
        });
      } else {
        this.availability.every(
          (item: TherapistAvailability, index: number) => {
            if (item['day'] == day) {
              this.availability[index].ifSlot2 = true;
              flag = false;
            }
            return flag;
          },
        );
      }
    }
  }

  remove(day: string) {
    this.addSlot = false;
    if (!this.viewForm) {
      let flag = true;
      if (day == 'monfri' || day == 'alldays') {
        this.extra.every((item, index) => {
          if (item['day'] == day) {
            this.extra[index].ifSlot2 = false;
            flag = false;
          }
          return flag;
        });
      } else {
        this.availability.every(
          (item: TherapistAvailability, index: number) => {
            if (item['day'] == day) {
              this.availability[index].ifSlot2 = false;
              flag = false;
            }
            return flag;
          },
        );
      }
    }
  }

  reset() {
    this.availability.forEach((day: TherapistAvailability) => {
      day.slot1Start = '';
      (day.slot1End = ''), (day.ifSlot2 = false);
      day.slot2Start = '';
      day.slot2End = '';
    });
    this.extra.forEach((day: TherapistAvailability) => {
      day.slot1Start = '';
      (day.slot1End = ''), (day.ifSlot2 = false);
      day.slot2Start = '';
      day.slot2End = '';
    });
  }

  flexible(event: any) {
    this.therapist['isFlexibleWithTiming'] =
      !this.therapist['isFlexibleWithTiming'];
  }

  available(event: any) {
    this.therapist['isAvailable'] = !this.therapist['isAvailable'];
  }

  dialogShow(type: string) {
    if (type === 'thumbnail') {
      this.isThumbnail = true;
    } else {
      this.isThumbnail = false;
    }
    if (type === 'videoBio') {
      this.isVideoBio = true;
    } else {
      this.isVideoBio = false;
    }
    this.isAlert = !this.isAlert;
  }

  closeAlert() {
    this.isAlert = false;
    this.isThumbnail = false;
    this.isVideoBio = false;
  }

  disableTherapist() {
    let req = {
      status: Status.Inactive,
      isAvailable: false,
    };
    this.therapist.status = Status.Inactive;
    this.therapist.isAvailable = false;
    let _id = String(this.activatedRoute.snapshot.params['id']);
    this.therapistService.enableAndDisableTherapist(_id, req).subscribe({
      next: (value) => {
        this.toastrService.showSuccess('Therapist disabled successfully');
        this.router.navigate(['/therapist/manage']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
    this.isDisabled = true;
  }

  async deletePicture(url: string) {
    if (url) {
      this.therapist.picture = '';
      this.isAlert = false;
    }
  }

  onImageError() {
    this.therapist.picture = 'default';
  }

  enableTherapist() {
    let req = {
      status: Status.Active,
      isAvailable: true,
    };
    this.therapist.status = Status.Active;
    let _id = String(this.activatedRoute.snapshot.params['id']);
    this.therapistService.enableAndDisableTherapist(_id, req).subscribe({
      next: (value) => {
        this.toastrService.showSuccess('Therapist enabled successfully');
        this.router.navigate(['/therapist/manage']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
    this.isDisabled = false;
  }

  populate(_id: string) {
    this.therapistService.getTherapist(_id).subscribe({
      next: (value) => {
        console.log(value);
        this.viewForm = true;
        this.therapist = value.data;
        this.idealPrice = value.data.costPerSession?.amount.max || 0;
        this.selectedTherapistLevel = value.data.designationLevel;

        const therapyLevel = value.data.userInterestTopics?.map(
          (data) => data.key,
        );
        this.selectedUserInterests = therapyLevel;
        this.audio = value.data.preferredModesOfTherapy.includes('audio')
          ? true
          : false;
        this.video = value.data.preferredModesOfTherapy.includes('video')
          ? true
          : false;
        this.chat = value.data.preferredModesOfTherapy.includes('chat')
          ? true
          : false;
        this.availability = value.data.availability;
        const masterData: MasterData = value.masterdata;
        masterData.Qualifications.forEach((data) => {
          if (data.status == Status.Active) {
            let obj = {
              value: data.data.toString(),
              label: data.data,
            };
            this.qualifications.push(obj);
          }
        });

        masterData.AreasOfExpertise.forEach((data) => {
          if (data.status == Status.Active) {
            let obj = {
              value: data.data.toString(),
              label: data.data,
            };
            this.expertise.push(obj);
          }
        });
        masterData.Languages.forEach((data: any) => {
          if (data.status == Status.Active) {
            let obj = {
              value: data.m_id,
              label: data.data,
            };
            this.languages.push(obj);
          }
        });

        if (value.data.status == Status.Inactive) {
          this.isDisabled = true;
        }
        if (value.data.verticalIds && value.data.verticalIds.length) {
          this.expertiseLevelArray = [];
          value.data.verticalIds.forEach((value3) => {
            const item: ExpertLevelArray = {
              verticalId: value3,
              verticalName: '',
              concernsList: this.concernsMasterData
                .filter((value1) => value1.verticalId.includes(value3))
                .map((value2) => {
                  return {
                    ...value2,
                    disabled: value.data.concernIds.includes(value2.value),
                  };
                }),
              concerns: [],
            };
            item.concernsList.forEach((v1) => {
              const expertLevel = value.data.concernExpertLevelMap[v1.value];
              if (expertLevel) {
                const it = {
                  concernsId: v1.value,
                  concernName: v1.label,
                  expertLevelId: expertLevel.id,
                  expertLevel: '',
                };
                item.concerns.push(it);
              }
            });
            if (!item.concerns.length) {
              item.concerns.push({
                concernsId: '',
                concernName: '',
                expertLevelId: '',
                expertLevel: '',
              });
            }
            // this.verticalsMasterDataFiltered = this.verticalsMasterData.filter(
            //   (data) => data.value != value,
            // );
            this.expertiseLevelArray.push(item);
          });
          this.verticalsMasterDataFiltered = this.verticalsMasterData.map(
            (value1) => {
              return {
                ...value1,
                disabled: value.data.verticalIds.includes(value1.value),
              };
            },
          );
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  expertLevels: string[] = [];
  therapistExpertLevels: TherapistExpertLevel[] = [];
  selectedTherapistLevel: string = '';
  idealPrice = 0;
  rating = 0;
  userInterestTopics: CircleTopics[] = [];
  selectedUserInterests: string[] = [];

  async loadMasterData() {
    this.userInterestTopics = await this.circleService.getUserInterestsMeta();
    this.masterdataService.masterDataList().subscribe({
      next: (masterdata) => {
        if (masterdata.languages) {
          masterdata.languages.forEach((data: any) => {
            const select2Data = {
              label: data.data,
              value: data.m_id,
            };
            this.languages.push(select2Data);
          });
        }
        if (masterdata.areasOfExpertise) {
          masterdata.areasOfExpertise.forEach((data: any) => {
            const select2Data = {
              label: data.data,
              value: data.data,
            };
            this.expertise.push(select2Data);
          });
        }
        if (masterdata.qualifications) {
          masterdata.qualifications.forEach((data: any) => {
            const select2Data = {
              label: data.data,
              value: data.data,
            };
            this.qualifications.push(select2Data);
          });
        }
        if (masterdata.therapyVerticals) {
          masterdata.therapyVerticals.forEach((data: any) => {
            const select2Data = {
              label: data.data,
              value: data.m_id,
              disabled: false,
            };
            this.verticalsMasterData.push(select2Data);
            this.verticalsMasterDataFiltered.push(select2Data);
          });
        }
        if (masterdata.therapyConcerns) {
          masterdata.therapyConcerns.forEach((data: any) => {
            const select2Data = {
              verticalId: data.verticalId,
              label: data.data,
              value: data.m_id,
            };
            this.concernsMasterData.push(select2Data);
          });
        }
        if (masterdata.therapistExpertLevels) {
          masterdata.therapistExpertLevels.forEach((data: any) => {
            const select2Data = {
              label: data.data,
              value: data.m_id,
            };
            this.expertLevelMasterData.push(select2Data);
          });
        }

        this.therapistExpertLevels = masterdata.therapistExpertLevels;
        this.expertLevels = this.therapistExpertLevels.map(
          (value) => value.data,
        );
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  trimString(str: string): string {
    return str.trim();
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        await this.loadMasterData();
        if (this.activatedRoute.snapshot.params) {
          const value = this.activatedRoute.snapshot.params['id'];
          if (value) {
            this.populate(value);
          }
        }
      }
    });
    this.expertiseLevelArray.push({
      verticalId: '',
      verticalName: '',
      concernsList: [],
      concerns: [
        {
          concernsId: '',
          concernName: '',
          expertLevelId: '',
          expertLevel: '',
        },
      ],
    });
  }
  expertiseLevelArray: ExpertLevelArray[] = [];
  addVertical() {
    this.expertiseLevelArray.push({
      verticalId: '',
      verticalName: '',
      concernsList: [],
      concerns: [
        {
          concernsId: '',
          concernName: '',
          expertLevelId: '',
          expertLevel: '',
        },
      ],
    });
  }
  deleteVertical(i: number) {
    this.expertiseLevelArray.splice(i, 1);
    const selectedVerticalsIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.verticalsMasterDataFiltered.forEach((value) => {
      value.disabled = selectedVerticalsIds.includes(value.value);
    });
  }
  addConcern(i: number) {
    this.expertiseLevelArray[i].concerns.push({
      concernsId: '',
      concernName: '',
      expertLevelId: '',
      expertLevel: '',
    });
  }
  deleteConcern(i: number, j: number) {
    this.expertiseLevelArray[i].concerns.splice(j, 1);
    const selectedConcernsIds = this.expertiseLevelArray[i].concerns.map(
      (value) => value.concernsId,
    );
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) =>
        value.verticalId.includes(this.expertiseLevelArray[i].verticalId),
      )
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: selectedConcernsIds.includes(value.value),
        };
      });
  }
  verticalChange(i: number, $event: any) {
    this.expertiseLevelArray[i].verticalId = $event;
    const selectedVerticalsIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.verticalsMasterDataFiltered = this.verticalsMasterData.map((value) => {
      return {
        value: value.value,
        label: value.label,
        disabled: selectedVerticalsIds.includes(value.value),
      };
    });
    this.expertiseLevelArray[i].verticalName =
      this.verticalsMasterDataFiltered.find((value) => value.value == $event)
        ?.label || '';
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) => value.verticalId.includes($event))
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: false,
        };
      });
  }
  concernChange(i: number, j: number, $event: any) {
    this.expertiseLevelArray[i].concerns[j].concernsId = $event;
    this.expertiseLevelArray[i].concerns[j].concernName =
      this.expertiseLevelArray[i].concernsList.find(
        (value) => value.value == $event,
      )?.label || '';
    const selectedConcernsIds = this.expertiseLevelArray[i].concerns.map(
      (value) => value.concernsId,
    );
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) =>
        value.verticalId.includes(this.expertiseLevelArray[i].verticalId),
      )
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: selectedConcernsIds.includes(value.value),
        };
      });
  }
  expertLevelChange(i: number, j: number, $event: any) {
    this.expertiseLevelArray[i].concerns[j].expertLevelId = $event;
    this.expertiseLevelArray[i].concerns[j].expertLevel =
      this.expertLevelMasterData.find((value) => value.value == $event)
        ?.label || '';
  }
  verticalConcernMap: Record<
    string,
    {
      label: string;
      value: string;
    }[]
  > = {};
  expertLevelMasterData: {
    label: string;
    value: string;
  }[] = [];
  verticalsMasterData: {
    label: string;
    value: string;
  }[] = [];
  verticalsMasterDataFiltered: {
    label: string;
    value: string;
    disabled: boolean;
  }[] = [];
  concernsMasterData: {
    verticalId: string[];
    label: string;
    value: string;
  }[] = [];
  validateExpertLevelArray() {
    let isValid = true;
    if (this.expertiseLevelArray.length == 0) {
      isValid = false;
    }
    this.expertiseLevelArray.forEach((value) => {
      if (!value.verticalId) {
        isValid = false;
      }
      value.concerns.forEach((concern) => {
        if (!concern.concernsId || !concern.expertLevelId) {
          isValid = false;
        }
      });
    });

    return isValid;
  }
}
