import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkUtilsService {
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
  }

  headers: HttpHeaders = new HttpHeaders();

  private addToDefaultHeaders(headers?: HttpHeaders) {
    if (headers?.keys().length) {
      for (let i = 0; i < headers?.keys().length; i++) {
        this.headers = this.headers.set(
          headers?.keys()[i],
          headers?.get(headers?.keys()[i])!,
        );
      }
    }
  }

  get<T>(url: string, headers?: HttpHeaders) {
    this.addToDefaultHeaders(headers);
    return this.http.get(url, { headers: this.headers }) as Observable<T>;
  }

  post<T>(url: string, requestBody?: unknown, headers?: HttpHeaders) {
    this.addToDefaultHeaders(headers);
    return this.http.post(url, requestBody, {
      headers: this.headers,
    }) as Observable<T>;
  }
}

//TODO create separate handler for portal
const APP_BASE_ROUTE = '/app/';
const V2 = 'v2/';
const ADMIN_BASE_ROUTE = '/admin/';

export class URL_DICT {
  public static sessionExpertAddRemark =
    environment.apiBackendBaseUrl + '/admin/session/add/remark';
  public static getMasterData =
    environment.apiBackendBaseUrl + '/' + 'master-data';
  public static getUserInterestsMeta =
    environment.apiBackendBaseUrl + '/user-interests';
  public static getUserByEmail =
    environment.apiBackendBaseUrl + '/' + 'getUserByEmail';
  public static generateOtpUrl =
    environment.apiBackendBaseUrl + '/auth/generateotp';
  public static verifyOtpUrl =
    environment.apiBackendBaseUrl + '/auth/verifyotp';
  public static getListenerByEmail =
    environment.apiBackendBaseUrl + '/listener/getListenerByEmail';
  public static getPendingChatSummary =
    environment.apiBackendBaseUrl + '/chat/pending/summary';
  public static postChatSummary =
    environment.apiBackendBaseUrl + '/chat/summary';
  public static getChatSummary =
    environment.apiBackendBaseUrl + '/chat/completed/summary';
  public static acceptChat = environment.apiBackendBaseUrl + '/chat/acceptChat';
  public static listenerLastSeenUrl =
    environment.apiBackendBaseUrl + '/listener/stamp/';
  public static resetPassword =
    environment.apiBackendBaseUrl + '/auth/reset-password';
  public static partnerGrid =
    environment.apiBackendBaseUrl + '/admin/partner/grid';
  public static memberGrid =
    environment.apiBackendBaseUrl + '/admin/members/grid/';
  public static memberGridStats =
    environment.apiBackendBaseUrl + '/admin/members/grid/stats/';
  public static adminSessionGrid =
    environment.apiBackendBaseUrl + '/admin/sessions/grid';
  public static therapist = environment.apiBackendBaseUrl + '/admin/therapist/';
  public static listener = environment.apiBackendBaseUrl + '/admin/listener/';
  public static partner = environment.apiBackendBaseUrl + '/admin/partner/';
  public static member = environment.apiBackendBaseUrl + '/admin/member/';
  public static transferMember =
    environment.apiBackendBaseUrl + '/admin/member/transfer';
  public static subscription =
    environment.apiBackendBaseUrl + '/admin/subscription/';
  public static adminSessionFeedBack =
    environment.apiBackendBaseUrl + '/admin/session/feedback';
  public static supportPortal =
    environment.apiBackendBaseUrl + '/portal/therapist/';
  public static adminSearchUsers =
    environment.apiBackendBaseUrl + '/admin/users';
  public static getActiveTherapists =
    environment.apiBackendBaseUrl + APP_BASE_ROUTE + 'therapists';
  public static getAvailableSlots =
    environment.apiBackendBaseUrl + '/admin/therapist/slots/';
  public static adminSessionBooking =
    environment.apiBackendBaseUrl + '/admin/session/newbooking';
  public static adminGetUploadUrl =
    environment.apiBackendBaseUrl + '/admin/upload';
  public static adminGetNewContentId =
    environment.apiBackendBaseUrl + '/admin/content/newid';
  public static adminNewContentPostHandler =
    environment.apiBackendBaseUrl + '/admin/content';
  public static adminContentUrl =
    environment.apiBackendBaseUrl + '/admin/content';
  public static contentGrid =
    environment.apiBackendBaseUrl + '/admin/content/grid';
  public static adminRoutineUrl =
    environment.apiBackendBaseUrl + '/admin/routine';
  public static adminSessionUpdateUrl =
    environment.apiBackendBaseUrl + '/admin/session/update';
  public static adminSessionCancelUrl =
    environment.apiBackendBaseUrl + '/admin/session/cancel';
  public static adminSessionConfirmUrl =
    environment.apiBackendBaseUrl + '/admin/session/confirm';
  public static adminContentsByCategory =
    environment.apiBackendBaseUrl + '/admin/contentsByCategory';
  public static adminRoutineGridUrl =
    environment.apiBackendBaseUrl + '/admin/grid/routine/';
  public static adminRoutineEnableDisableUrl =
    environment.apiBackendBaseUrl + '/admin/routine/availability/';
  public static adminBytesCategoryUrl =
    environment.apiBackendBaseUrl + '/admin/bytesCategory/';
  public static adminBytesCategoriesGridUrl =
    environment.apiBackendBaseUrl + '/admin/bytesCategory/grid';
  public static adminAddNewBytesUrl =
    environment.apiBackendBaseUrl + '/admin/bytes/new';
  public static adminBytesGridUrl =
    environment.apiBackendBaseUrl + '/admin/bytes/grid';
  public static adminGetByteUrl =
    environment.apiBackendBaseUrl + '/admin/bytes/';
  public static adminUpdateByteUrl =
    environment.apiBackendBaseUrl + '/admin/bytes/';
  public static adminAddNewBytesBulkUrl =
    environment.apiBackendBaseUrl + '/admin/bytes/new/bulk';
  public static reportBugUrl =
    environment.apiBackendBaseUrl + APP_BASE_ROUTE + 'bug/report';
  public static adminCircles =
    environment.apiBackendBaseUrl + '/admin/circles/';
  public static adminPostsUrl =
    environment.apiBackendBaseUrl + '/admin/circles/post/create';
  public static postsMediaUploadUrl =
    environment.apiBackendBaseUrl + '/admin/circles/media/upload';
  public static adminCirclePostsGrid =
    environment.apiBackendBaseUrl + '/admin/circles/posts/grid';
  public static adminCirclePostReportsGrid =
    environment.apiBackendBaseUrl + '/admin/circles/post/reports/grid';
  public static adminCirclePostModeratorActions =
    environment.apiBackendBaseUrl + '/admin/circles/post/actions';
  public static adminCirclePostGet =
    environment.apiBackendBaseUrl + '/admin/circles/posts/';
  public static adminCirclesGetUrl =
    environment.apiBackendBaseUrl + '/admin/circles/masterdata/get';
  public static adminBulkSendCorporateCode =
    environment.apiBackendBaseUrl + '/admin/members/bulksend';
  public static adminBulkUploadMembers =
    environment.apiBackendBaseUrl + '/admin/members/bulkupload/';
  public static adminRolesAndPermissionTableUrl =
    environment.apiBackendBaseUrl + '/super-admin/user/roles';
  public static adminRolesAndPermissionsUrl =
    environment.apiBackendBaseUrl + '/super-admin/roles-permissions/get';
  public static adminAddRolesAndPermissionsUrl =
    environment.apiBackendBaseUrl + '/super-admin/roles-permissions/add';
  public static adminUserCreateUrl =
    environment.apiBackendBaseUrl + '/super-admin/create';
  public static adminEnableDisableUserInAllCollectionUrl =
    environment.apiBackendBaseUrl + '/super-admin/user/availability';
  public static adminGetTimeRanges =
    environment.apiBackendBaseUrl + '/admin/therapist/slot/time-ranges';
  public static adminMarkNotAvailableSlot =
    environment.apiBackendBaseUrl + '/admin/therapist/mark/not-available';
  public static getTherapistByEmailUrl =
    environment.apiBackendBaseUrl + '/admin/therapist/get/';
  public static addEvents = environment.apiBackendBaseUrl + '/admin/events';
  public static eventsGridUrl =
    environment.apiBackendBaseUrl + '/admin/events/grid';
  public static eventsGetUrl = environment.apiBackendBaseUrl + '/admin/events/';
  public static eventsUpdateUrl =
    environment.apiBackendBaseUrl + '/admin/events/update/';
  public static eventsRegistrationGridUrl =
    environment.apiBackendBaseUrl + '/admin/events/registrations/grid';
  public static talksAdminGridUrl =
    environment.apiBackendBaseUrl + '/admin/talks/grid';
  public static talkSetPlaybackUrl =
    environment.apiBackendBaseUrl + '/admin/talks/setSession';
  public static talksLogAdminGridUrl =
    environment.apiBackendBaseUrl + '/admin/talks/talkLogs/grid';
  public static fetchBroadCastDetailsTalks =
    environment.apiBackendBaseUrl + '/admin/talks/broadcastDetails';
  public static addParticipant =
    environment.apiBackendBaseUrl + '/app/talks/addParticipant';
  public static updateTalkNotificationDetails =
    environment.apiBackendBaseUrl + '/admin/talks/updateNotification';
  public static getTalkDetailsById =
    environment.apiBackendBaseUrl + '/admin/talks/';
  public static getPartnerByUser =
    environment.apiBackendBaseUrl + '/partner/getPartnerByUser';
  public static fetchPartnerMemberGrid =
    environment.apiBackendBaseUrl + '/partner/members/grid';
  public static partnerPortalAddMember =
    environment.apiBackendBaseUrl + '/partner/members/add';
  public static partnerPortalBulkAddMember =
    environment.apiBackendBaseUrl + '/partner/members/bulk/add/';
  public static partnerPortalBulkInviteMember =
    environment.apiBackendBaseUrl + '/partner/members/bulk/invite';
  public static partnerPortalDeleteMember =
    environment.apiBackendBaseUrl + '/partner/members/delete';
  public static partnerPortalResetPassword =
    environment.apiBackendBaseUrl + '/partner/reset-password';
  public static sessionStatsUrl =
    environment.apiBackendBaseUrl + '/admin/session/stats';
  public static userProfileSearchUrl =
    environment.apiBackendBaseUrl + '/admin/user/profile';
  public static createTherapyPlanUrl =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/add';
  public static fetchTherapyPlans =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/fetch';
  public static linkTherapyPlanUrl =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/link';
  static unLinkTherapyPlanUrl =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/un-link';
  public static adminTherapyPlansGrid =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/grid/all';
  public static adminTherapyPlanGetUrl =
    environment.apiBackendBaseUrl + '/admin/therapist/plan/';
  public static adminTherapyPlanUpdateUrl =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/';
  public static adminTherapyPlansSubcriptionGrid =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/subscription/grid';
  public static adminFetchPlanForConcerns =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/fetch/concern';
  public static adminActivateTherapyPlan =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/activate';
  public static adminExpertsGrid =
    environment.apiBackendBaseUrl + '/admin/experts/grid';
  public static adminExpertsTherapistListenerCreate =
    environment.apiBackendBaseUrl + '/admin/experts/add';
  public static adminExpertsGet =
    environment.apiBackendBaseUrl + '/admin/experts/';
  public static adminUniqueUserTherapySubs =
    environment.apiBackendBaseUrl + '/admin/therapy/plan/sub/unique/';
  public static adminAddExtraSessionsToPlanSubs =
    environment.apiBackendBaseUrl + '/admin/add/extra/session/plan/subs/';
  public static adminFetchPlanForUser =
    environment.apiBackendBaseUrl + '/admin/therapist/plans/user';
  public static adminAddUserFormAttributes =
    environment.apiBackendBaseUrl + '/admin/add/userformattributes';
  public static adminGetUserFormAttributes =
    environment.apiBackendBaseUrl + '/admin/get/userformattributes';
  public static adminUpdateUserFormAttributes =
    environment.apiBackendBaseUrl + '/admin/update/userformattributes';
  public static adminTherapistAVailabilityV2Url =
    environment.apiBackendBaseUrl +
    ADMIN_BASE_ROUTE +
    V2 +
    'therapist/availability';
  public static getClubPass =
    environment.apiBackendBaseUrl + '/admin/club-pass/get';
  public static adminExpertOnboardRequestGridUrl =
    environment.apiBackendBaseUrl + '/admin/expert-onboarding/grid';
  public static adminExpertOnboardRequestGetUrl =
    environment.apiBackendBaseUrl + '/admin/expert-onboarding/get/';
  public static adminExpertOnboardReqAddComments =
    environment.apiBackendBaseUrl + '/admin/expert/onboard/comments/';
  public static adminExpertOnboardReqStatusUpdate =
    environment.apiBackendBaseUrl + '/admin/expert/onboard/status/';
  public static adminExpertOnboardPdfDownLoadUr =
    environment.apiBackendBaseUrl + '/admin/expert/onboard/media/download';
}
