import { Injectable } from '@angular/core';
import Acknowledgement from 'projects/app-core/src/models/acknowledgement';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { CreateTherapyPlanRequest } from '../pages/therapyPlans/create-therapy-plan/create-therapy-plan.component';
import { TherapyPlansResponse } from '../pages/therapyPlans/therapy-plans-list/therapy-plans-list.component';
import { TherapyPlansAdminResponse } from '../models/TherapyPlansAdminResponse';
import { TherapyPlansGridRequest } from '../models/therapyPlansGridRequest';
import { TherapyPlansGridResponse } from '../models/therapyPlansGridResp';
import { TherapyPlans } from '../models/TherapyPlans';
import { TherapyPlanSubscriptionsGridRequest } from '../models/therapyPlanSubscribtionGridRequest';
import {
  TherapyPlansSubscriptionGridResponse,
  TherapyPlansSubscriptionSessionDetails,
} from '../models/therapyPlansSubcriptionGridResponse';
import { TherapyPlansAppResponse } from 'projects/app-core/src/app/models/TherapyPlansAppResponse';
import { UniqueTherapyPlanSubscriptionGridResponse } from '../models/uniquePlanSubscriptionForUsers';
import { ActiveTherapyPlansResponse } from 'projects/app-core/src/app/models/ActiveTherapyPlansResponse';
import { UniqueTherapyPlanSubscriptionForUsersReq } from '../models/uniqueTherapyPlanSubscription';
import { TherapistAppResponseV2 } from '../models/TherapistV2AppResponse';

export interface TherapyPlanUpdateRequest extends CreateTherapyPlanRequest {
  active: boolean;
}

export interface ExtraSessionForPlanSubscription {
  sessionDetails: Record<string, TherapyPlansSubscriptionSessionDetails>;
}

export interface FetchTherapistsRequest {
  therapistId?: string;
  verticalId?: string;
  concernId?: string;
  userId?: string;
  slug?: string;
}

export interface TransferTherapistInTherapyPlanSubscription {
  newTherapistId: string;
  planSubscriptionId: string;
}

@Injectable({
  providedIn: 'root',
})
export class TherapyPlansService {
  constructor(private api: NetworkUtilsService) {}

  public addTherapyPlan(request: CreateTherapyPlanRequest) {
    return this.api.post<Acknowledgement>(
      URL_DICT.createTherapyPlanUrl,
      request,
    );
  }
  public linkTherapyPlan(
    verticalId: string,
    concernIds: string[],
    planIds: string[],
  ) {
    return this.api.post<Acknowledgement>(URL_DICT.linkTherapyPlanUrl, {
      verticalId: verticalId,
      concernIds: concernIds,
      planIds: planIds,
    });
  }
  public unLinkTherapyPlan(
    verticalId: string,
    concernId: string,
    planId: string,
  ) {
    return this.api.post<Acknowledgement>(URL_DICT.unLinkTherapyPlanUrl, {
      verticalId: verticalId,
      concernId: concernId,
      planId: planId,
    });
  }
  public fetchAllPlans() {
    return this.api.get<{
      plansMapped: TherapyPlansResponse[];
      activePlans: TherapyPlansAdminResponse[];
    }>(URL_DICT.fetchTherapyPlans);
  }

  public fetchTherapyPlansGrid(gridRequest: TherapyPlansGridRequest) {
    return this.api.post<TherapyPlansGridResponse>(
      URL_DICT.adminTherapyPlansGrid,
      gridRequest,
    );
  }

  public getPlan(id: string) {
    return this.api.get<TherapyPlans>(URL_DICT.adminTherapyPlanGetUrl + id);
  }

  public updatePlan(id: string, data: TherapyPlanUpdateRequest) {
    return this.api.post(URL_DICT.adminTherapyPlanUpdateUrl + id, data);
  }

  public fetchTherapyPlansSubscription(
    gridRequest: TherapyPlanSubscriptionsGridRequest,
  ) {
    return this.api.post<TherapyPlansSubscriptionGridResponse>(
      URL_DICT.adminTherapyPlansSubcriptionGrid,
      gridRequest,
    );
  }

  public fetchActiveTherapyPlansForUser(userId: string) {
    const url = URL_DICT.adminFetchPlanForUser + '?userId=' + userId;
    return this.api.get<ActiveTherapyPlansResponse[]>(url);
  }

  public fetchPlansForConcernVertical(
    verticalId: string,
    concernId: string,
    therapistId: string,
    userId: string,
  ) {
    return this.api.post<TherapyPlansAppResponse[]>(
      URL_DICT.adminFetchPlanForConcerns,
      {
        verticalId: verticalId,
        concernId: concernId,
        userId: userId,
        therapistId: therapistId,
      },
    );
  }
  public activateAdminTherapySession(req: {
    planId: string;
    therapistId: string;
    userId: string;
    concernId: string;
    verticalId: string;
    paymentOrderId?: string;
  }) {
    return this.api.post<Acknowledgement>(
      URL_DICT.adminActivateTherapyPlan,
      req,
    );
  }

  public uniqueUserTherapySubslist(request: UniqueTherapyPlanSubscriptionForUsersReq) {
    return this.api.post<UniqueTherapyPlanSubscriptionGridResponse>(
      URL_DICT.adminUniqueUserTherapySubs,
      request
    );
  }

  public addExtraSessions(
    planSubscriptionId: string,
    request: ExtraSessionForPlanSubscription,
  ) {
    return this.api.post<ExtraSessionForPlanSubscription>(
      URL_DICT.adminAddExtraSessionsToPlanSubs + planSubscriptionId,
      request,
    );
  }

  public getTherapistsForTransfer(req : FetchTherapistsRequest){
    return this.api.post<TherapistAppResponseV2[]>(URL_DICT.adminTherapistsTransferGetUrl,req);
  }

  public saveTherapistsForTransfer(req : TransferTherapistInTherapyPlanSubscription){
    return this.api.post(URL_DICT.adminTherapistTransferPostUrl,req);
  }
}
