<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex flex-row justify-between items-center mb-3">
	<div class="flex flex-row md:gap-3 items-center">
		<div class="flex flex-row flex-nowrap md:gap-3 items-center">
			<h1 class="headeradminui-text md:break-keep">
				Manage Events Bidding
			</h1>
		</div>
	</div>
	<div [routerLink]="['/events/events-bidding/curd']"
		class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1">
		<div>
			<img class="add-icon"
				src="../../../../assets/icons/add-icon.svg"
				alt="img">
		</div>
		<div>
			<span class>Add Events Biddings</span>
		</div>
	</div>
</div>
<div nz-row
	nzJustify="end"
	nzAlign="middle">
	<div nz-col
		class="p-1">
		<nz-input-group [nzAddOnAfter]="suffixButton"
			nzSearch
			nzSize="large">
			<input [(ngModel)]="globalSearchInput"
				type="text"
				nz-input
				placeholder="Search for name">
		</nz-input-group>
	</div>
</div>
<ng-template #suffixButton>
	<button (click)="searchGlobal()"
		nz-button
		nzType="primary"
		nzSize="small"
		nzSearch>
		Search
	</button>
</ng-template>
<ng-template #tableHead>
	<div nz-row
		nzJustify="space-between">
		<div nz-col>
			<span class="font-bold">Events</span>
		</div>
		<div nz-col
			class="space-x-3">
			<nz-switch [(ngModel)]="testEvent"
				(ngModelChange)="testEventChangeFn($event)"
				nzCheckedChildren="Hide Test Events"
				nzUnCheckedChildren="Show Test Events"></nz-switch>
			<button (click)="resetAllFilter()"
				class="btn-color"
				nz-button
				nzSize="small"
				nzType="primary">
				Clear filter
			</button>
		</div>
	</div>
</ng-template>
<nz-spin [nzSpinning]="loading">
	<nz-table #basicTable
		[nzData]="eventBidsListDate.data"
		[nzTitle]="tableHead"
		[nzScroll]="{ y: '500px' }"
		[nzShowPagination]="true"
		[nzTotal]="eventBidsListDate.totalRecords!"
		[nzPageSize]="eventBidsListDate.pageSize!"
		[nzBordered]="true"
		[nzShowSizeChanger]="true"
		[nzPageIndex]="eventBidsListDate.pageNum!"
		(nzPageIndexChange)="nzPageIndexChange($event)"
		(nzPageSizeChange)="nzPageSizeChange($event)"
		class="mt-5"
		nzTableLayout="fixed"
		nzFrontPagination="false">
		<thead>
			<tr>
				<th>Name</th>
				<th>Location</th>
				<th>
					Event Date
					<nz-filter-trigger [nzActive]="true"
						[nzDropdownMenu]="eventsDateFilterMenu">
						<span nz-icon
							nzTheme="outline"
							nzType="schedule"></span>
					</nz-filter-trigger>
				</th>
				<th>
					Bidding End Date
					<nz-filter-trigger [nzActive]="true"
						[nzDropdownMenu]="bidEndDateFilterMenu">
						<span nz-icon
							nzTheme="outline"
							nzType="schedule"></span>
					</nz-filter-trigger>
				</th>
				<th [nzWidth]="'100px'">Max Bid Amount</th>
				<th [nzWidth]="'140px'">Audience Type</th>
				<th [nzWidth]="'160px'">
					Status
					<nz-filter-trigger [nzActive]="true"
						[nzDropdownMenu]="rewardSelectionMenu">
						<span nz-icon
							nzType="filter"></span>
					</nz-filter-trigger>
				</th>
				<th [nzWidth]="'80px'">Total Host</th>
				<th [nzWidth]="'80px'">Bids Received</th>
				<th [nzWidth]="'80px'">Total Accepted Bids</th>
				<th>Actions</th>
			</tr>
		</thead>
		<tbody class="text-sm">
			<tr *ngFor="let data of basicTable.data">
				<td>
					{{ data.eventName }}
				</td>
				<td>{{ data.location }}</td>
				<td>
					{{ formatUnixToDate(data.eventDate) }}
				</td>
				<td>
					{{ formatUnixToDate(data.biddingEndDate) }}
				</td>
				<td>
					{{ data.initialPayment }}
				</td>
				<td>
					<ng-container *ngFor="let type of data.audienceType">
						<nz-tag [nzColor]="'blue'"
							class="inline-block w-auto mt-1">
							{{ type }}
						</nz-tag>
					</ng-container>
				</td>
				<td>
					<nz-tag [nzColor]="data.eventStatus === eventStatusEnum.OPEN_FOR_BIDDING ? 'green' : 'red'">
						{{ data.eventStatus === eventStatusEnum.OPEN_FOR_BIDDING ? "OPEN FOR BIDDING" : "CLOSED" }}
					</nz-tag>
				</td>
				<td>
					{{ data.maxExpert }}
				</td>
				<td>
					{{ data.totalBidsReceived }}
				</td>
				<td>
					{{ data.totalBidsAccepted }}
				</td>
				<td>
					<div class="flex flex-col space-y-3">
						<button (click)="openDrawer(data.id , data.maxExpert === data.totalBidsAccepted - 1)"
							nz-button
							nzType="link">
							<i nz-icon
								nzType="eye"></i>
							View Bids
						</button>
						<button *ngIf="data.totalBidsReceived === 0 && data.eventStatus !== eventStatusEnum.CLOSED"
							[routerLink]="['/events/events-bidding/curd/' + data.id]"
							nz-button
							nzType="link">
							<i nz-icon
								nzType="edit"></i>
							Edit
						</button>
						<button *ngIf="data.eventStatus === eventStatusEnum.CLOSED"
							[routerLink]="['/events/add/'+data.id]"
							nz-button
							nzType="link">
							<i nz-icon
								nzType="plus"></i>
							Create Event
						</button>
						<button *ngIf="data.eventStatus !== eventStatusEnum.CLOSED"
							(click)="openCloseBidModal(data.id)"
							nz-button
							nzType="link">
							<i nz-icon
								nzType="stop"></i>
							Close Bid
						</button>
					</div>
				</td>










			</tr>
		</tbody>
	</nz-table>
</nz-spin>
<nz-drawer [nzVisible]="isDrawerVisible"
	[nzPlacement]="'bottom'"
	[nzHeight]="'500px'"
	[nzClosable]="true"
	(nzOnClose)="closeDrawer()"
	nzTitle="Biddings">
	<ng-container *nzDrawerContent>
		<nz-spin [nzSpinning]="logLoading"
			nzSize="small">
			<nz-table [nzBordered]="true"
				[nzData]="bidsLogData"
				[nzSize]="'middle'"
				[nzShowPagination]="false"
				[nzScroll]="{ x: '100%' }">
				<thead>
					<tr>
						<th>Expert Name</th>
						<th>
							Bid Amount
							<nz-filter-trigger [nzActive]="true"
								[nzDropdownMenu]="bidAmountSortMenu">
								<span nz-icon
									nzType="filter"></span>
							</nz-filter-trigger>
						</th>
						<th>
							Experience / Qualification
						</th>
						<th>Proposal Message</th>
						<th>
							Status
							<nz-filter-trigger [nzActive]="true"
								[nzDropdownMenu]="bidsStatusFilterMenu">
								<span nz-icon
									nzType="filter"></span>
							</nz-filter-trigger>
						</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let data of bidsLogData">
						<td>
							{{ data.expertName }}
						</td>
						<td>
							{{ data.bidAmount }}
						</td>
						<td>
							{{ data.expertQualification}}
						</td>
						<td>
							{{ data.proposalMessage }}
						</td>
						<td>
							<nz-tag [nzColor]="'green'">{{ data.status }}</nz-tag>
						</td>
						<td>
							<div *ngIf="user?.customClaims?.additionalPermissions?.includes(permission.AdminApproveOrRejectEventBid); else noAccess">
								<ng-container *ngIf="data.status === 'IN_REVIEW'; else statusText">
									<button (click)="onOpenModal(data.id)"
										nz-button
										nzType="primary"
										nzSize="small"
										class="m-3">
										Accept
									</button>
									<button (click)="onOpenModal(data.id, false)"
										nz-button
										nzType="default"
										nzSize="small"
										class="m-3"
										nzDanger>
										Reject
									</button>
								</ng-container>
								<ng-template #statusText>
									<span *ngIf="data.status === 'ACCEPTED'"
										class="text-green-500">
										You already accepted
									</span>
									<span *ngIf="data.status === 'REJECTED'"
										class="text-red-500">
										You already rejected
									</span>
								</ng-template>
							</div>
							<ng-template #noAccess>
								<span class="text-gray-500">
									You don't have access
								</span>
							</ng-template>
						</td>


					</tr>
				</tbody>
			</nz-table>
		</nz-spin>
	</ng-container>
</nz-drawer>
<nz-dropdown-menu #rewardSelectionMenu="nzDropdownMenu">
	<div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
		<div nz-row>
			<div nz-col>
				<span class="font-bold">Select Reward Type</span>
				<nz-divider></nz-divider>
				<nz-radio-group [(ngModel)]="selectStatus">
					<div *ngFor="let reward of statusFilter"
						nz-row>
						<div nz-col>
							<label [nzValue]="reward"
								nz-radio>
								{{ reward }}
							</label>
						</div>
					</div>
				</nz-radio-group>
			</div>
		</div>
		<div nz-row
			nzJustify="end">
			<div nz-col>
				<button (click)="statusFilterFun()"
					class="m-1"
					nz-button
					nzType="primary">
					Ok
				</button>
			</div>
			<div nz-col>
				<button (click)="resetStatusFilterFun()"
					class="m-1"
					nz-button
					nzType="default">
					Reset
				</button>
			</div>
		</div>
	</div>
</nz-dropdown-menu>
<nz-dropdown-menu #eventsDateFilterMenu="nzDropdownMenu">
	<div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
		<div class="ng-row">
			<nz-range-picker [nzFormat]="dateFormat"
				[(ngModel)]="dateRange"
				(ngModelChange)="onEventsFilterDateChange($event)"></nz-range-picker>
		</div>
		<div nz-row
			class="mt-5 gap-4"
			nzJustify="end">
			<div nz-col>
				<button (click)="dateFilterFn()"
					class="m-1"
					nz-button
					nzType="primary"
					class="btn-color">
					Ok
				</button>
			</div>
			<div nz-col>
				<button (click)="resetFilter()"
					class="m-1"
					nz-button
					nzType="primary"
					class="btn-color">
					Reset
				</button>
			</div>
		</div>
	</div>
</nz-dropdown-menu>
<nz-dropdown-menu #bidAmountSortMenu="nzDropdownMenu">
	<div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
		<div nz-row>
			<div nz-col>
				<span class="font-bold">
					Select Sort Direction
				</span>
				<nz-divider></nz-divider>
				<nz-radio-group [(ngModel)]="amountSort">
					<div *ngFor="let sort of sortFilter"
						nz-row>
						<div nz-col>
							<label [nzValue]="sort"
								nz-radio>
								{{ sort }}
							</label>
						</div>
					</div>
				</nz-radio-group>
			</div>
		</div>
		<div nz-row
			nzJustify="end">
			<div nz-col>
				<button (click)="fetchLogs(expertBidId)"
					class="m-1"
					nz-button
					nzType="primary">
					Ok
				</button>
			</div>
			<div nz-col>
				<button (click)="resetAmountSort()"
					class="m-1"
					nz-button
					nzType="default">
					Reset
				</button>
			</div>
		</div>
	</div>
</nz-dropdown-menu>
<nz-dropdown-menu #bidsStatusFilterMenu="nzDropdownMenu">
	<div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
		<div nz-row>
			<div nz-col>
				<span class="font-bold">Select Status</span>
				<nz-divider></nz-divider>
				<nz-radio-group [(ngModel)]="bidLogStatusFilter">
					<div *ngFor="let status of eventBidLogStatus"
						nz-row>
						<div nz-col>
							<label [nzValue]="status"
								nz-radio>
								{{ status }}
							</label>
						</div>
					</div>
				</nz-radio-group>
			</div>
		</div>
		<div nz-row
			nzJustify="end">
			<div nz-col>
				<button (click)="fetchLogs(expertBidId)"
					class="m-1"
					nz-button
					nzType="primary">
					Ok
				</button>
			</div>
			<div nz-col>
				<button (click)="resetStatusFilter()"
					class="m-1"
					nz-button
					nzType="default">
					Reset
				</button>
			</div>
		</div>
	</div>
</nz-dropdown-menu>
<nz-dropdown-menu #bidEndDateFilterMenu="nzDropdownMenu">
	<div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
		<div class="ng-row">
			<nz-range-picker [nzFormat]="dateFormat"
				[(ngModel)]="eventsBidEndFilterDate"
				(ngModelChange)="onBidEndDateChange($event)"></nz-range-picker>
		</div>
		<div nz-row
			class="mt-5 gap-4"
			nzJustify="end">
			<div nz-col>
				<button (click)="bidEndDateFilterFn()"
					class="m-1"
					nz-button
					nzType="primary"
					class="btn-color">
					Ok
				</button>
			</div>
			<div nz-col>
				<button (click)="resetBidEndDateFilterFn()"
					class="m-1"
					nz-button
					nzType="primary"
					class="btn-color">
					Reset
				</button>
			</div>
		</div>
	</div>
</nz-dropdown-menu>













<nz-modal [(nzVisible)]="isModalVisible"
	[nzFooter]="null"
	(nzOnCancel)="onCloseModal()"
	nzTitle="Confirmation">
	<ng-container *nzModalContent>
		<div class="flex flex-col justify-center items-center w-full space-y-4 mb-5">
			<p>
				Are you sure you want to {{ isAcceptModalVisible ? 'accept' : 'reject' }} the bid?
			</p>
			<p *ngIf="isAcceptModalVisible && totalHostReached">
				By accepting this, you automatically reject the other bids.
			</p>
		</div>
		<div class="modal-footer flex justify-end space-x-3">
			<button (click)="onCloseModal()"
				nz-button
				nzType="default">
				Cancel
			</button>
			<button (click)="onConfirm()"
				nz-button
				nzType="primary">
				{{ isAcceptModalVisible ? 'Accept' : 'Reject' }}
			</button>
		</div>
	</ng-container>
</nz-modal>
<nz-modal [(nzVisible)]="isCloseBidModalVisible"
	[nzFooter]="null"
	(nzOnCancel)="closeCloseBidModal()"
	nzTitle="Confirmation">
	<ng-container *nzModalContent>
		<div class="flex flex-col justify-center items-center w-full space-y-4 mb-5">
			<p>
				Are you sure you want to close the bid?
			</p>
		</div>
		<div class="modal-footer flex justify-end space-x-3">
			<button (click)="closeCloseBidModal()"
				nz-button
				nzType="default">
				Cancel
			</button>
			<button (click)="closeBid()"
				nz-button
				nzType="primary">
				Ok
			</button>
		</div>
	</ng-container>
</nz-modal>
