import { Component } from '@angular/core';
import { ExpertsService } from '../../../services/experts.service';
import {
  ExpertsFilter,
  ExpertsGridRequest,
  ExpertsSearch,
  ExpertsSort,
} from '../../../models/expertsGridRequest';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { ExpertGridResponseData } from '../../../models/expertGridResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-manage-experts',
  templateUrl: './manage-experts.component.html',
  styleUrl: './manage-experts.component.scss',
})
export class ManageExpertsComponent {
  showTestCount = false;
  constructor(
    public experts: ExpertsService,
    public toastr: ToastService,
    public auth: AuthService,
    public utils: UtilsService,
  ) {}
  gridRequest!: ExpertsGridRequest;
  tableData: ExpertGridResponseData[] = [];
  pageSize = 10;
  currentPage = 1;
  totalRecords = 0;
  expertsSpinner = false;
  pageSizeOptions = [10, 20];
  expertNameColumnSearch = '';
  expertEmailColumnSearch = '';
  globalSearchInput = '';
  rolesCheckBoxOptions: CheckBoxOptions[] = [];
  interestCheckBoxOptions: CheckBoxOptions[] = [];
  statusCheckBoxOptions: CheckBoxOptions[] = [];

  statusFilter(status: any[]) {
    if (status && status.length) {
      if (!this.gridRequest!.filters) {
        this.gridRequest!.filters = {
          [ExpertsFilter.ROLES]: [],
          [ExpertsFilter.USER_INTEREST]: [],
          [ExpertsFilter.NAME]: [],
          [ExpertsFilter.STATUS]: [],
          [ExpertsFilter.TEST]: []
        };
      }
      this.gridRequest!.filters!.STATUS = status;
    }
  }

  showTestExperts(){
      if(!this.gridRequest){
        this.gridRequest = {
          page: this.currentPage,
          pageSize: this.pageSize,
          globalSearch: [],
          export: false
        }
      }
      if (!this.gridRequest.filters) {
        this.gridRequest.filters = {
          NAME: undefined,
          ROLES: undefined,
          USER_INTEREST: undefined,
          STATUS: undefined,
          TEST: undefined
        };
      }
      this.gridRequest.filters!.TEST = this.showTestCount ? ['true'] : ['false']
      this.getExperts()
    }

  expertNameSearchFn() {
    this.expertNameColumnSearch = this.expertNameColumnSearch.trim();
    if (this.expertNameColumnSearch.length) {
      if (!this.gridRequest!.searchQuery) {
        this.gridRequest!.searchQuery = {
          [ExpertsSearch.NAME]: '',
          [ExpertsSearch.EMAIL]: '',
        };
      }
      this.gridRequest!.searchQuery!.NAME = this.expertNameColumnSearch;
      this.getExperts();
    }
  }
  expertEmailSearchFn() {
    this.expertEmailColumnSearch = this.expertEmailColumnSearch.trim();
    if (this.expertEmailColumnSearch.length) {
      if (!this.gridRequest!.searchQuery) {
        this.gridRequest!.searchQuery = {
          [ExpertsSearch.NAME]: '',
          [ExpertsSearch.EMAIL]: '',
        };
      }
      this.gridRequest!.searchQuery!.EMAIL = this.expertEmailColumnSearch;
      this.getExperts();
    }
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.gridRequest?.globalSearch?.push(this.globalSearchInput.trim());
      this.getExperts();
    }
  }

  getExperts() {
    this.expertsSpinner = true;
    this.experts.listExperts(this.gridRequest).subscribe({
      next: (value) => {
        value.data.forEach((data) => {
          data.rolesWithColor = data.roles.map((data) => {
            return {
              data: data,
              color: this.utils.getRandomColor(),
            };
          });
          data.interestWithColor = data.userInterestsTags.map((data) => {
            return {
              data: data,
              color: this.utils.getRandomColor(),
            };
          });
        });
        this.rolesCheckBoxOptions = value.filterOptions?.roles.map((data) => {
          if (this.gridRequest.filters?.ROLES?.includes(data)) {
            return {
              label: data,
              value: data,
              checked: true,
            };
          } else {
            return {
              label: data,
              value: data,
              checked: false,
            };
          }
        });
        this.interestCheckBoxOptions = value.filterOptions?.interests.map(
          (data) => {
            if (this.gridRequest.filters?.USER_INTEREST?.includes(data)) {
              return {
                label: data,
                value: data,
                checked: true,
              };
            } else {
              return {
                label: data,
                value: data,
                checked: false,
              };
            }
          },
        );
        this.statusCheckBoxOptions = [
          {
            label: "Active",
            value: "true",
            checked: false
          },
          {
            label: "Inactive",
            value: "false",
            checked: false
          }
        ];
        
        this.statusCheckBoxOptions.forEach((data) => {
          if(this.gridRequest.filters?.STATUS?.includes(data.value)){
            data.checked = true;
          }else{
            data.checked = false;
          }
        })

        this.tableData = value.data;
        this.totalRecords = value.totalRecords;
        this.expertsSpinner = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastr.showAnyoErrorToast(e.description);
        this.expertsSpinner = false;
      },
    });
  }

  rolesFilter(role: string[]) {
    if (role && role.length) {
      if (!this.gridRequest.filters) {
        this.gridRequest.filters = {
          [ExpertsFilter.ROLES]: [],
          [ExpertsFilter.USER_INTEREST]: [],
          [ExpertsFilter.NAME]: [],
          [ExpertsFilter.STATUS]: [],
          [ExpertsFilter.TEST]: []
        };
      }
      this.gridRequest!.filters!.ROLES = role;
    }
  }

  userInterestFilter(interests: string[]) {
    if (interests && interests.length) {
      if (!this.gridRequest.filters) {
        this.gridRequest.filters = {
          [ExpertsFilter.ROLES]: [],
          [ExpertsFilter.USER_INTEREST]: [],
          [ExpertsFilter.NAME]: [],
          [ExpertsFilter.STATUS]: [],
          [ExpertsFilter.TEST]: []
        };
      }
      this.gridRequest!.filters!.USER_INTEREST = interests;
    }
  }

  resetFilter() {
    this.gridRequest = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: {
        [ExpertsSort.CREATED_AT]: AnyoSortDirection.ASC,
      },
      to: undefined,
      page: this.currentPage,
      pageSize: this.pageSize,
    };
    if (!this.gridRequest.filters) {
      this.gridRequest.filters = {
        NAME: undefined,
        ROLES: undefined,
        USER_INTEREST: undefined,
        STATUS: undefined,
        TEST: undefined
      };
    }
    this.gridRequest.filters.TEST = ['false']
    this.globalSearchInput = '';
    this.expertEmailColumnSearch = '';
    this.expertNameColumnSearch = '';
    this.getExperts();
  }

  nzPageIndexChange($event: number) {
    this.gridRequest.page = $event;
    this.getExperts();
  }

  nzPageSizeChange($event: number) {
    this.gridRequest.pageSize = $event;
    this.getExperts();
  }

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.gridRequest = {
          page: 1,
          pageSize: this.pageSize,
          export: false,
          globalSearch: [],
        };
        if (!this.gridRequest.filters) {
          this.gridRequest.filters = {
            NAME: undefined,
            ROLES: undefined,
            USER_INTEREST: undefined,
            STATUS: undefined,
            TEST: undefined
          };
        }
        this.gridRequest.filters!.TEST = [this.showTestCount ? 'true' : 'false']
        this.getExperts();
      }
    });
  }
}
