<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
	<div class="flex flex-wrap w-1/4 justify-start">
		<img [routerLink]="['/events']"
			class="left-arrow clickable"
			src="../../../../assets/icons/left-arrow.svg"
			alt="event">
		<h1 *ngIf="viewMode"
			class="headeradminui-text pl-2">
			View {{recurring ? "Class" : "Events"}}
		</h1>
		<h1 *ngIf="!viewMode"
			class="headeradminui-text pl-2">
			Edit {{recurring ? "Class" : "Events"}}
		</h1>
	</div>
</div>
<nz-spin [nzSpinning]="pageLoading">
	<nz-card>
		<div nz-row
			nzJustify="end">
			<div nz-col>
				<button (click)="editForm()"
					nz-button
					nzType="primary">
					Edit {{recurring ? "Class" : "Events"}}
				</button>
			</div>
		</div>
		<form [formGroup]="validateForm"
			nz-form>
			<div nz-row
				nzGutter="16">
				<div nz-col
					nzSpan="16">
					<nz-form-item>
						<nz-form-label nzFor="title"
							nzRequired
							nzSpan="24">
							Title
						</nz-form-label>
						<nz-form-control nzErrorTip="Please input Title"
							nzSpan="24">
							<input formControlName="eventName"
								id="title"
								nz-input
								placeholder="Title">
						</nz-form-control>
					</nz-form-item>
				</div>
				<div nz-col
					nzSpan="4">
					<nz-form-item>
						<nz-form-label nzRequired
							nzSpan="24">
							Category
						</nz-form-label>
						<nz-form-control>
							<nz-select formControlName="selectedCategory"
								id="eventCategory"
								nzAllowClear
								nzPlaceHolder="Select a Event Category"
								nzShowSearch>
								<nz-option *ngFor="let category of eventCategories"
									[nzLabel]="category.label"
									[nzValue]="category.value"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div nz-col
					nzSpan="4">
					<nz-form-item>
						<nz-form-label nzFor="eventDuration"
							nzRequired
							nzSpan="24">
							Duration in Minutes
						</nz-form-label>
						<nz-form-control nzSpan="24">
							<input formControlName="eventDuration"
								nz-input
								placeholder="Duration in Minutes"
								type="number">
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
			<div class="mt-5"
				nz-row
				nzGutter="10">
				<div nz-col
					nzSpan="12">
					<nz-form-item>
						<nz-form-label nzFor="eventDescription"
							nzRequired
							nzSpan="24">
							Description
						</nz-form-label>
						<nz-form-control nzSpan="24">
							<textarea formControlName="eventDescription"
								id="eventDescription"
								nz-input
								placeholder="Event Description"
								rows="10"></textarea>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div *ngIf="!recurring"
					nz-col
					nzSpan="12">
					<nz-form-item>
						<nz-form-label nzFor="keyPoints"
							nzSpan="24">
							Key Points
						</nz-form-label>
						<nz-form-control nzSpan="24">
							<textarea formControlName="keyPoints"
								id="keyPoints"
								nz-input
								placeholder="Key Points"
								rows="10"></textarea>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
			<div class="mt-5"
				nz-row
				nzGutter="16">
				<div nz-col>
					<nz-form-item>
						<nz-form-label nzFor="eventDate"
							nzRequired
							nzSpan="24">
							Event Date
						</nz-form-label>
						<nz-form-control>
							<nz-date-picker [nzFormat]="dateTimeFormat"
								formControlName="eventDate"
								id="eventDate"
								nzShowTime></nz-date-picker>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div *ngIf="recurring"
					nz-col>
					<nz-form-item>
						<nz-form-label nzFor="eventEndDate"
							nzRequired
							nzSpan="24">
							Event End Date
						</nz-form-label>
						<nz-form-control>
							<nz-date-picker [nzFormat]="dateTimeFormat"
								[nzDisabledDate]="disableEndDate"
								formControlName="eventEndDate"
								id="eventEndDate"></nz-date-picker>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div nz-col>
					<nz-form-item>
						<nz-form-label nzFor="visibleTime"
							nzRequired
							nzSpan="24">
							Visible From Start of Date
						</nz-form-label>
						<nz-form-control>
							<nz-date-picker [nzFormat]="dateFormat"
								formControlName="visibleTime"
								id="visibleTime"
								nzShowTime></nz-date-picker>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div *ngIf="!recurring"
					nz-col>
					<nz-form-item>
						<nz-form-label nzFor="cutOffTime"
							nzRequired
							nzSpan="24">
							Registration Cut Off By the end of Selected Date
						</nz-form-label>
						<nz-form-control>
							<nz-date-picker [nzFormat]="dateFormat"
								formControlName="registrationCutOff"
								id="cutOffTime"></nz-date-picker>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div nz-col
					nzSpan="4">
					<nz-form-item>
						<nz-form-label nzFor="maximumRegistrations"
							nzRequired
							nzSpan="24">
							Max Registration Count
						</nz-form-label>
						<nz-form-control nzSpan="24">
							<input formControlName="maximumRegistrations"
								nz-input
								placeholder="Max Registration Count"
								type="number">
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
			<nz-divider></nz-divider>
			<div *ngIf="recurring"
				nz-row
				nzGutter="16">
				<div nz-col
					nzSpan="12">
					<nz-form-label nzRequired>
						RRText (Please generate from
						<a href="https://icalendar.org/rrule-tool.html"
							target="_blank"
							class="text-blue-600 pl-1">
							https://icalendar.org/rrule-tool.html
						</a>
						)
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [disabled]="viewMode"
							[(ngModel)]="rrText"
							[ngModelOptions]="{ standalone: true }"
							nz-input
							placeholder="RRText"
							type="text">
					</nz-form-control>
				</div>
				<div nz-col
					nzSpan="12">
					<nz-form-label nzRequired>
						Cron Text (Please generate from
						<a href="https://crontab.guru/#"
							target="_blank"
							class="text-blue-600 pl-1">
							https://crontab.guru/#
						</a>
						)
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [disabled]="viewMode"
							[(ngModel)]="cronText"
							[ngModelOptions]="{ standalone: true }"
							nz-input
							placeholder="CronText"
							type="text">
					</nz-form-control>
				</div>
			</div>
			<div *ngIf="recurring"
				nz-row
				nzGutter="16">
				<div nz-col
					nzSpan="12">
					<nz-form-label nzRequired>
						Recurring Frequency Text ( This Text will display in the app to the users )
					</nz-form-label>
					<nz-form-control>
						<input [disabled]="viewMode"
							[(ngModel)]="recurringFrequencyText"
							[ngModelOptions]="{ standalone: true }"
							nz-input
							placeholder="Every Monday or Every Wednesday,Friday"
							type="text">
					</nz-form-control>
				</div>
			</div>
			<nz-divider *ngIf="recurring"></nz-divider>
			<div class="mt-5"
				nz-row
				nzGutter="16">
				<div nz-col
					nzSpan="12">
					<nz-form-item>
						<nz-form-label nzFor="language"
							nzRequired>
							Languages
						</nz-form-label>
						<nz-form-control nzSpan="24">
							<nz-select formControlName="selectedLanguages"
								id="language"
								nzAllowClear
								nzMode="tags"
								nzPlaceHolder="Languages"
								nzShowSearch>
								<nz-option *ngFor="let language of languages"
									[nzLabel]="language"
									[nzValue]="language"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div nz-col
					nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>Event Payment Types</nz-form-label>
						<nz-form-control [nzSpan]="24">
							<nz-select (ngModelChange)="IsFree($event)"
								formControlName="eventPaymentType"
								id="eventType">
								<nz-option *ngFor="let type of eventPaymentTypes"
									[nzLabel]="type"
									[nzValue]="type"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
					<div class="mt-2">
						<ul>
							<li>
								<span nz-typography>
									<strong>Free</strong>
								</span>
								- Free for all
                Customers
							</li>
							<li>
								<b>Paid</b>
								- Paid for all Customers
							</li>
							<li>
								<b>Retail Paid</b>
								- Free for Corporate users, Paid for retail
                users
							</li>
							<li>
								<b>Corporate Paid</b>
								- Free for Retail users, Paid for
                Corporate users
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div *ngIf="clubPasses!.length > 0 && validateForm.get('eventPaymentType')?.value !== 'Free'"
				class="my-5"
				nz-row
				nzGutter="16">
				<div nz-col
					nzSpan="12">
					<nz-form-item>
						<nz-form-label nzFor="clubPass">Club Passes</nz-form-label>
						<nz-form-control nzSpan="24">
							<nz-select formControlName="applicableClubPasses"
								id="clubPass"
								nzAllowClear
								nzMode="tags"
								nzPlaceHolder="Select a Club Pass"
								nzShowSearch>
								<nz-option *ngFor="let clubPass of clubPasses"
									[nzLabel]="clubPass.passName"
									[nzValue]="clubPass">
								</nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
		</form>
		<div nz-row>
			<div nz-col>
				<nz-switch [(ngModel)]="corporateOnlyEvent"
					nzCheckedChildren="Corporate Only"
					nzUnCheckedChildren="All Customers"></nz-switch>
			</div>
		</div>
		<div nz-row>
			<div nz-col
				nzSpan="12"
				class="mt-2">
				<nz-select [(ngModel)]="selectedPartners"
					class="min-w-[400px]"
					nzShowSearch="true"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option *ngFor="let partner of partners"
						[nzLabel]="partner.partner"
						[nzValue]="partner.id"></nz-option>
				</nz-select>
			</div>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Venue Details"></nz-divider>
		<div nz-row>
			<div nz-col
				nzSpan="12">
				<nz-switch [(ngModel)]="offlineEvent"
					nzCheckedChildren="Offline"
					nzUnCheckedChildren="Online"></nz-switch>
			</div>
		</div>
		<div *ngIf="offlineEvent"
			nz-row>
			<div nz-col
				nzSpan="12">
				<nz-form-item>
					<nz-form-label nzFor="venueName"
						nzRequired>
						Venue Name
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="venue"
							[ngModelOptions]="{ standalone: true }"
							nz-input
							placeholder="Venue Name">
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Thumbnail Image"></nz-divider>
		<div nz-col>
			<div nz-col
				nzSpan="6">
				<div nz-col
					nzSpan="6"></div>
				<span>
					<b>
						Thumbnail Images ( Single , ratio of image dimension should be 1:1 )
					</b>
				</span>
				<br>
				<input [disabled]="viewMode"
					(change)="uploadThumbnailImage($event)"
					accept=".jpg,.jpeg,.png"
					type="file">
			</div>
			<div *ngIf="squareImageLink"
				nz-col
				nzSpan="6"
				style="width: 200px"
				class="pt-5">
				<img [src]="squareImageLink"
					class="h-fit object-cover"
					alt="example">
			</div>
		</div>

		<div *ngIf="offlineEvent"
			nz-row>
			<div nz-col
				nzSpan="12">
				<nz-form-item>
					<nz-form-label nzFor="venueName"
						nzRequired>
						Venue Name
					</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="venue"
							[ngModelOptions]="{ standalone: true }"
							nz-input
							placeholder="Venue Name">
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Images"></nz-divider>
		<div nz-row>
			<div nz-col
				nzSpan="6">
				<span>
					<b>
						Banner Images ( Multiple, ratio of image dimension should be 2:1 )
					</b>
				</span>
				<br>
				<input [disabled]="viewMode"
					(change)="uploadImages($event)"
					multiple
					accept=".jpg,.jpeg,.png"
					type="file">
				<div *ngFor="let image of images">
					<p>{{ image.name }}</p>
				</div>
			</div>
		</div>
		<div class="flex flex-wrap justify-start items-center pr-4  pb-5">
			<div *ngFor="let data of imagesLinks; index as i"
				class="pt-5">
				<nz-card [nzCover]="coverTemplate"
					style="width: 200px">
					<span (click)="deleteImage(i)"
						nz-icon
						nzType="delete"></span>
				</nz-card>
				<ng-template #coverTemplate>
					<img [src]="data"
						class="h-fit object-cover"
						alt="example">
				</ng-template>
			</div>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Share Details"></nz-divider>
		<div nz-row>
			<div nz-col
				nzSpan="10">
				<nz-form-label nzRequired
					nzSpan="24">
					Share Message
				</nz-form-label>
				<textarea [disabled]="viewMode"
					[(ngModel)]="shareMessage"
					nz-input
					rows="4"></textarea>
			</div>
			<div nz-col
				class="pl-2"
				nzSpan="6">
				<span>
					<b>
						Upload share Image ( Single, ratio of image dimension should be 2:1 )
					</b>
				</span>
				<br>
				<input [disabled]="viewMode"
					(change)="uploadShareImage($event)"
					accept=".jpg,.jpeg,.png"
					type="file">
			</div>
			<div *ngIf="shareImageLink"
				nz-col
				nzSpan="6"
				style="width: 200px"
				class="pt-5">
				<img [src]="shareImageLink"
					class="h-fit object-cover"
					alt="shareImage">
			</div>
		</div>
		<nz-divider *ngIf="eventEntryFee.length && !viewMode"
			nzOrientation="left"
			nzText="Entry Fee Details"></nz-divider>
		<div>
			<div *ngFor="let fee of eventEntryFee"
				nz-row
				nzGutter="16">
				<div nz-col>
					<nz-form-item>
						<nz-form-label nzRequired
							nzSpan="24">
							Amount
						</nz-form-label>
						<nz-form-control>
							<nz-input-group nzPrefix="₹"
								nzSuffix="INR">
								<input [(ngModel)]="fee.amount"
									[disabled]="viewMode"
									nz-input
									placeholder="Amount"
									type="number">
							</nz-input-group>
						</nz-form-control>
					</nz-form-item>
					`
				</div>
				<div nz-col>
					<nz-form-item>
						<nz-form-label nzRequired
							nzSpan="24">
							MarkUp
						</nz-form-label>
						<nz-form-control>
							<nz-input-group nzPrefix="₹"
								nzSuffix="INR">
								<input [(ngModel)]="fee.markupPrice"
									[disabled]="viewMode"
									nz-input
									placeholder="Markup Price"
									type="number">
							</nz-input-group>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Guest Details"></nz-divider>
		<div nz-row
			nzJustify="end">
			<div nz-col>
				<button [disabled]="viewMode"
					(click)="addGuestSpeaker()"
					nz-button
					nzType="primary">
					<span nz-icon
						nzType="download"></span>
					Add Guest Speaker
				</button>
			</div>
		</div>
		<div *ngFor="let speaker of guestSpeakerDetails; index as index">
			<nz-card class="mt-5"
				nzTitle="Guest Speaker {{ index + 1 }}">
				<div nz-row
					nzGutter="16">
					<div nz-col
						nzSpan="8">
						<nz-form-item>
							<nz-form-label nzRequired>Name</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.name"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="name">
							</nz-form-control>
						</nz-form-item>
					</div>
					<div nz-col
						nzSpan="8">
						<nz-form-item>
							<nz-form-label nzRequired>Email</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.email"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="Email">
							</nz-form-control>
						</nz-form-item>
					</div>
					<div nz-col
						nzSpan="8">
						<nz-form-item>
							<nz-form-label nzRequired>Qualification</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.qualification"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="Qualification">
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
				<div nz-row>
					<div class="mt-2"
						nz-col
						nzSpan="24">
						<nz-form-item>
							<nz-form-label nzRequired>Description</nz-form-label>
							<nz-form-control nzSpan="24">
								<textarea [(ngModel)]="speaker.description"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="description"
									rows="5"></textarea>
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
				<nz-divider nzText="Social Media Links"></nz-divider>
				<div nz-row
					nzGutter="16">
					<div nz-col
						nzSpan="12">
						<nz-form-item>
							<nz-form-label>Twitter</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.socialMediaLinks.twitter"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="Twitter">
							</nz-form-control>
						</nz-form-item>
					</div>
					<div nz-col
						nzSpan="12">
						<nz-form-item>
							<nz-form-label>Youtube</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.socialMediaLinks.youtube"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="Youtube">
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
				<div class="mt-2"
					nz-row
					nzGutter="16">
					<div nz-col
						nzSpan="12">
						<nz-form-item>
							<nz-form-label>Web</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.socialMediaLinks.web"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="Web">
							</nz-form-control>
						</nz-form-item>
					</div>
					<div nz-col
						nzSpan="12">
						<nz-form-item>
							<nz-form-label>Facebook</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.socialMediaLinks.faceBook"
									[ngModelOptions]="{ standalone: true }"
									[disabled]="viewMode"
									nz-input
									placeholder="Facebook">
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
				<div class="mt-2"
					nz-row
					nzGutter="16">
					<div nz-col
						nzSpan="12">
						<nz-form-item>
							<nz-form-label>Linked In</nz-form-label>
							<nz-form-control nzSpan="24">
								<input [(ngModel)]="speaker.socialMediaLinks.linkedIn"
									[disabled]="viewMode"
									nz-input
									placeholder="LinkedIn">
							</nz-form-control>
						</nz-form-item>
					</div>
					<div nz-col
						nzSpan="12">
						<div class="mt-2"
							nz-row
							nzAlign="bottom"
							nzJustify="end">
							<button [disabled]="viewMode"
								(click)="deleteGuestSpeaker(index)"
								nz-button
								nzDanger
								nzType="primary">
								Delete Guest Speaker
							</button>
						</div>
					</div>
				</div>
			</nz-card>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Presenter Details"></nz-divider>
		<div nz-row>
			<div nz-col
				nzSpan="8">
				<nz-select [(ngModel)]="selectedExperts"
					[nzDisabled]="viewMode"
					id="circleExperts"
					nzAllowClear
					nzMode="tags"
					nzPlaceHolder="Experts"
					nzShowSearch
					style="min-width: 100%;">
					<nz-option *ngFor="let expert of circleExpertsList"
						[nzLabel]="expert.expertId"
						[nzValue]="expert.name"></nz-option>
				</nz-select>
			</div>
		</div>
		<nz-divider nzOrientation="left"
			nzText="Events Bids Details"></nz-divider>
		<div nz-row>
			<div nz-col
				nzSpan="8">
				<nz-select [(ngModel)]="selectedBidId"
					[nzDisabled]="viewMode"
					id="circleExperts"
					nzAllowClear
					nzPlaceHolder="Enter Bid detail"
					nzShowSearch
					style="min-width: 100%;">
					<nz-option *ngFor="let bid of bidsData"
						[nzLabel]="bid.eventName"
						[nzValue]="bid.id"></nz-option>
				</nz-select>
			</div>
		</div>
		<nz-divider nzText="Other Details"></nz-divider>
		<div nz-row
			class="gap-3">
			<div nz-col
				nzSpan="8">
				<nz-form-item>
					<nz-form-label>Meet Link</nz-form-label>
					<nz-form-control nzSpan="24">
						<input [(ngModel)]="meetLink"
							[ngModelOptions]="{ standalone: true }"
							[disabled]="viewMode"
							nz-input
							placeholder="Enter Meet Link">
					</nz-form-control>
				</nz-form-item>
			</div>

			<div nz-col
				class="pl-2"
				nzSpan="6">
				<img *ngIf="videoThumbnail"
					[src]="videoThumbnail"
					alt="thumbnail">
				<span>
					<b>
						Upload Video Thumbnail
					</b>
				</span>
				<br>
				<input [disabled]="viewMode"
					(change)="uploadThumbnail($event)"
					accept=".jpg,.jpeg,.png"
					type="file">
			</div>
			<div nz-col
				class="pl-2"
				nzSpan="6">
				<video *ngIf="videoLink"
					class="pt-2 pb-2"
					controls>
					<source [src]="videoLink">
				</video>
				<span>
					<b>Upload Event Video</b>
				</span>
				<br>
				<input [disabled]="viewMode"
					(change)="uploadVideo($event)"
					accept=".mp3,.mp4"
					type="file">
			</div>

			<div nz-col
				nzSpan="4">
				<nz-form-item>
					<nz-form-label>isActive</nz-form-label>
					<nz-form-control nzSpan="24">
						<nz-switch [disabled]="viewMode"
							[(ngModel)]="active"></nz-switch>
					</nz-form-control>
				</nz-form-item>
			</div>
		</div>
		<nz-divider></nz-divider>
		<div *ngIf="!viewMode"
			nz-row
			nzJustify="end">
			<div nz-col>
				<button (click)="updateEvent()"
					nz-button
					nzType="primary">
					Update {{recurring ? "Class" : "Events"}}
				</button>
			</div>
		</div>
	</nz-card>
</nz-spin>
