import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpertOnboardingGridResponse } from '../../../models/expertOnboardingGridResponse';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

@Component({
  selector: 'app-onboarding-table',
  templateUrl: './onboarding-table.component.html',
  styleUrl: './onboarding-table.component.scss'
})
export class OnboardingTableComponent {
  @Input() gridResp!: ExpertOnboardingGridResponse
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() pageChangeEvent = new EventEmitter<number>();

  constructor(public utils: UtilsService){}

  nzPageIndexChange(page: number) {
    this.pageChangeEvent.emit(page);
  }

  pageSize: number = 10;
  pageSizeOptions = [10, 20, 50, 100];

  nzPageSizeChange(pageSize: number) {
    this.pageSizeChangeEvent.emit(pageSize);
  }

  resetFilter() {
    this.resetFilterEvent.emit();
  }


  resetValues() {
    this.pageSize = 10;
  }

}
