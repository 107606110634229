/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  EventBidsStatus,
  EventsBiddingsCreateOrUpdateRequest,
} from '../../../models/events/EventsBiddingsCreateOrUpdateRequest';
import { MasterdataService } from '../../../services/masterdata.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { EventsService } from '../../../services/events.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import * as moment from 'moment';

@Component({
  selector: 'app-curd-events-biddings',
  templateUrl: './curd-events-biddings.component.html',
  styleUrl: './curd-events-biddings.component.scss',
})
export class CurdEventsBiddingsComponent extends AnyoComponent {
  constructor(
    auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private masterdataService: MasterdataService,
    private eventService: EventsService,
    private toastService: ToastService,
    private router: Router,
  ) {
    super(auth);
  }

  readonly audienceTypeOption = ['Student', 'Industry Experts'];
  loading: boolean = false;
  eventId: string = '';
  isEdit: boolean = false;
  eventName: string = '';
  eventDescription: string = '';
  location: string = '';
  eventDate: Date | undefined = undefined;
  duration: number = 0;
  audienceType: string[] = [];
  isTravelSponsorship: boolean = false;
  travelSponsorshipDescription: string = '';
  maxExpertBidLimit: number = 0;
  eventStatues: EventBidsStatus | undefined = undefined;
  additionalNotes: string = '';
  images: string[] = [];
  vertical: string[] = [];
  concern: string[] = [];
  biddingEndDate: Date | undefined = undefined;
  totalHost: number = 1;
  verticalsMasterData: {
    label: string;
    value: string;
  }[] = [];
  concernMasterData: {
    m_id: string;
    verticalId: string[];
    data: string;
  }[] = [];
  concernFilteredMasterData: {
    label: string;
    value: string;
  }[] = [];
  imageFileList: NzUploadFile[] = [];
  isTesting: boolean = false;
  offlineEvent: boolean = false;
  offlineAddress: string = '';
  offlineMapLink: string = '';
  mapAddress: string = '';
  selectedValue: string = 'Event';
  isClass: boolean = false;

  async ready(): Promise<void> {
    if (this.user) {
      const id = this.activatedRoute.snapshot.params['id'];
      this.eventId = id;
      await this.loadMasterData();
      if (id) {
        this.isEdit = true;
        await this.getAndSetTheEventsBidding(id);
      }
    }
  }

  async getAndSetTheEventsBidding(id: string): Promise<void> {
    this.loading = true;
    this.eventService.getEventBidding(id).subscribe({
      next: async (event) => {
        this.eventName = event.eventName;
        this.eventDescription = event.eventDescription;
        this.eventDate = event.eventDate;
        this.duration = event.duration;
        this.audienceType = event.audienceType;
        this.maxExpertBidLimit = event.maxExpertBidLimit.length
          ? event.maxExpertBidLimit[0].amount
          : 0;
        this.isTravelSponsorship = event.isTravelSponsorship;
        this.travelSponsorshipDescription = event.travelSponsorshipDescription;
        this.vertical = event.vertical;
        this.biddingEndDate = event.biddingEndDate;
        this.concern = event.concern;
        this.isTesting = event.isTestEventBid;
        this.totalHost = event.totalEventHost || 0;
        this.offlineEvent = event.isOffline;
        this.offlineAddress = event.offlineAddress || '';
        this.offlineMapLink = event.offlineMapLink || '';
        this.mapAddress = event.mapAddress || '';
        this.eventStatues = event.eventStatues;
      },
      error: (error) => {
        this.toastService.showError(error);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  async loadMasterData(): Promise<void> {
    this.masterdataService.masterDataList().subscribe({
      next: (masterData) => {
        masterData.therapyVerticals.forEach((data) => {
          const verticalsDropDownOption = {
            label: data.data,
            value: data.m_id,
          };
          this.verticalsMasterData.push(verticalsDropDownOption);
        });
        if (this.eventId) {
          console.log('call this');
          masterData.therapyConcerns.forEach((data) => {
            console.log(data);
            this.concernFilteredMasterData.push({
              label: data.data,
              value: data.m_id,
            });
          });
        }
        this.concernMasterData = masterData.therapyConcerns;
      },
    });
  }

  onVerticalChange($event: string[]) {
    this.vertical = $event;
    this.concernFilteredMasterData = [];
    this.concernMasterData.forEach((data) => {
      if (
        $event.some((selectedVertical) =>
          data.verticalId.includes(selectedVertical),
        )
      ) {
        this.concernFilteredMasterData.push({
          label: data.data,
          value: data.m_id,
        });
      }
    });
  }

  onConcernChange($event: any) {
    this.concern = $event;
  }

  onClassEventChange($event: any) {
    this.selectedValue = $event;
    if (this.selectedValue === 'Class') {
      this.isClass = true;
    } else {
      this.isClass = false;
    }
  }

  toggleIsTravelSponsorship() {
    if (!this.isTravelSponsorship) {
      this.travelSponsorshipDescription = '';
    }
  }
  beforeUpload = (): boolean => {
    return true;
  };

  async addOrUpdateEventBidding() {
    const missingFields = [];
    if (!this.eventName?.trim()) missingFields.push('Event Name');
    if (!this.vertical?.length) missingFields.push('Vertical');
    if (!this.concern?.length) missingFields.push('Concern');
    if (!this.eventDescription?.trim()) missingFields.push('Event Description');
    if (!this.eventDate) missingFields.push('Event Date');
    if (!this.duration || this.duration <= 0) missingFields.push('Duration');
    if (!this.audienceType?.length) missingFields.push('Audience Type');
    if (!this.maxExpertBidLimit || this.maxExpertBidLimit < 0)
      missingFields.push('Max Expert Bid Limit');
    if (!this.biddingEndDate) missingFields.push('Bidding End Date');
    if (this.offlineEvent) {
      if (!this.offlineAddress?.trim()) missingFields.push('Offline Address');
      if (!this.offlineMapLink?.trim()) missingFields.push('Offline Map Link');
      if (!this.mapAddress?.trim()) missingFields.push('Map Address');
    }
    if (missingFields.length > 0) {
      this.toastService.showError(
        `Please fill in the required fields: ${missingFields.join(', ')}`,
      );
      return;
    }

    const reqBody: EventsBiddingsCreateOrUpdateRequest = {
      eventName: this.eventName.trim(),
      vertical: this.vertical,
      concern: this.concern,
      eventDescription: this.eventDescription.trim(),
      eventDate: moment(this.eventDate).valueOf(),
      duration: this.duration,
      audienceType: this.audienceType,
      isTravelSponsorship: this.isTravelSponsorship,
      travelSponsorshipDescription: this.travelSponsorshipDescription?.trim(),
      biddingEndDate: moment(this.biddingEndDate).endOf('day').valueOf(),
      isTestEventBid: this.isTesting,
      totalHost: this.totalHost,
      isClass: this.isClass,
      isOffline: this.offlineEvent,
      maxExpertBidLimit: this.maxExpertBidLimit,
      eventStatus: this.eventStatues,
    };

    if (this.offlineEvent) {
      reqBody.offlineAddress = this.offlineAddress.trim();
      reqBody.offlineMapLink = this.offlineMapLink.trim();
      reqBody.mapAddress = this.mapAddress.trim();
    }

    if (this.isEdit) {
      reqBody.id = this.eventId;
    }

    this.loading = true;
    this.eventService.addOrUpdateEventBidding(reqBody).subscribe({
      next: () => {
        this.toastService.showSuccess(
          `Event Bidding ${this.isEdit ? 'Updated' : 'Created'} Successfully`,
        );
        this.router.navigate(['/events/events-bidding']);
      },
      error: (err) => {
        this.loading = false;
        this.toastService.showError(err.error.description);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
