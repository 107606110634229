import {GridRequest} from '../../../models/gridRequest';

export interface SessionGridRequest
  extends GridRequest<
    SessionGridSort,
    SessionGridFilter,
    SessionGridSearch,
    SessionGridDateFilter
  > {
  tab: SessionGridTab;
}

export enum SessionGridSort {
  SESSION_DATE = 'SESSION_DATE',
  THERAPIST_RATING = "THERAPIST_RATING"
}

export enum SessionGridTab {
  ALL = 'ALL',
  NEW_APPOINTMENTS = 'NEW_APPOINTMENTS',
}

export enum SessionGridFilter {
  SESSION_STATUS = 'SESSION_STATUS',
  THERAPIST_EMAIL = 'THERAPIST_EMAIL',
  HIDE_TEST_BOOKING = 'HIDE_TEST_BOOKING',
  PARTNER_ID = "PARTNER_ID",
  USER_ID = "USER_ID",
}

export enum SessionGridSearch {
  CLIENT_EMAIL = 'CLIENT_EMAIL',
  CLIENT_PHONE = 'CLIENT_PHONE',
}

export enum SessionGridDateFilter {
  SESSION_DATE = 'SESSION_DATE',
}
