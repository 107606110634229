<nz-table
  #sessionTable
  [nzTitle]="tableHead"
  [nzScroll]="{ y: '500px' }"
  class="mt-5"
  [nzData]="inputTableData!.data"
  nzFrontPagination="false"
  [nzTotal]="inputTableData!.totalRecords"
  [nzPageSize]="inputTableData!.pageSize"
  [nzBordered]="true"
  [nzShowSizeChanger]="true"
  [nzPageSizeOptions]="pageSizeOptions"
  [nzPageIndex]="inputTableData!.pageNum"
  nzTableLayout="fixed"
  (nzPageIndexChange)="nzPageIndexChange($event)"
  (nzPageSizeChange)="nzPageSizeChange($event)"
>
  <thead>
    <tr>
      <th nzCustomFilter>Client Details</th>
      <th nzCustomFilter>
        Partner Details
        <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="partnerIdMenu">
          <span nz-icon nzTheme="outline" nzType="filter"></span>
        </nz-filter-trigger>
      </th>
      <th
        (nzSortOrderChange)="sortSessionDates($event)"
        nzCustomFilter
        nzShowSort="true"
      >
        Session Timing
        <nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="sessionDateFilterMenu"
        >
          <span nz-icon nzTheme="outline" nzType="schedule"></span>
        </nz-filter-trigger>
      </th>
      <th nzCustomFilter>
        Therapist Details
        <nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="therapistFilterMenu"
        >
          <span nz-icon nzTheme="outline" nzType="schedule"></span>
        </nz-filter-trigger>
      </th>
      <th nzCustomFilter>
        Session Status
        <nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="sessionStatusMenu"
        >
          <span nz-icon nzTheme="outline" nzType="filter"></span>
        </nz-filter-trigger>
      </th>
      <th>User Rating</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of sessionTable.data">
      <tr>
        <td
          (nzExpandChange)="onExpandChange(data.id, $event)"
          [nzExpand]="expandSet.has(data.id)"
        >
          {{ data.clientDetails.name }}<br />
          <a nz-dropdown [nzDropdownMenu]="menu">
            <u class="cursor-pointer">{{ data.clientDetails.email }}</u>
          </a>
        </td>
        <td>
          {{ data.clientDetails.partnerId }}
          <br />
          {{ data.clientDetails.partnerName }}
          <br />
          <nz-tag
            *ngIf="data.clientDetails.partnerId === 'PR-9008'"
            nzColor="cyan"
            >Retail User</nz-tag
          >
          <nz-tag
            *ngIf="data.clientDetails.partnerId !== 'PR-9008'"
            nzColor="cyan"
            >Corporate User</nz-tag
          >
        </td>
        <td>
          <div *ngIf="data.appointmentRequestDate" nz-row nzJustify="start">
            Requested Date: {{ formatSessionDate(data.appointmentRequestDate) }}
          </div>
          <div nz-row nzJustify="start">
            Session Date: {{ formatSessionDate(data.sessionDate) }}
          </div>
          <div nz-row nzJustify="start">
            {{ formatSessionTime(data.sessionStart) }} -
            {{ formatSessionTime(data.sessionEnd) }}
          </div>
        </td>
        <td>
          <div>
            <u
              class="cursor-pointer"
              (click)="openTherapistSessionsPage(data.therapistDetails.id)"
              >{{ data.therapistDetails.therapistName }}</u
            >
          </div>
        </td>
        <td>
          {{ data.sessionStatus }}
        </td>
        <td>
          {{ data.rating }}
          <br />
          {{ data.testimonial }}
        </td>
        <td>
          <div nz-row nzJustify="start">
            <div
              class="mr-1"
              colspan="1"
              nz-col
              *ngIf="showConfirmButton(data.sessionStatus)"
            >
              <span nz-tooltip nzTooltipTitle="Confirm Appointment">
                <button
                  class="btn-color"
                  (click)="confirmAppointmentFn(data.id)"
                  nz-button
                  nzShape="circle"
                  nzType="primary"
                  [nzDanger]="
                    data.sessionStatus === SessionStatus.CANCELLATION_REQUESTED
                      ? true
                      : false
                  "
                >
                  <span
                    nz-icon
                    nzTheme="outline"
                    [nzType]="
                      data.sessionStatus ===
                      SessionStatus.CANCELLATION_REQUESTED
                        ? 'close'
                        : 'check'
                    "
                  ></span>
                </button>
              </span>
            </div>

            <div
              class="mr-1"
              colspan="1"
              nz-col
              *ngIf="showCancelButton(data.sessionStatus)"
            >
              <span
                nz-tooltip
                [nzTooltipTitle]="
                  data.sessionStatus === SessionStatus.CANCELLATION_REQUESTED
                    ? 'Cancellation Requested'
                    : 'Cancel Appoinment'
                "
              >
                <button
                  class="btn-color"
                  (click)="
                    cancelAppointmentFn(
                      data.id,
                      data.clientDetails.name,
                      data.clientDetails.email
                    )
                  "
                  nz-button
                  [nzDanger]="
                    data.sessionStatus === SessionStatus.CANCELLATION_REQUESTED
                      ? false
                      : true
                  "
                  nzShape="circle"
                  nzType="primary"
                >
                  <span
                    nz-icon
                    nzTheme="outline"
                    [nzType]="
                      data.sessionStatus ===
                      SessionStatus.CANCELLATION_REQUESTED
                        ? 'check'
                        : 'close'
                    "
                  ></span>
                </button>
              </span>
            </div>
            <div
              class="mr-1"
              colspan="1"
              nz-col
              *ngIf="
                showUpdateButton(data.sessionStatus) && showUpdateButtonPortal
              "
            >
              <span nz-tooltip nzTooltipTitle="Update Appoinments">
                <button
                  class="btn-color"
                  (click)="UpdateAppointmentFn(data)"
                  nz-button
                  nzShape="circle"
                  nzType="primary"
                >
                  <span nz-icon nzType="database" nzTheme="outline"></span>
                </button>
              </span>
            </div>
            <div
              class="mr-1"
              colspan="1"
              nz-col
              *ngIf="
                showFeedbackPendingButton(data.sessionStatus, data.sessionEnd)
              "
            >
              <span nz-tooltip nzTooltipTitle="Feedback Pending">
                <button
                  class="btn-color"
                  (click)="showFeedBackFormFn(data)"
                  nz-button
                  nzShape="circle"
                  nzType="primary"
                >
                  <span
                    *ngIf="!showBarIcon"
                    nz-icon
                    nzTheme="outline"
                    nzType="profile"
                  ></span>
                  <span
                    *ngIf="showBarIcon"
                    nz-icon
                    nzType="bars"
                    nzTheme="outline"
                  ></span>
                </button>
              </span>
            </div>
            <div
              class="mr-1"
              colspan="1"
              nz-col
              *ngIf="showRemarkButton(data.sessionStatus, data.sessionEnd)"
            >
              <span nz-tooltip nzTooltipTitle="Add Remarks">
                <button
                  class="btn-color"
                  (click)="openRemarkForm(data)"
                  nz-button
                  nzShape="circle"
                  nzType="primary"
                >
                  <span nz-icon nzType="comment" nzTheme="outline"></span>
                </button>
              </span>
            </div>
            <div class="mr-1" *ngIf="data.remarks" colspan="1" nz-col>
              <span nz-tooltip nzTooltipTitle="Remarks">
                <button
                  nz-button
                  class="btn-color"
                  nzType="primary"
                  nzShape="circle"
                  (click)="showRemarksModalFn(data)"
                >
                  <span
                    nz-icon
                    *ngIf="!showBarIcon"
                    nzType="info"
                    nzTheme="outline"
                  ></span>
                  <span
                    nz-icon
                    *ngIf="showBarIcon"
                    nzType="info-circle"
                    nzTheme="outline"
                  ></span>
                </button>
              </span>
            </div>
          </div>
        </td>
      </tr>
      <tr [nzExpand]="expandSet.has(data.id)">
        <div nz-row nzAlign="top" nzJustify="start">
          <div class="ml-1 mr-1 w-[20%]" colspan="2" nz-col>
            {{ data.id }}
            <br />
            Gender: {{ data.clientDetails.gender }} <br />Phone:
            {{ data.clientDetails.phone }}<br />
            PartnerId: {{ data.clientDetails.partnerId }}
            <br />
            PartnerName: {{ data.clientDetails.partnerName }}
          </div>
          <div class="ml-1 mr-1 w-[20%]" colspan="2" nz-col>
            <div *ngIf="data.appointmentConfirmedDate" nz-row nzJustify="start">
              Confirmed Date:
              {{ formatSessionDate(data.appointmentConfirmedDate) }}
            </div>
            <div *ngIf="data.appointmentConfirmedBy" nz-row nzJustify="start">
              Confirmed By: {{ data.appointmentConfirmedBy }}
            </div>
            <div
              *ngIf="data.appointmentCancellationDate"
              nz-row
              nzJustify="start"
            >
              Cancelled Date:
              {{ formatSessionDate(data.appointmentCancellationDate) }}
            </div>
            <div *ngIf="data.appointmentCancelledBy" nz-row nzJustify="start">
              Cancelled By: {{ data.appointmentCancelledBy }}
            </div>
          </div>
          <div class="ml-1 mr-1 w-[20%]" colspan="2" nz-col>
            <br />
            {{ data.therapistDetails.therapistEmail }}
            <br />
            {{ data.therapistDetails.therapistPhone }}
            <br />
            {{ data.callLink }}
          </div>
          <div
            *ngIf="data.planSubcriptionId"
            class="ml-1 mr-1 w-[20%]"
            colspan="2"
            nz-col
          >
            Plan Subcription Id : {{ data.planSubscriptionDetails?.id }}
            <br />
            Plan Name:
            <u
              (click)="
                openTherapyPlansSubscriptionPageByPlanName(
                  data.planSubscriptionDetails?.planId!
                )
              "
              class="cursor-pointer"
            >
              {{ data.planSubscriptionDetails?.planName }} </u
            ><br />Purchased Date :
            {{
              utils.convertEpochToFormattedDate(
                data.planSubscriptionDetails?.purchasedAt!
              )
            }}
            <br />
            Validity:
            {{
              utils.convertEpochToFormattedDate(
                data.planSubscriptionDetails?.validTill!
              )
            }}
            <br />
            Payment Type:
            {{
              utils.convertToTitleCase(
                data.planSubscriptionDetails?.paymentType!
              )
            }}
            <br />
            Verticle : {{ data.planSubscriptionDetails?.vertical }}
            <br />
            Concern : {{ data.planSubscriptionDetails?.concern }}
            <br />
            No of Session: {{ data.planSubscriptionDetails?.noOfSessions }}
          </div>
          <div class="w-[20%]" colspan="1" nz-col></div>
          <div class="w-[20%]" colspan="1" nz-col></div>
        </div>
      </tr>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li (click)="openUserSessionPage(data.clientDetails.id)" nz-menu-item>
            Go to Session Filter by UserId
          </li>
          <li
            (click)="
              openTherapyPlansSubscriptionPageByUser(data.clientDetails.id)
            "
            nz-menu-item
          >
            Go to Therapy Plan Subscription
          </li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>
  </tbody>
</nz-table>
<nz-dropdown-menu #sessionStatusMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <span class="font-bold">Session Status</span>
    </div>
    <div nz-row>
      <div nz-col>
        <nz-checkbox-wrapper (nzOnChange)="sessionStatusFilterChangeFn($event)">
          <div
            *ngFor="let sessionStatus of sessionStatusCheckBoxOptions"
            nz-row
          >
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="sessionStatus.value"
                [ngModel]="sessionStatus.checked"
                >{{ sessionStatus.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col class="mr-1">
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="sessionStatusFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<!-- <nz-dropdown-menu #partnerIdMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <span class="font-bold">Partner Id</span>
    </div>
    <div nz-row>
      <div nz-col>
        <nz-checkbox-wrapper (nzOnChange)="partnerIdFilterChangeFn($event)">
          <div *ngFor="let partnerId of partnerIdCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                [ngModel]="partnerId.checked"
                [nzValue]="partnerId.value"
                nz-checkbox
                >{{ partnerId.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col class="mr-1">
        <button
          (click)="partnerIdFilterFn()"
          class="btn-color"
          class="m-1"
          nz-button
          nzType="primary"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          (click)="resetFilter()"
          class="btn-color"
          class="m-1"
          nz-button
          nzType="primary"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu> -->
<nz-dropdown-menu #therapistFilterMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <span class="font-bold">Therapists</span>
    </div>
    <div nz-row>
      <div nz-col>
        <nz-checkbox-wrapper (nzOnChange)="therapistFilterChangeFn($event)">
          <div *ngFor="let therapist of therapistCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="therapist.value"
                [ngModel]="therapist.checked"
                >{{ therapist.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col class="mr-1">
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="therapistFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1"
          class="btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #sessionDateFilterMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="ng-row">
      <nz-range-picker
        [(ngModel)]="sessionColumnFilterDate"
        (ngModelChange)="onSessionFilterDateChange($event)"
      ></nz-range-picker>
    </div>
    <div nz-row class="mt-5" nzJustify="end">
      <div nz-col class="mr-1">
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="dateFilterFn()"
        >
          Ok
        </button>
      </div>
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Sessions</span>
    </div>
    <div nz-col>
      <div nz-row nzAlign="middle">
        <div nz-col>
          <nz-switch
            (ngModelChange)="testBookingChangeFn()"
            [(ngModel)]="showTestBookings"
            nzCheckedChildren="Hide Test Bookings"
            nzUnCheckedChildren="Show Test Bookings"
          ></nz-switch>
        </div>
        <div nz-col>
          <div *ngIf="showNewBookingBtn" class="m-1" nz-col>
            <button
              class="btn-color pr-1"
              (click)="showNewSessionFn()"
              nz-button
              nzSize="small"
              nzType="primary"
            >
              <span nz-icon nzTheme="outline" nzType="plus"></span>
              New Booking
            </button>
          </div>
        </div>
        <div nz-col>
          <div *ngIf="showNewBookingBtn" class="m-1" nz-col>
            <button
              class="btn-color pr-1"
              (click)="showActivateNewPlaModal()"
              nz-button
              nzSize="small"
              nzType="primary"
            >
              <span nz-icon nzTheme="outline" nzType="plus"></span>
              Activate New Plan
            </button>
          </div>
        </div>
        <div nz-col class="m-1">
          <button
            class="btn-color"
            (click)="exportData()"
            nz-button
            nzSize="small"
            nzType="primary"
          >
            <span nz-icon nzType="download"></span>
            Export
          </button>
        </div>
        <div nz-col class="m-1">
          <button
            class="btn-color"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Clear filter
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<nz-modal
  [(nzVisible)]="showRemarksModal"
  (nzOnCancel)="closeRewardsModal()"
  (nzOnOk)="closeRewardsModal()"
  nzWidth="700px"
>
  <ng-container *nzModalContent>
    <nz-table #remarksTable [nzData]="selectedSession!.remarks!">
      <thead>
        <tr>
          <th>
            {{ getColumnName() }}
          </th>
          <th>Date</th>
          <th nzWidth="50%">Remarks</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let remark of remarksTable.data">
          <td>
            {{ remark.addedBy }}
          </td>
          <td>
            {{ formatSessionDate(remark.addedAt) }}
          </td>
          <td>
            {{ remark.remark }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</nz-modal>
<nz-modal
  [nzOkLoading]="feedbackFormSubmitLoading"
  [(nzVisible)]="showFeedBackForm"
  nzTitle="Therapist Session Feedback"
  (nzOnCancel)="closeFeedbackForm()"
  (nzOnOk)="submitFeedBack()"
>
  <ng-container *nzModalContent>
    <div nz-row>Session Completed ?</div>
    <div nz-row class="mt-1">
      <nz-radio-group
        [(ngModel)]="sessionCompleted"
        (ngModelChange)="sessionCompletedChangeFn($event)"
      >
        <label nz-radio nzValue="Yes">Yes</label>
        <label nz-radio nzValue="No">No</label>
      </nz-radio-group>
    </div>
    <div nz-row class="mt-1">Session Status</div>
    <div nz-row class="mt-1">
      <nz-select
        class="w-[50%]"
        *ngIf="isSessionCompleted()"
        nzShowSearch
        nzAllowClear
        nzPlaceHolder="Select Status"
        [(ngModel)]="selectedStatus"
      >
        <nz-option nzLabel="Completed" nzValue="Completed"></nz-option>
      </nz-select>
      <nz-select
        class="w-[50%]"
        *ngIf="!isSessionCompleted()"
        nzShowSearch
        nzAllowClear
        nzPlaceHolder="Select Status"
        [(ngModel)]="selectedStatus"
      >
        <nz-option
          nzLabel="No Show Therapist"
          nzValue="No Show Therapist"
        ></nz-option>
        <nz-option nzLabel="No Show User" nzValue="No Show User"></nz-option>
        <nz-option nzLabel="Tech Issue" nzValue="Tech Issue"></nz-option>
        <nz-option
          nzLabel="Missed Appointment"
          nzValue="Missed Appointment"
        ></nz-option>
      </nz-select>
    </div>
    <div nz-row class="mt-2">
      <textarea
        rows="4"
        nz-input
        placeholder="Remarks"
        [(ngModel)]="feedbackRemarks"
      ></textarea>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzOkDisabled]="sessionOkDisabled"
  nzOkText="Book Session"
  (nzOnCancel)="closeNewSessionModal()"
  (nzOnOk)="submitNewSessionFn()"
  [(nzVisible)]="showNewSessionModal"
  [nzOkLoading]="newSessionSubmitProgress"
  nzTitle="New Session"
>
  <ng-container *nzModalContent>
    <div nz-row nzAlign="middle">
      <div nz-col colspan="2">
        <div>
          <input
            class="w-[300px]"
            placeholder="Search Users"
            nz-input
            [(ngModel)]="selectedUser"
            (input)="onSearchUser($event)"
            [nzAutocomplete]="auto"
            (ngModelChange)="onUserSelectedBookNewSession($event)"
          />
          <nz-autocomplete
            [nzDataSource]="userSearchResults"
            nzBackfill
            #auto
          ></nz-autocomplete>
        </div>
      </div>

      <div nz-col colspan="1" class="ml-5">
        <nz-spin
          nzSimple
          [nzSpinning]="searchSpinner"
          [nzSize]="'small'"
        ></nz-spin>
      </div>
    </div>
    <div nz-row nzAlign="middle" class="mt-2">
      <div nz-col colspan="3">
        <div>
          <nz-select
            class="w-[300px]"
            placeholder="Select Plan"
            nzShowSearch
            nzAllowClear
            [(ngModel)]="selectedActivePlanId"
            (ngModelChange)="onActivePlanSelected($event)"
          >
            <nz-option
              *ngFor="let plan of userActivePlans"
              [nzLabel]="
                plan.planName +
                ' - ' +
                plan.therapyVertical +
                ' - ' +
                plan.therapistDetails.therapistName
              "
              [nzValue]="plan.id"
            >
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <div nz-row class="mt-2">
      <nz-spin
        [nzSize]="'small'"
        [nzSpinning]="bookSessionDateTimeSpinner"
        nzTip=""
      >
        <div nz-row>
          <div nz-col class="mr-2">
            <!--            <nz-date-picker [(ngModel)]="selectedSessionDate" (nzOnOk)="selectedSessionDate"-->
            <!--                            (ngModelChange)="dateSelected($event)"-->
            <!--                            [nzDisabledDate]="disabledDate"-->
            <!--                            nzPlaceHolder="Select Date"></nz-date-picker>-->
            <nz-date-picker
              (ngModelChange)="onNewSessionDateChange($event)"
              [(ngModel)]="date"
            ></nz-date-picker>
          </div>

          <div class="mr-2" nz-col>
            <nz-time-picker
              (ngModelChange)="onNewSessionTimeChange($event)"
              [(ngModel)]="selectedSessionTime"
              nzFormat="HH:mm"
              nzPlaceHolder="Select time"
            ></nz-time-picker>
          </div>
        </div>
      </nz-spin>
    </div>
    <div nz-row class="mt-2">
      <label nz-checkbox [(ngModel)]="emergencyBooking"
        >Emergency Booking</label
      >
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [(nzVisible)]="visibleUpdateAppointment"
  nzTitle="Update Appointment"
  (nzOnCancel)="closeUpdateModal()"
  (nzOnOk)="submitUpdateAppointment()"
  [nzOkLoading]="updateAppointmentSubmitLoading"
>
  <ng-container *nzModalContent>
    <div nz-row nzAlign="middle">
      <div nz-col colspan="2">
        <label class="feedback-yes">Therapist Name</label>
        <div>
          <nz-select
            class="w-[250px] mr-2"
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select Therapist"
            [(ngModel)]="selectedTherapistString"
          >
            <nz-option
              *ngFor="let th of therapistWithId"
              [nzLabel]="th.label"
              [nzValue]="th.value"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col colspan="2">
        <label class="feedback-yes">Time</label>
        <div>
          <nz-time-picker
            (ngModelChange)="onUpdateSessionTimeChange($event)"
            [(ngModel)]="startTime"
            nzFormat="HH:mm"
            nzPlaceHolder="Select time"
          ></nz-time-picker>
        </div>
      </div>
      <div nz-col colspan="2" class="pt-2">
        <label class="feedback-yes">Date</label>
        <div>
          <nz-date-picker
            class="w-[250px]"
            (ngModelChange)="onUpdateSessionDateChange($event)"
            [(ngModel)]="updateDate"
            nzFormat="dd/MM/YYYY"
          ></nz-date-picker>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<nz-dropdown-menu #partnerIdMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <!-- <div nz-col>
        <span class="font-bold">Partner Id</span>
        <nz-divider></nz-divider> -->
      <!-- <nz-checkbox-wrapper (nzOnChange)="partnerIdFilterChangeFn($event)">
          <div *ngFor="let partnerId of partnerIdCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                [ngModel]="partnerId.checked"
                [nzValue]="partnerId.value"
                nz-checkbox
                >{{ partnerId.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
      <div nz-col>
        <nz-divider class="height:14em" nzType="vertical"></nz-divider>
      </div> -->
      <div nz-col>
        <span class="font-bold">Partner Name</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="partnerIdFilterChangeFn($event)">
          <div *ngFor="let state of partnerCompanyNameCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="state.value"
                [ngModel]="state.checked"
                >{{ state.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="this.reloadTableEvent.emit()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-modal
  [nzOkLoading]="activateNewPlanLoading"
  [(nzVisible)]="activateNewPlanModal"
  nzTitle="Activate new Plan"
  (nzOnCancel)="hideActivateNewPlanModal()"
  (nzOnOk)="activateNewPlan()"
  nzOkText="Activate Plan"
>
  <ng-container *nzModalContent>
    <div nz-row>
      <div nz-row nzAlign="middle"></div>
      <div nz-col colspan="2">
        <div>
          <input
            class="w-[300px]"
            placeholder="Search Users"
            nz-input
            [(ngModel)]="selectedUser"
            (input)="onSearchUser($event)"
            [nzAutocomplete]="auto"
            (ngModelChange)="onUserSelectFn($event)"
          />
          <nz-autocomplete
            [nzDataSource]="userSearchResults"
            nzBackfill
            #auto
          ></nz-autocomplete>
        </div>
      </div>
      <div nz-col colspan="1" class="ml-5">
        <nz-spin
          nzSimple
          [nzSpinning]="searchSpinner"
          [nzSize]="'small'"
        ></nz-spin>
      </div>
    </div>
    <div nz-row nzAlign="middle" class="mt-2">
      <div nz-col colspan="2">
        <nz-select
          class="w-[300px]"
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select Therapist"
          [(ngModel)]="selectedTherapist"
          (ngModelChange)="therapistSelectedFn($event)"
        >
          <nz-option
            *ngFor="let th of activeTherapists"
            [nzLabel]="th.email"
            [nzValue]="th._id"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <div nz-row nzAlign="middle" class="mt-2">
      <div nz-col colspan="2">
        <nz-select
          class="w-[300px]"
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select Vertical"
          [(ngModel)]="selectedVerticalId"
          (ngModelChange)="onVerticalChange($event)"
        >
          <nz-option
            *ngFor="let th of verticals"
            [nzLabel]="th.data"
            [nzValue]="th.m_id"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <div nz-row nzAlign="middle" class="mt-2"></div>
    <div nz-col colspan="2">
      <nz-select
        class="w-[300px]"
        nzShowSearch
        nzAllowClear
        [(ngModel)]="selectedConcernId"
        nzPlaceHolder="Select Concern"
        (ngModelChange)="onConcernChange($event)"
      >
        <nz-option
          *ngFor="let th of filteredConcerns"
          [nzLabel]="th.data"
          [nzValue]="th.m_id"
        ></nz-option>
      </nz-select>
    </div>
    <div nz-row nzAlign="middle" class="mt-2">
      <div nz-col colspan="2">
        <nz-select
          class="w-[300px]"
          nzShowSearch
          nzAllowClear
          [(ngModel)]="selectedPlanId"
          nzPlaceHolder="Select Plan"
          (ngModelChange)="onPlanChange($event)"
        >
          <nz-option
            *ngFor="let th of plansForSelectedConcern"
            [nzLabel]="th.planName"
            [nzValue]="th.planId"
          ></nz-option>
        </nz-select>
      </div>
      <div nz-col colspan="1" class="ml-5">
        <nz-spin
          nzSimple
          [nzSpinning]="fetchPlansLoading"
          [nzSize]="'small'"
        ></nz-spin>
      </div>
    </div>
    <div nz-row nzAlign="middle" class="mt-2">
      <input
        nz-input
        placeholder="Enter Payment Order Id"
        [(ngModel)]="paymentOrderId"
      />
      Type FREE_ACTIVATION to activate plan for free
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzOkLoading]="addRemarkForPastUserLoading"
  [(nzVisible)]="showRemarkForm"
  nzTitle="Admin Remarks"
  (nzOnCancel)="closeRemarkForm()"
  (nzOnOk)="addRemarkForPastUser()"
>
  <ng-container *nzModalContent>
    <div nz-row>
      <textarea
        rows="4"
        nz-input
        placeholder="Remarks"
        class="placeholder:opacity-100"
        [(ngModel)]="remarkForPastSession"
      ></textarea>
    </div>
  </ng-container>
</nz-modal>
