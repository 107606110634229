<div class="flex justify-end items-end w-full">
    <div nz-row nzjusty nzJustify="end" nzAlign="middle">
        <button [routerLink]="['/experts/add']" nz-button nzType="primary">Create Experts</button>
    </div>
</div>
<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Experts</span>
      </div>
      <div class="flex gap-2">
        <nz-switch [(ngModel)]="showTestCount" (ngModelChange)="showTestExperts()" nzCheckedChildren="hide test expert" nzUnCheckedChildren="show test expert"></nz-switch>
        <div nz-col>
          <button
            class="btn-color"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Clear filter
          </button>
        </div>
        </div>
    </div>
  </ng-template>
  <div class="flex justify-end mt-3">
    <div class="w-[40%]">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for expert name,email"
        />
      </nz-input-group>
    </div>
  </div>
  <ng-template #suffixButton>
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      nzSearch
      (click)="searchGlobal()"
    >
      Search
    </button>
  </ng-template>
<nz-spin [nzSpinning]="expertsSpinner" nzTip="Fetching Experts....">
    <nz-table #basicTable 
    [nzData]="tableData"
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="totalRecords"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="currentPage"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
    >
        <thead>
          <tr>
            <th>Name
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="expertNameSearch"
            >
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
            </th>
            <th>Email
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="expertEmailSearch"
            >
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
            </th>
            <th nzWidth="20%">About</th>
            <th>lastSeen</th>
            <th>Online</th>
            <th nzWidth="10%">Status
            <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="statusMenu">
              <span nz-icon nzType="filter"></span>
            </nz-filter-trigger></th>
            <th nzWidth="15%">user Interests Tags
              <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="interestMenu">
                <span nz-icon nzType="filter"></span>
              </nz-filter-trigger>
            </th>
            <th nzWidth="15%">Roles
              <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="rolesMenu">
                <span nz-icon nzType="filter"></span>
              </nz-filter-trigger>
            </th>
            <th>Rating</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{data.name}}</td>
            <td>{{data.email}}</td>
            <td>{{data.about}}</td>
            <td>{{utils.convertEpochToFormattedDate(data.lastSeen)}}</td>
            <td>{{data.online ? "Online" : "Offline"}}</td>
            <td>{{data.active ? "Active" : "In Active" }}</td>
            <td [align]="'left'">
              <div class="flex flex-wrap gap-1" *ngFor="let tag of data.interestWithColor">
                <nz-tag class="mt-1" [nzColor]="tag.color">{{
                  utils.convertToTitleCase(tag.data)
                }}</nz-tag>
               </div>
               <div *ngIf="!data.interestWithColor.length">N/A</div>
            </td>
            <td [align]="'left'">
             <div class="flex flex-wrap gap-1" *ngFor="let role of data.rolesWithColor">
              <nz-tag class="mt-1" [nzColor]="role.color">{{
                utils.convertToTitleCase(role.data)
              }}</nz-tag>
             </div>
            </td>
            <td>{{data.rating}}</td>
            <td>
                <span nz-tooltip nzTooltipTitle="View Experts">
                    <button
                      nz-button
                      class="save"
                      nzShape="circle"
                      [routerLink]="['/experts/edit/' + data.userId]"
                    >
                      <span nz-icon nzType="eye" nzTheme="outline"></span>
                    </button>
                  </span>
            </td>
          </tr>
        </tbody>
      </nz-table>
</nz-spin>
<nz-dropdown-menu #expertNameSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="expertNameColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="expertNameSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #expertEmailSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="expertEmailColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="expertEmailSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #rolesMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Roles</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="rolesFilter($event)">
          <div *ngFor="let role of rolesCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="role.value"
                [ngModel]="role.checked"
                >{{ role.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="getExperts()">
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #expertEmailSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="expertEmailColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="expertEmailSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #interestMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">User Interest</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="userInterestFilter($event)">
          <div *ngFor="let interest of interestCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="interest.value"
                [ngModel]="interest.checked"
                >{{ interest.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="getExperts()">
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #statusMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Status</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="statusFilter($event)">
          <div *ngFor="let status of statusCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="status.value"
                [ngModel]="status.checked"
                >{{ status.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="getExperts()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>