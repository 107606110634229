<button nz-button (click)="open()" class="btn-color" nzType="primary">
  Edit Availability
</button>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="1284" [nzVisible]="visible"
  [nzTitle]="'Availability update for ' + therapistName" [nzFooter]="footerTpl" (nzOnClose)="close()">
  <ng-container *nzDrawerContent>
    <nz-spin [nzSpinning]="markUnavailableSpinner" nzTip="Loading......">
      <nz-divider nzText="Set Available Slots"></nz-divider>

      <!-- <app-set-available-slots
        [therapistId]="therapistId"
        (therapistAvailabilityEvent)="getAvailability($event)"
      ></app-set-available-slots> -->
      <app-set-availability-v2 [therapistName]="therapistName" [therapistId]="therapistId"></app-set-availability-v2>

      <nz-divider nzText="Time Ranges For Select Day For Therapist"></nz-divider>
      <div nz-row class="flex-col">
        <label>Select Date :</label>
        <nz-date-picker class="w-[20%] mb-4" [(ngModel)]="date" (ngModelChange)="onChange($event)"
          [nzDisabledDate]="disabledDate"></nz-date-picker>
      </div>

      <div style="display: flex; border-bottom: 1px solid rgb(233, 233, 233)">
        <label nz-checkbox [(ngModel)]="allChecked" (ngModelChange)="updateAllChecked()">
          Mark full day off
        </label>
        <label nz-checkbox [nzDisabled]="true"></label>
        <p class="pl-4">
          Can't mark full day as available as there are prior bookings.
          Reschedule and then update availability
        </p>
      </div>
      <div nz-row class="flex-col" nzAlign="middle">
        <nz-checkbox-wrapper (nzOnChange)="selectedTimeRanges($event)">
          <div *ngFor="let status of checkBoxTimeRangeOptions" nz-row>
            <div nz-col>
              <label nz-checkbox [nzDisabled]="
                  !status.value.notAvailableSlotExists &&
                  !status.value.available
                " [nzValue]="status.value" [ngModel]="status.checked">{{ status.label }}</label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
      <div nz-row nzJustify="end">
        <button nz-button nzType="primary" class="btn-color mt-3" (click)="saveNotAvailableTimings()">
          Save
        </button>
      </div>
      <nz-table #basicTable [nzData]="notAvailableSlots" [nzTitle]="tableHead" [nzScroll]="{ y: '500px' }" class="mt-5"
        [nzFrontPagination]="true" [nzPageSize]="5">
        <thead>
          <tr>
            <th>Date</th>
            <th nzWidth="30%">Available Slots</th>
            <th nzWidth="30%">Unavailable Slots</th>
            <th>day</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ convertDateFormat(data.date) }}</td>
            <td>
              <div *ngFor="let time of data.availableSlots">
                {{ convertTimeFormat(time.start) }} -
                {{ convertTimeFormat(time.end) }}
              </div>
            </td>
            <td>
              <div *ngFor="let time of data.unavailableSlots">
                {{ convertTimeFormat(time.start) }} -
                {{ convertTimeFormat(time.end) }}
              </div>
            </td>
            <td>{{ data.day }}</td>
            <td>
              <button *ngIf="validateFutureDate(data)" nz-button (click)="editNotAvailable(data.date)">
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-spin>
  </ng-container>
  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" class="btn-color" (click)="close()">
        Cancel
      </button>
    </div>
  </ng-template>
  <ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Therapist availability</span>
      </div>
    </div>
  </ng-template>
</nz-drawer>