import { GridResponse } from 'projects/app-core/src/models/gridResponse';

export interface EventsBiddingLogResponse {
  expertName: string;
  bidAmount: number;
  expertQualification: string;
  proposalMessage: string;
  status: BiddingStatus;
  eventBidId: string;
  id: string;
}

export interface EventsBiddingLogGridResponse
  extends GridResponse<undefined, EventsBiddingLogResponse> {}

export enum BiddingStatus {
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}
