import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageExpertsComponent } from './manage-experts/manage-experts.component';
import { AddExpertsComponent } from './add-experts/add-experts.component';
import { EditExpertsComponent } from './edit-experts/edit-experts.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingFormComponent } from './onboarding-form/onboarding-form.component'

const routes: Routes = [
  {
    path: '',
    component: ManageExpertsComponent
  },
  {
    path: 'add',
    component: AddExpertsComponent
  },
  {
    path: 'edit/:id',
    component: EditExpertsComponent
  },
  {
    path: "onboarding",
    component: OnboardingComponent
  },
  {
    path: "add/req/:onboardid",
    component: AddExpertsComponent
  },
  {
    path: "form/:id",
    component: OnboardingFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpertsRoutingModule { }
