<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">
        Manage Events Registration
      </h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">
        {{ totalCount }} Events Registration
      </h1>
    </div>
    
  </div>
</div>
<div class="flex items-start gap-4 " style="background: #ECECEC;padding:30px;">
  <nz-card [nzLoading]="eventsLoading" *ngIf="eventRegistrationData.length > 0" style="width:400px;" [nzBordered]="false" nzTitle="Event info">
    <p>Event Name : {{ eventRegistrationData[0].eventName }}</p>
    <P>Event Category : {{ eventRegistrationData[0].eventCategory }}</P>
    </nz-card>
</div>
<nz-spin [nzSpinning]="eventsLoading" nzTip="Fetching Event registrations....">
  <div nz-row nzJustify="end" nzAlign="middle">
    <div nz-col class="p-1">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for user name"
        />
      </nz-input-group>
    </div>
  </div>

  <nz-table
    #basicTable
    [nzData]="eventRegistrationData"
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzShowPagination]="true"
    [nzFrontPagination]="false"
    [nzTotal]="eventRegistrationTableTotalCount"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="eventRegistrationTableCurrentPageNo"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <!-- <th>
          Event Name
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="eventsMenu"
            [(nzVisible)]="isEventsMenu"
          >
            <span nz-icon nzType="filter-o"></span>
          </nz-filter-trigger>
        </th> -->
        <!-- <th>Event Category</th> -->
        <th>User Name</th>
        <th>
          User Email
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="userEmailSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th nzWidth="30%">Tags</th>
        <th>Amount</th>
        <th>
          Event Type
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="paymentTypeMenu"
            [(nzVisible)]="ispaymentTypeMenu"
          >
            <span nz-icon nzType="filter-o"></span>
          </nz-filter-trigger>
        </th>
        <th
          (nzSortOrderChange)="sortRegisteredDates($event)"
          nzCustomFilter
          nzShowSort="true"
        >
          Registered On

          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="eventsDateFilterMenu"
          >
            <span nz-icon nzTheme="outline" nzType="schedule"></span>
          </nz-filter-trigger>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <!-- <td>{{ data.eventName }}</td> -->
        <!-- <td>{{ data.eventCategory }}</td> -->
        <td>{{ data.userName }}</td>
        <td>{{ data.userEmail }}</td>
        <td>
          <div *ngFor="let tag of data.tags">
            <nz-tag [nzColor]="data.paymentTypeColor">{{
              utils.convertToTitleCase(tag)
            }}</nz-tag>
          </div>
          <div *ngIf="!data.tags">"N/A"</div>
        </td>
        <td>{{ data.amount }}</td>
        <td [align]="'left'">
          <nz-tag [nzColor]="data.paymentTypeColor">{{
            utils.convertToTitleCase(data.paymentType)
          }}</nz-tag>
        </td>
        <td>{{ utils.convertEpochToFormattedDate(data.registerOn) }}</td>
      </tr>
    </tbody>
    <ng-template #tableHead>
      <div nz-row nzJustify="space-between">
        <div nz-col>
          <span class="font-bold">Events Registration</span>
        </div>
        <div nz-col>
          <div nz-row nzAlign="middle">
            <div nz-col class="pr-4">
              <nz-switch
                (ngModelChange)="testEventChangeFn($event)"
                [(ngModel)]="showTestEvents"
                nzCheckedChildren="Hide Test Events Registrations"
                nzUnCheckedChildren="Show Test Events Registrations"
              ></nz-switch>
            </div>
            <div nz-col class="pr-4">
              <button
                nz-button
                nzSize="small"
                nzType="primary"
                (click)="exportRegistrationTable()"
              >
                Export
              </button>
            </div>
            <div nz-col>
              <button
                nz-button
                nzSize="small"
                nzType="primary"
                (click)="resetFilter()"
              >
                Clear filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </nz-table>
</nz-spin>
<ng-template #suffixButton>
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    nzSearch
    (click)="searchGlobal()"
  >
    Search
  </button>
</ng-template>
<nz-dropdown-menu #userEmailSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="emailColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
          (input)="onInputChange()"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="userEmailSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #paymentTypeMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Payment Type</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="paymentTypeFilter($event)">
          <div *ngFor="let paymentType of paymentTypeCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="paymentType.value"
                [ngModel]="paymentType.checked"
                >{{ paymentType.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="listEventRegistration()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #eventsDateFilterMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="ng-row">
      <nz-range-picker
        [nzFormat]="dateFormat"
        [(ngModel)]="eventsRegistrationColumnFilterDate"
      ></nz-range-picker>
    </div>
    <div nz-row class="mt-5 gap-4" nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="dateFilterFn()"
        >
          Ok
        </button>
      </div>
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<!-- <nz-dropdown-menu #eventsMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Event Name</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="eventNameFilter($event)">
          <div
            *ngFor="let paymentType of eventNameFilterCheckBoxOptions"
            nz-row
          >
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="paymentType.value"
                [ngModel]="paymentType.checked"
                >{{ paymentType.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="listEventRegistration()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu> -->
